import React, { useState, useEffect } from 'react';
import { CountryList } from '../../config/country';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import './filter-header.scss';
import { IconSearch } from '../svg';

function HeaderFilter(props) {
    const animatedComponents = makeAnimated();
    const [countryVal, setCountryVal] = useState();

    const setCountryValue = date => {
        CountryList.map((country, index) => {
            if (country.name === props.country) {
                setCountryVal(country);
            }
        });
    }

    const onDateChange = date => {
        props.setDate(date)
    }

    const onSearchChange = (e) => {
        e.preventDefault();
        props.setSearch(e.target.value)
    }

    const onCountryChange = selectedOption => {
        if (selectedOption) {
            props.setCountry(selectedOption);
        } else {
            props.setCountry('');
        }
        props.setIsSubmitted(true);
        props.setDetailId('');
    }

    const onSubmit = (e) => {
        props.setSkip(0);
        props.setIsSubmitted(true);
        props.setDetailId('');
    }

    const onKeyEnter = (e) => {
        if (e.keyCode === 13) {
            onSubmit()
        }
    }

    useEffect(() => {
        setCountryValue();
    }, [props.country]);

    return (
        <header className="filter-header">
            <div className="container-fluid">
                <div className=" justify-content-center align-items-center">
                    <div className="position-relative">
                        <Select
                            className="custom-select"
                            classNamePrefix="select-control"
                            isMulti
                            components={animatedComponents}
                            isClearable={false}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.name}
                            options={CountryList}
                            value={countryVal}
                            placeholder="Select Country of Origin"
                            onChange={onCountryChange}
                        />
                        <input
                            className="form-control"
                            type="Search"
                            value={props.search}
                            onChange={onSearchChange}
                            onKeyDown={onKeyEnter} />

                        {!props.search &&
                            <span className="placeholder">Search (To search with multiple keywords use '+' after each keyword.)</span>
                        }
                        <button type="submit" className="btn-search" onClick={onSubmit}>
                            <IconSearch />
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </header >
    );
}

export default HeaderFilter;