export const ProfileType = [
    {
        "name": "Technology Offers",
        "title": "Technology",
        "subtitle": "OFFERS",
        "slug": "TO",
        "isChecked": true
    },
    {
        "name": "Technology Requests",
        "title": "Technology",
        "subtitle": "REQUESTS",
        "slug": "TR",
        "isChecked": true
    },
    {
        "name": "Business Offers",
        "title": "Business",
        "subtitle": "OFFERS",
        "slug": "BO",
        "isChecked": true
    },
    {
        "name": "Business Requests",
        "title": "Business",
        "subtitle": "REQUESTS",
        "slug": "BR",
        "isChecked": true
    },
    {
        "name": "Research & Development Requests",
        "title": "Research",
        "subtitle": "REQUESTS",
        "slug": "RDR",
        "isChecked": true
    },
]