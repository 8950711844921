import React from 'react';

const IconCalendar = () => {
	return (
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 512 512" space="preserve">
			<g>
				<g>
					<path d="M492,352c11.046,0,20-8.954,20-20V120c0-44.112-35.888-80-80-80h-26V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20
			v20h-91V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v20h-90V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v20H80
			C35.888,40,0,75.888,0,120v312c0,44.112,35.888,80,80,80h352c44.112,0,80-35.888,80-80c0-11.046-8.954-20-20-20
			c-11.046,0-20,8.954-20,20c0,22.056-17.944,40-40,40H80c-22.056,0-40-17.944-40-40V120c0-22.056,17.944-40,40-40h25v20
			c0,11.046,8.954,20,20,20s20-8.954,20-20V80h90v20c0,11.046,8.954,20,20,20s20-8.954,20-20V80h91v20c0,11.046,8.954,20,20,20
			c11.046,0,20-8.954,20-20V80h26c22.056,0,40,17.944,40,40v212C472,343.046,480.954,352,492,352z"/>
				</g>
			</g>
			<g>
				<g>
					<circle cx="125" cy="210" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="299" cy="210" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="386" cy="210" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="125" cy="297" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="125" cy="384" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="212" cy="210" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="212" cy="297" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="212" cy="384" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="299" cy="297" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="386" cy="297" r="20" />
				</g>
			</g>
			<g>
				<g>
					<circle cx="299" cy="384" r="20" />
				</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
		</svg>

	)
}

export default IconCalendar;