import React, { useState } from 'react';
import { Nav, NavItem, NavLink } from "reactstrap";
import { Col } from 'reactstrap';
import { IconAngleLeft, IconAngleRight, IconAngleDoubleRight, IconAngleDoubleLeft } from '../svg';

function Pagination(props) {

    const { totalPage, currentPage } = props;
    const numberLimit = 5;
    const lastIsActive = true;
    const firstIsActive = true;

    let startPoint = 1;
    let endPoint = numberLimit;
    if (numberLimit > totalPage) {
        startPoint = 1;
        endPoint = totalPage;
    } else if (currentPage <= parseInt(numberLimit / 2, 10)) {
        startPoint = 1;
        endPoint = numberLimit;
    } else if (currentPage + parseInt(numberLimit / 2, 10) <= totalPage) {
        startPoint = currentPage - parseInt(numberLimit / 2, 10);
        endPoint = currentPage + parseInt(numberLimit / 2, 10);
    } else {
        startPoint = totalPage - (numberLimit - 1);
        endPoint = totalPage;
    }
    startPoint = startPoint === 0 ? 1 : startPoint;
    const points = [];
    for (var i = startPoint; i <= endPoint; i++) {
        points.push(i);
    }
    let firstPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    let lastPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
    let prevPageButtonClassName = currentPage <= 1 ? "disabled" : "";
    let nextPageButtonClassName = currentPage >= totalPage ? "disabled" : "";

    const onChangePage = (e) => {
        props.onChangePage(e);
    }

    return totalPage > 1 ? (
        <div className="pagination-wrap">
            <h5 className="page-count">
                Page {props.currentPage} of {props.totalPage}
            </h5>

            <Nav className="pagination pagination-custom">
                {firstIsActive && (
                    <NavItem className={`page-item ${firstPageButtonClassName}`} >
                        <NavLink
                            className="page-link first"
                            onClick={() => onChangePage(1)}>
                            <IconAngleDoubleLeft />
                        </NavLink>
                    </NavItem>
                )}
                <NavItem className={`page-item ${prevPageButtonClassName}`}>
                    <NavLink
                        className="page-link prev"
                        onClick={() => onChangePage(currentPage - 1)}
                    >
                        <IconAngleLeft />
                    </NavLink>
                </NavItem>
                {points.map(i => {
                    return (
                        <NavItem
                            key={i}
                            className={`page-item ${currentPage === i && "active"}`}>
                            <NavLink
                                className="page-link"
                                onClick={() => onChangePage(i)}
                            >
                                {i}
                            </NavLink>
                        </NavItem>
                    );
                })}
                <NavItem className={`page-item ${nextPageButtonClassName}`}>
                    <NavLink
                        className="page-link next"
                        onClick={() => onChangePage(currentPage + 1)}
                    >
                        <IconAngleRight />
                    </NavLink>
                </NavItem>
                {lastIsActive && (
                    <NavItem className={`page-item ${lastPageButtonClassName}`}>
                        <NavLink
                            className="page-link last"
                            onClick={() => onChangePage(totalPage)}>
                            <IconAngleDoubleRight />
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
        </div>
    ) : (
            <Col xxs="12" className="mt-2" />
        );
}


export default Pagination;