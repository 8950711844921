import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { ProfileType } from '../../config/profile-type';
import { ProfileDetailCardGroup } from '../../config/profile-detail-group';
import { CountryList } from '../../config/country';
import profileService from '../../service/profile-service';
import { IconTech, IconResearch, IconCommercial, IconAngleDown } from '../../components/svg';
import './profile.scss';

function ProfileDetail(props) {
    const [isSticky, setSticky] = useState(false);
    const [stickyPos, setStickyPos] = useState('36rem');
    const [toggleOpen, setToggleOpen] = useState(false);

    const [data, setData] = useState([]);

    const getProfileDetail = async () => {
        try {
            const response = await profileService.getProfileById(props.profileId);
            if (response && response.data.data) {
                setData(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getItem = () => {
        const res = ProfileType.find(function (item) {
            return item.slug === data.detail_profile_type
        });
        return res ? res : null;
    }

    const getFlag = () => {
        const res = CountryList.find(function (item) {
            return item.name === data.client_country
        });
        return res ? res.emoji : null;
    }

    const scrollDown = (e, scrollTo) => {
        const getScrollHeight = document.querySelector(scrollTo).offsetTop;

        window.scrollTo({
            top: getScrollHeight - 150,
            left: 0,
            behavior: 'smooth'
        });

        setToggleOpen(false);
    }

    const stickyNavLink = (id) => {
        if (id !== '') {
            const getHeaderHeight = document.querySelector('.filter-header').offsetHeight;
            const getTitleHeight = document.querySelector('.title').offsetHeight;
            const getProfKeyHeight = document.querySelector('.profile-key-info').offsetHeight;
            const navPos = getHeaderHeight + getTitleHeight + getProfKeyHeight + 150;
            const intialNavPos = getHeaderHeight + getTitleHeight + getProfKeyHeight;

            setStickyPos(intialNavPos);

            window.addEventListener('scroll', () => {
                if (window.pageYOffset >= navPos) {
                    setSticky(true);
                    setStickyPos(getHeaderHeight);
                } else {
                    setSticky(false);
                    setStickyPos(intialNavPos);
                }
                activeStickyNavLink(navPos);
            });
        }
    }

    const activeStickyNavLink = (navPos) => {
        if (window.pageYOffset <= navPos) {
            // nothing to do here
        } else {
            ProfileDetailCardGroup.map((elem) => {
                if (document.querySelector("#" + elem.cardSelector)) {
                    const getCard = document.querySelector("#" + elem.cardSelector);
                    if (document.querySelector('.' + elem.buttonClass)) {
                        if (window.pageYOffset >= (getCard.offsetTop - 180) && window.pageYOffset < (getCard.offsetTop + (getCard.offsetHeight - 160))) {
                            document.querySelector('.' + elem.buttonClass).classList.add('active')
                        } else {
                            document.querySelector('.' + elem.buttonClass).classList.remove('active')
                        }
                    }
                }
            })
        }
    }

    const goBack = () => {
        setData('');
        props.setDetailId('');
    }

    useEffect(() => {
        getProfileDetail();
    }, []);

    useEffect(() => {
        if (data.id) {
            stickyNavLink(props.profileId)
        }
    }, [data]);


    return (
        <>
            {data &&
                <div className="container-fluid position-relative card-detail">
                    <div className="d-flex justify-content-between align-items-center">
                        <a className="btn-default" target="_blank" href={"https://een.ec.europa.eu/tools/services/ExpressionOfInterest/EOI/Create/" + data.pod_reference}>Express Your Interest</a>

                        <button className="btn-back" onClick={() => goBack()}>Back To Result</button>
                    </div>

                    <h3 className="title">
                        {data.detail_title}
                    </h3>

                    <div className="profile-key-info">
                        {data.detail_profile_type &&
                            <span>
                                {getItem().title === 'Technology' ? <IconTech /> : null}
                                {getItem().title === 'Research' ? <IconResearch /> : null}
                                {getItem().title === 'Business' ? <IconCommercial /> : null}

                                {getItem().title}
                            </span>
                        }

                        {data.client_country &&
                            <span>
                                <span className="flag">{getFlag()}</span>
                                {data.client_country}
                            </span>
                        }

                        <span>{data.pod_reference}</span>

                        {data.detail_profile_type &&
                            <span className={`${getItem().subtitle ? getItem().subtitle.toLowerCase() : null}`}>{getItem().subtitle}</span>
                        }
                    </div>

                    <div className={`sticky-nav-link ${isSticky ? 'sticky' : ''}`} style={{ top: stickyPos }}>
                        <button className={`sticky-nav-link__toggle ${toggleOpen ? 'open' : ''}`} onClick={() => toggleOpen ? setToggleOpen(false) : setToggleOpen(true)}>
                            <IconAngleDown />
                        </button>

                        <div className={`sticky-nav-link__list ${toggleOpen ? 'open' : ''}`}>
                            <button type="button" className="sticky-nav-link__links btn-click-key-info active" onClick={(e) => scrollDown(e, '#key-info')}>Key Information</button>

                            {(data.detail_summary || data.detail_description || data.detail_advantage || data.detail_expertise_sought || data.detail_development_stage || data.detail_development_stage_comment || data.detail_ipr_status || data.detail_ipr_comment || data.detail_profile_origin) &&
                                <button type="button" className="sticky-nav-link__links btn-click-info" onClick={(e) => scrollDown(e, '#info')}>Basic Information</button>
                            }

                            {(data.keywords_technology || data.keywords_market || data.keywords_nace) &&
                                <button type="button" className="sticky-nav-link__links btn-click-keywords" onClick={(e) => scrollDown(e, '#keywords')}>Keywords</button>
                            }

                            {(data.keywords_technology || data.keywords_market || data.keywords_nace) &&
                                <button type="button" className="sticky-nav-link__links btn-click-partner" onClick={(e) => scrollDown(e, '#partner')}>Partner Sought</button>
                            }

                            {(data.partnership_role_sought || data.partnership_size_sought || data.partnership_considered) &&
                                <button type="button" className="sticky-nav-link__links btn-click-client" onClick={(e) => scrollDown(e, '#client')}>Client</button>
                            }

                            {(data.client_type || data.company_since || data.client_company_turnover || data.client_transnational || data.client_language || data.client_country) &&
                                <button type="button" className="sticky-nav-link__links btn-click-dissemination" onClick={(e) => scrollDown(e, '#dissemination')}>Dissemination</button>
                            }

                            {(data.program_eoi_deadline || data.program_call_deadline || data.program_call_title || data.program_call_website || data.program_coordinator_required === 'yes' || data.program_evaluation || data.program_framework) &&
                                <button type="button" className="sticky-nav-link__links btn-click-programme" onClick={(e) => scrollDown(e, '#programee')}>Programme Call</button>
                            }
                        </div>
                    </div>

                    <div id="key-info" className="card">
                        <div className="row justify-content-center">
                            <div className="col-xs-12 col-lg-8">
                                <h3>Key Information</h3>
                                <ul className="card-lists card-lists--inline">
                                    <li>
                                        <h4>Published Date</h4>
                                        {data.key_information_submitted_date ?
                                            <p>
                                                <Moment format="DD MMM, YYYY">
                                                    {data.key_information_submitted_date}
                                                </Moment>
                                            </p>
                                            : '-'
                                        }
                                    </li>
                                    <li>
                                        <h4>Last Updated</h4>
                                        {data.key_information_updated_date ?
                                            <p>
                                                <Moment format="DD MMM, YYYY">
                                                    {data.key_information_updated_date}
                                                </Moment>
                                            </p>
                                            : '-'
                                        }
                                    </li>
                                    <li>
                                        <h4>Deadline</h4>
                                        {data.key_information_deadline_date ?
                                            <p>
                                                <Moment format="DD MMM, YYYY">
                                                    {data.key_information_deadline_date}
                                                </Moment>
                                            </p>
                                            : '-'
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {(data.detail_summary || data.detail_description || data.detail_advantage || data.detail_expertise_sought || data.detail_development_stage || data.detail_development_stage_comment || data.detail_ipr_status || data.detail_ipr_comment || data.detail_profile_origin) &&
                        <div id="info" className="card">
                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-lg-8">
                                    <h3>Basic Information</h3>
                                    <ul className="card-lists">
                                        <li>
                                            <h4>Summary</h4>
                                            <p>{data.detail_summary}</p>
                                        </li>

                                        {data.detail_description &&
                                            <li>
                                                <h4>Description</h4>
                                                <p dangerouslySetInnerHTML={{ __html: data.detail_description }}></p>
                                            </li>
                                        }

                                        {data.detail_advantage &&
                                            <li>
                                                <h4>Advantages and Innovations</h4>
                                                <p dangerouslySetInnerHTML={{ __html: data.detail_advantage }}></p>
                                            </li>
                                        }

                                        {data.detail_expertise_sought &&
                                            <li>
                                                <h4>Technical Specification or Expertise Sought</h4>
                                                <p dangerouslySetInnerHTML={{ __html: data.detail_expertise_sought }}></p>
                                            </li>
                                        }

                                        {data.detail_development_stage &&
                                            <li>
                                                <h4>Stage of Development</h4>
                                                <p>{data.detail_development_stage}</p>
                                            </li>
                                        }

                                        {data.detail_development_stage_comment &&
                                            <li>
                                                <h4>Stage of Development Comment</h4>
                                                <p>{data.detail_development_stage_comment}</p>
                                            </li>
                                        }

                                        {data.detail_ipr_status &&
                                            <li>
                                                <h4>IPR status</h4>
                                                <p>{data.detail_ipr_status}</p>
                                            </li>
                                        }

                                        {data.detail_ipr_comment &&
                                            <li>
                                                <h4>IPR status Comment</h4>
                                                <p>{data.detail_ipr_comment}</p>
                                            </li>
                                        }

                                        {data.detail_profile_origin &&
                                            <li>
                                                <h4>Profile Origin</h4>
                                                <p>{data.detail_profile_origin}</p>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                    {(data.keywords_technology || data.keywords_market || data.keywords_nace) &&
                        <div id="keywords" className="card">
                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-lg-8">
                                    <h3>Keywords</h3>
                                    <ul className="card-lists">
                                        {data.keywords_technology &&
                                            <li>
                                                <h4>Technology Keywords</h4>
                                                <p>{data.keywords_technology}</p>
                                            </li>
                                        }

                                        {data.keywords_market &&
                                            <li>
                                                <h4>Market Keywords</h4>
                                                <p>{data.keywords_market}</p>
                                            </li>
                                        }

                                        {data.keywords_nace &&
                                            <li>
                                                <h4>NACE Keywords</h4>
                                                <p>{data.keywords_nace}</p>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                    {(data.partnership_role_sought || data.partnership_size_sought || data.partnership_considered) &&
                        <div id="partner" className="card">
                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-lg-8">
                                    <h3>Partner Sought</h3>

                                    <ul className="card-lists">
                                        {data.partnership_role_sought &&
                                            < li >
                                                <h4>Type and Role of Partner Sought</h4>
                                                <p>{data.partnership_role_sought}</p>
                                            </li>
                                        }

                                        {data.partnership_size_sought &&
                                            <li>
                                                <h4>Type and Size of Partner Sought</h4>
                                                <p>{data.partnership_size_sought}</p>
                                            </li>
                                        }

                                        {data.partnership_considered &&
                                            <li>
                                                <h4>Type of Partnership Considered</h4>
                                                <p>
                                                    {data.partnership_considered.map((item, index) => {
                                                        return <span key={index}>{item}</span>
                                                    })}
                                                </p>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                    {(data.client_type || data.company_since || data.client_company_turnover || data.client_transnational || data.client_language || data.client_country) &&
                        <div id="client" className="card">
                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-lg-8">
                                    <h3>Client</h3>
                                    <ul className="card-lists card-lists--inline">
                                        {data.client_type &&
                                            <li>
                                                <h4>Type and Size of Client</h4>
                                                <p>{data.client_type}</p>
                                            </li>
                                        }

                                        {data.company_since && data.company_since !== '0' &&
                                            <li>
                                                <h4>Year Established</h4>
                                                <p>{data.company_since}</p>
                                            </li>
                                        }

                                        {data.client_company_turnover &&
                                            <li>
                                                <h4>Turnover (euro)</h4>
                                                <p>{data.client_company_turnover}</p>
                                            </li>
                                        }

                                        {data.client_transnational &&
                                            <li>
                                                <h4>Already Engaged in Trans-National Cooperation</h4>
                                                <p>{data.client_transnational ? 'Yes' : 'No'}</p>
                                            </li>
                                        }

                                        {data.client_language &&
                                            <li>
                                                <h4>Languages Spoken</h4>
                                                <p>{data.client_language}</p>
                                            </li>
                                        }

                                        {data.client_country &&
                                            <li>
                                                <h4>Client Country</h4>
                                                <p>{data.client_country}</p>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                    {(data.dissemination_sector_groups || data.dissemination_countries) &&
                        <div id="dissemination" className="card">
                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-lg-8">
                                    <h3>Dissemination</h3>
                                    <ul className="card-lists">
                                        {data.dissemination_sector_groups &&
                                            <li>
                                                <h4>Relevant Sector Groups</h4>
                                                <p>
                                                    {data.dissemination_sector_groups.map((item, index) => {
                                                        return <span key={index}>{item}</span>
                                                    })}
                                                </p>
                                            </li>
                                        }
                                        {data.dissemination_countries &&
                                            <li>
                                                <h4>Restrict dissemination to specific countries</h4>
                                                <p>
                                                    {data.dissemination_countries}
                                                </p>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                    {(data.program_eoi_deadline || data.program_call_deadline || data.program_call_title || data.program_call_website || data.program_coordinator_required === 'yes' || data.program_evaluation || data.program_framework) &&
                        <div id="programee" className="card">
                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-lg-8">
                                    <h3>Programme-Call</h3>
                                    <ul className="card-lists card-lists--inline">
                                        {data.program_framework &&
                                            <li>
                                                <h4>Framework Programme</h4>
                                                <p>
                                                    {data.program_framework}
                                                </p>
                                            </li>
                                        }

                                        {data.program_call_title &&
                                            <li>
                                                <h4>Call title and identifier</h4>
                                                <p>
                                                    {data.program_call_title}
                                                </p>
                                            </li>
                                        }

                                        {data.program_evaluation &&
                                            <li>
                                                <h4>Submission and evaluation scheme</h4>
                                                <p>
                                                    {data.program_evaluation}
                                                </p>
                                            </li>
                                        }

                                        {data.program_coordinator_required &&
                                            <li>
                                                <h4>Coordinator Required</h4>
                                                <p>
                                                    {data.program_coordinator_required}
                                                </p>
                                            </li>
                                        }

                                        {data.program_eoi_deadline &&
                                            <li>
                                                <h4>Deadline for EOI</h4>
                                                <p>
                                                    <Moment format="DD MMM, YYYY">
                                                        {data.program_eoi_deadline}
                                                    </Moment>
                                                </p>
                                            </li>
                                        }

                                        {data.program_call_deadline &&
                                            <li>
                                                <h4>Deadline of the Call</h4>
                                                <p>
                                                    <Moment format="DD MMM, YYYY">
                                                        {data.program_call_deadline}
                                                    </Moment>
                                                </p>
                                            </li>
                                        }

                                        {data.program_call_website &&
                                            <li>
                                                <h4>Weblink to the Call</h4>
                                                <p>
                                                    <a href={data.program_call_website} target="_blank">{data.program_call_website}</a>
                                                </p>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="d-flex justify-content-end mt-3">
                        <a className="btn-default" target="_blank" href={"https://een.ec.europa.eu/tools/services/ExpressionOfInterest/EOI/Create/" + data.pod_reference}>Express Your Interest</a>
                    </div>
                </div>
            }
        </>
    );
}

export default ProfileDetail;
