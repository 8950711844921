export const ProfileDetailCardGroup = [
    {
        "name": "Key Information",
        "cardSelector": "key-info",
        "buttonClass": "btn-click-key-info",
    },
    {
        "name": "Basic Information",
        "cardSelector": "info",
        "buttonClass": "btn-click-info",
    },
    {
        "name": "Keywords",
        "cardSelector": "keywords",
        "buttonClass": "btn-click-keywords",
    },
    {
        "name": "Partner Sought",
        "cardSelector": "partner",
        "buttonClass": "btn-click-partner",
    },
    {
        "name": "Client",
        "cardSelector": "client",
        "buttonClass": "btn-click-client",
    },
    {
        "name": "Dissemination",
        "cardSelector": "dissemination",
        "buttonClass": "btn-click-dissemination",
    },
    {
        "name": "Programme Call",
        "cardSelector": "programee",
        "buttonClass": "btn-click-programme",
    },
]