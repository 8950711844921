import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import Select from 'react-select'
import HeaderFilter from '../../components/filter-header';
import Pagination from '../../components/pagination';
import AsideFilter from './aside-filter';
import CardProfileList from '../../components/profile-card';
import profileService from '../../service/profile-service';
import ProfileDetail from './detail';
import './profile.scss';
import { IconAscending, IconDecending } from '../../components/svg';

function Profile() {
    const sortOptions = [
        { value: 'asc', label: 'Ascending' },
        { value: 'dsc', label: 'Descending' }
    ];

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(1);

    const [detailId, setDetailId] = useState();

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(20);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();

    const [fromDate, setFromDate] = useState(new Date('2019-10-31'));
    const [toDate, setToDate] = useState(new Date());

    const [sortVal, setSortVal] = useState({ value: 'dsc', label: 'Descending' });
    const [sort, setSort] = useState('dsc');
    const [search, setSearch] = useState('');
    const [country, setCountry] = useState('');

    const [keywordList, setKeywordList] = useState([]);
    const [technologyKeyword, setTechnologyKeyword] = useState([]);
    const [marketKeyword, setMarketKeyword] = useState([]);

    const [profileTypeArr, setProfileTypeArr] = useState(['TO', 'TR', 'BO', 'BR', 'RDR']);

    const getProfileData = async () => {
        try {
            const response = await profileService.getProfile(search, country, sort, limit, profileTypeArr, fromDate, toDate, skip, technologyKeyword, marketKeyword);
            if (response && response.data.data) {
                setData(response.data.data);
                setTotalData(response.data.totalcount)
                setIsSubmitted(false)
                setTotalPage(Math.ceil(response.data.totalcount / limit));
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onPageChange = page => {
        setCurrentPage(page);
        setSkip((page - 1) * limit);
        setIsSubmitted(true);
    }

    const onSort = selectedOption => {
        console.log(selectedOption)
        if (selectedOption) {
            setSort(selectedOption.value);
            setSortVal(selectedOption)
        }
        setIsSubmitted(true);
    }

    const getKeywords = async () => {
        try {
            const response = await profileService.getKeywords();
            if (response && response.data.data) {
                setKeywordList(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProfileData();
        getKeywords();
    }, []);

    useEffect(() => {
        if (isSubmitted) {
            getProfileData();
        }
    }, [isSubmitted]);

    return (
        <>
            {isSubmitted && <div className="loader"><span className="spin"></span></div>}

            <HeaderFilter
                isSubmitted={isSubmitted}
                setIsSubmitted={setIsSubmitted}
                setSkip={setSkip} search={search}
                setSearch={setSearch}
                country={country}
                setCountry={setCountry}
                setDetailId={setDetailId}
            />

            {!detailId &&
                <div className="container-fluid">

                    <div className="row justify-content-between search-results">
                        <div className="col-xs-12 col-md-4">
                            <AsideFilter
                                fromDate={fromDate}
                                setFromDate={setFromDate}
                                toDate={toDate}
                                setToDate={setToDate}
                                profileTypeArr={profileTypeArr}
                                setProfileTypeArr={setProfileTypeArr}
                                isSubmitted={isSubmitted}
                                setIsSubmitted={setIsSubmitted}
                                keywordList={keywordList}
                                technologyKeyword={technologyKeyword}
                                setTechnologyKeyword={setTechnologyKeyword}
                                marketKeyword={marketKeyword}
                                setMarketKeyword={setMarketKeyword}
                            />
                        </div>

                        <div className="col-xs-12 col-md-8">
                            {totalData > 0 ?
                                <>
                                    <div className="d-flex flex-column-reverse flex-md-row align-items-start">
                                        <h4 className="results-found">
                                            <b>{totalData}</b> results found.
                                        </h4>

                                        <div className="form-group sorting">
                                            <label>Sort By</label>
                                            <Select
                                                className="custom-select"
                                                classNamePrefix="select-control"
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.value}
                                                options={sortOptions}
                                                value={sortVal}
                                                onChange={onSort}
                                            />
                                        </div>
                                    </div>

                                    {data.map((value, index) => {
                                        return (
                                            <div className="search-results__group" key={index}>
                                                <h4>
                                                    <Moment format="DD MMM, YYYY">
                                                        {value.submitted_date}
                                                    </Moment>
                                                </h4>

                                                <CardProfileList
                                                    setDetailId={setDetailId}
                                                    profileData={value}
                                                />

                                                {/* <button className="btn-view-more" type="button">
                                            View other 18 entries from <b>{value.date}</b>
                                            <img src={angleDown} alt="down" />
                                        </button> */}
                                            </div>
                                        )
                                    })}

                                    <Pagination
                                        currentPage={currentPage}
                                        totalPage={totalPage}
                                        onChangePage={(i) => onPageChange(i)}
                                    />
                                </>
                                :
                                <h4 className="results-found">No data found</h4>
                            }
                        </div>
                    </div>
                </div>
            }

            {detailId ? <ProfileDetail setDetailId={setDetailId} profileId={detailId} /> : null}
        </>
    );
}

export default Profile;