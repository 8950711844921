import React from 'react';

const IconAngleDoubleLeft = () => {
    return (
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 532.153 532.153" space="preserve">
            <g>
                <g>
                    <path d="M224.334,510.96c8.758,13.709,23.586,21.193,38.721,21.193c8.458,0,17.014-2.338,24.67-7.228
			c21.358-13.647,27.619-42.032,13.966-63.391l-124.879-195.46l124.879-195.46c13.647-21.365,7.393-49.744-13.966-63.391
			c-21.354-13.642-49.744-7.393-63.392,13.966L83.66,241.362c-9.627,15.067-9.627,34.357,0,49.425L224.334,510.96z"/>
                    <path d="M230.454,290.787L371.128,510.96c8.765,13.709,23.587,21.193,38.722,21.193c8.458,0,17.014-2.338,24.67-7.228
			c21.358-13.647,27.613-42.032,13.966-63.391l-124.885-195.46l124.891-195.46c13.642-21.359,7.393-49.744-13.966-63.391
			c-21.353-13.642-49.743-7.393-63.391,13.966L230.454,241.362C220.821,256.43,220.821,275.72,230.454,290.787z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>

    );
}

export default IconAngleDoubleLeft;