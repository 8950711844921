import Req from "../interceptor/request-interceptor";
import Moment from 'moment';

const ProfileService = (function () {
    function _getProfile(keywords = '', country = '', sort = 'dsc', limit = 20, profileType = '', datefrom = '', dateto = '', skip = 0, technologyKeyword = '', marketKeyword = '') {
        let kparam = '';
        let cParam = '';
        let ptParam = '';
        let dfParam = '';
        let dtParam = '';
        let tkParam = '';
        let mkParam = '';

        if (keywords) {
            // kparam = keywords.replace('+', ',');
            kparam = keywords.split('+').join(',')
        }

        if (country) {
            country.map((value) => {
                cParam += '&c[]=' + value.name;
            })
        }

        if (profileType) {
            profileType.map((value) => {
                ptParam += '&pt[]=' + value;
            })
        }

        if (datefrom) {
            dfParam = Moment(datefrom).format('YYYYMMDD');
        }

        if (dateto) {
            dtParam = Moment(dateto).format('YYYYMMDD');
        }

        console.log(technologyKeyword, marketKeyword);
        if (technologyKeyword) {
            technologyKeyword.map((value) => {
                tkParam += '&tk[]=' + value.title;
            })
        }

        if (marketKeyword) {
            marketKeyword.map((value) => {
                mkParam += '&mk[]=' + value.title;
            })
        }

        return Req.get(`/profiles/list?k=${kparam}&sort=${sort}&limit=${limit}&df=${dfParam}&dt=${dtParam}${cParam}${tkParam}${mkParam}${ptParam}&skip=${skip}`);
    }

    function _getProfileById(id) {
        return Req.get("/profiles/single/" + id);
    }

    function _getKeywords() {
        return Req.get("profiles/keywords");
    }

    return {
        getProfile: _getProfile,
        getProfileById: _getProfileById,
        getKeywords: _getKeywords
    };
})();
export default ProfileService;