import React, { useState, useEffect } from 'react';
import { ProfileType } from '../../config/profile-type';
import { useMediaQuery } from 'react-responsive'
import { DownMediumDevice } from '../../components/media-breakpoint';
import { Collapse, UncontrolledCollapse } from 'reactstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import "react-datepicker/dist/react-datepicker.css";
import { IconTech, IconResearch, IconCommercial, IconCalendar, IconAngleDown } from '../../components/svg';

function AsideFilter(props) {
    // Media Queries
    const handleMediaQueryChange = (matches) => {
        if (isDownMediumDevice) {
            setDateRangeCollapse(false)
            setProfileTypesCollapse(false)
            setKeywordsCollapse(false)
            setProfileInfo(false)
        } else {
            setDateRangeCollapse(true)
            setProfileTypesCollapse(true)
            setKeywordsCollapse(true)
            setProfileInfo(true)
        }
        // matches will be true or false based on the value for the media query
    }
    const isDownMediumDevice = useMediaQuery(
        { query: '(max-width: 768px)' }, undefined, handleMediaQueryChange
    );

    const animatedComponents = makeAnimated();

    const [dateRangeCollapse, setDateRangeCollapse] = useState(true);
    const [profileTypesCollapse, setProfileTypesCollapse] = useState(true);
    const [keywordsCollapse, setKeywordsCollapse] = useState(true);
    const [profileInfoCollapse, setProfileInfo] = useState(true);

    const openDateRange = () => setDateRangeCollapse(!dateRangeCollapse);
    const openProfileTypes = () => setProfileTypesCollapse(!profileTypesCollapse);
    const openKeywords = () => setKeywordsCollapse(!keywordsCollapse);
    const openProfileInfo = () => setProfileInfo(!profileInfoCollapse);

    const onFromDateChange = date => {
        props.setFromDate(date);
        props.setIsSubmitted(true);
    }

    const onToDateChange = date => {
        props.setToDate(date);
        props.setIsSubmitted(true);
    }

    const onProfileTypeChecked = (val, slug) => {
        if (val) {
            props.setProfileTypeArr(result => [...result, slug]);
        } else {
            const filteredItems = props.profileTypeArr.filter(function (item) {
                return item !== slug
            })
            props.setProfileTypeArr(filteredItems);
        }

        ProfileType.map((item) => {
            if (item.slug === slug) {
                item.isChecked = val;
            }
        });

        props.setIsSubmitted(true);
    }

    const onTechnologyKeywordChange = selectedOption => {
        if (selectedOption) {
            props.setTechnologyKeyword(selectedOption);
        } else {
            props.setTechnologyKeyword('');
        }
        props.setIsSubmitted(true);
    }

    const onMarketKeywordChange = selectedOption => {
        if (selectedOption) {
            props.setMarketKeyword(selectedOption);
        } else {
            props.setMarketKeyword('');
        }
        props.setIsSubmitted(true);
    }

    return (
        <>
            <aside className="aside-filter">
                <h4 id="datePicker" className={isDownMediumDevice ? '' : 'pointer-event-none'} onClick={openDateRange} >
                    Date Range
                    <DownMediumDevice>
                        <IconAngleDown />
                    </DownMediumDevice>
                </h4>
                <Collapse isOpen={dateRangeCollapse}>
                    <div className="aside-filter__date-pickers mb-5">
                        <IconCalendar />
                        <div className="dates">
                            <DatePicker
                                className="date-picker"
                                placeholderText="From Date"
                                showPopperArrow={true}
                                selected={props.fromDate}
                                onChange={onFromDateChange}
                                dateFormat="d MMM, yyyy"
                                popperPlacement="top-start"
                                disabledKeyboardNavigation
                            />
                        </div>
                    TO
                    <div className="dates">
                            <DatePicker
                                className="date-picker text-right"
                                placeholderText="To Date"
                                showPopperArrow={true}
                                selected={props.toDate}
                                onChange={onToDateChange}
                                dateFormat="d MMM, yyyy"
                                popperPlacement="top-end"
                                disabledKeyboardNavigation
                            />
                        </div>
                        <IconCalendar />
                    </div>
                </Collapse>
                <hr />

                <h4 id="profileTypes" className={isDownMediumDevice ? '' : 'pointer-event-none'} onClick={openProfileTypes}>
                    Profile Types
                    <DownMediumDevice>
                        <IconAngleDown />
                    </DownMediumDevice>
                </h4>
                <Collapse isOpen={profileTypesCollapse}>
                    <ul className="aside-filter__list">
                        {ProfileType.map((profile, index) => {
                            return (
                                <li key={index}>
                                    <span>
                                        {profile.title === 'Technology' ? <IconTech /> : null}
                                        {profile.title === 'Research' ? <IconResearch /> : null}
                                        {profile.title === 'Business' ? <IconCommercial /> : null}

                                        <span>{profile.name}</span>
                                    </span>
                                    <label className="checkbox bounce">
                                        <input id={index} name={index} type="checkbox" checked={profile.isChecked} onChange={(e) => onProfileTypeChecked(e.target.checked, profile.slug)} />
                                        <svg viewBox="0 0 21 21">
                                            <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                                        </svg>
                                    </label>
                                </li>
                            )
                        })}
                    </ul>
                </Collapse>
                <hr />

                <h4 id="keywords" className={isDownMediumDevice ? '' : 'pointer-event-none'} onClick={openKeywords}>
                    Keywords
                    <DownMediumDevice>
                        <IconAngleDown />
                    </DownMediumDevice>
                </h4>
                <Collapse isOpen={keywordsCollapse}>
                    <div className="form-group">
                        <label>Technology keywords</label>
                        <Select
                            className="w-100 custom-select"
                            classNamePrefix="select-control"
                            isMulti
                            components={animatedComponents}
                            isClearable={false}
                            getOptionLabel={option => option.key + ' - ' + option.title}
                            getOptionValue={option => option.title}
                            options={props.keywordList.technology_keywords}
                            value={props.technologyKeyword}
                            placeholder="Select Technology Keywords"
                            onChange={onTechnologyKeywordChange}
                        />
                    </div>

                    <div className="form-group">
                        <label>Market keywords</label>
                        <Select
                            className="w-100 custom-select"
                            classNamePrefix="select-control"
                            isMulti
                            components={animatedComponents}
                            isClearable={false}
                            getOptionLabel={option => option.key + ' - ' + option.title}
                            getOptionValue={option => option.title}
                            options={props.keywordList.market_keywords}
                            value={props.marketKeyword}
                            placeholder="Select Market Keywords"
                            onChange={onMarketKeywordChange}
                        />
                    </div>
                </Collapse>
                <hr />

                <h4 id="profileInfo" onClick={openProfileInfo}>
                    Profile Information
                    <IconAngleDown />
                </h4>
                <Collapse isOpen={profileInfoCollapse}>
                    <ul className="aside-filter__list aside-filter__list--info">
                        <li>
                            <span><IconTech /> Technology Profile can either be a :</span>
                            <p>
                                <span className="offer">TECHNOLOGY OFFER <span>TO</span></span>
                                A company offers technology or expertise to sell or share with another company.
                            </p>
                            <p>
                                <span className="request">TECHNOLOGY REQUEST <span>TR</span></span>
                                A company is looking to partner with another company to fulfil a technology need.
                            </p>
                        </li>
                        <li>
                            <span><IconCommercial /> Business Profile can either be a :</span>
                            <p>
                                <span className="offer">BUSINESS OFFER <span>BO</span></span>
                                A company is looking for partners (e.g. distributors, agents) by offering their products or services to other companies.
                            </p>
                            <p>
                                <span className="request">BUSINESS REQUEST <span>BR</span></span>
                                A company is requesting products or services of another company to complete their product range or to distribute in another country.
                            </p>
                        </li>
                        <li>
                            <span className="request"><IconResearch /> Research & Development Request <span>RDR</span></span>
                            <p>
                                An organization is looking for partners to join a consortium for a research project under a specific call to be funded by the European Union.
                            </p>
                        </li>
                    </ul>
                </Collapse>
            </aside >

            <DownMediumDevice>
                <hr style={{ marginBottom: '3rem' }} />
            </DownMediumDevice>
        </>
    );
}

export default AsideFilter;