import React from 'react';
import { ProfileType } from '../../config/profile-type';
import { CountryList } from '../../config/country';
import { IconTech, IconResearch, IconCommercial } from '../svg';

function CardProfileList(props) {

    const getItem = () => {
        const res = ProfileType.find(function (item) {
            return item.slug === props.profileData.profile_type
        });
        return res ? res : null;
    }

    const getFlag = () => {
        const res = CountryList.find(function (item) {
            return item.name === props.profileData.company_country_label
        });
        return res ? res.emoji : null;
    }

    return (
        <article className="profile-card-view" key={props.profileData.id}>
            <div className="profile-card-view__subtitle">
                <h6>
                    <span>
                        {getItem().title === 'Technology' ? <IconTech /> : null}
                        {getItem().title === 'Research' ? <IconResearch /> : null}
                        {getItem().title === 'Business' ? <IconCommercial /> : null}

                        {getItem().title} /
                    </span>
                    <span className="country">
                        <span className="flag">{getFlag()}</span>
                        {props.profileData.company_country_label} /
                    </span>
                    <span>{props.profileData.reference_external}</span>

                    <span className={`${getItem().subtitle ? getItem().subtitle.toLowerCase() : null}`}>{getItem().subtitle}</span>
                </h6>
            </div>
            <h4 onClick={() => props.setDetailId(props.profileData.id)}>{props.profileData.title}</h4>
            <p>{props.profileData.summary.substring(0, 195) + "..."}</p>
        </article>
    );
}

export default CardProfileList;
