import { useMediaQuery } from 'react-responsive'

// greater than media query
const UpSmallDevice = ({ children }) => {
    const isUpSmallDevice = useMediaQuery({ minWidth: 576 })
    return isUpSmallDevice ? children : null
}
const UpMediumDevice = ({ children }) => {
    const isUpMediumDevice = useMediaQuery({ minWidth: 768 })
    return isUpMediumDevice ? children : null
}
const UpLargeDevice = ({ children }) => {
    const isUpLargeDevice = useMediaQuery({ minWidth: 992 })
    return isUpLargeDevice ? children : null
}
const UpExtraLargeDevice = ({ children }) => {
    const isUpExtraLargeDevice = useMediaQuery({ minWidth: 1200 })
    return isUpExtraLargeDevice ? children : null
}

// Down than media query
const DownSmallDevice = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 576 })
    return isDesktop ? children : null
}
const DownMediumDevice = ({ children }) => {
    const isDownMediumDevice = useMediaQuery({ maxWidth: 768 })
    return isDownMediumDevice ? children : null
}
const DownLargeDevice = ({ children }) => {
    const isDownLargeDevice = useMediaQuery({ maxWidth: 992 })
    return isDownLargeDevice ? children : null
}
const DownExtraLargeDevice = ({ children }) => {
    const isDownExtraLargeDevice = useMediaQuery({ maxWidth: 1200 })
    return isDownExtraLargeDevice ? children : null
}

export { UpSmallDevice, UpMediumDevice, UpLargeDevice, UpExtraLargeDevice, DownSmallDevice, DownMediumDevice, DownLargeDevice, DownExtraLargeDevice };