import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import './App.scss';
import Profile from './views/profile';

function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <Profile />
          </Route>
          {/* <Route path="/:id">
            <ProfileDetail />
          </Route> */}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
