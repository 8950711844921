import React from 'react';

const IconCommercial = () => {
	return (
		<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			viewBox="0 0 56.48 56.48" space="preserve">
			<g>
				<g>
					<path d="M9.276,34.346H8.099c-0.149,0-0.271,0.135-0.271,0.301v2.378c0,0.166,0.122,0.301,0.271,0.301h1.177
			c0.149,0,0.27-0.135,0.27-0.301v-2.378C9.546,34.482,9.426,34.346,9.276,34.346z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M21.2,14.875c-0.258,0.034-0.387,0.153-0.387,0.354c0,0.068,0.021,0.119,0.065,0.156c0.043,0.036,0.105,0.055,0.184,0.055
			c0.134,0,0.263-0.04,0.388-0.121c0.124-0.081,0.198-0.174,0.22-0.278l0.063-0.299C21.65,14.794,21.471,14.838,21.2,14.875z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M47.292,11.986c-0.709,0.411-1.188,1.175-1.188,2.054c0,0.878,0.479,1.642,1.188,2.054
			c0.71-0.411,1.187-1.176,1.187-2.054C48.479,13.161,48.002,12.396,47.292,11.986z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M33.4,34.344h-1.176c-0.149,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.121,0.301,0.271,0.301H33.4
			c0.148,0,0.271-0.135,0.271-0.301v-2.378C33.672,34.482,33.551,34.344,33.4,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M21.195,34.344h-1.176c-0.149,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.121,0.301,0.271,0.301h1.176
			c0.149,0,0.271-0.135,0.271-0.301v-2.378C21.466,34.479,21.345,34.344,21.195,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M35.922,34.344h-1.176c-0.15,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.121,0.301,0.271,0.301h1.176
			c0.149,0,0.271-0.135,0.271-0.301v-2.378C36.192,34.482,36.071,34.344,35.922,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M30.879,34.344h-1.176c-0.148,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.121,0.301,0.271,0.301h1.176
			c0.15,0,0.271-0.135,0.271-0.301v-2.378C31.149,34.482,31.029,34.344,30.879,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M6.754,34.346H5.579c-0.15,0-0.271,0.135-0.271,0.301v2.378c0,0.166,0.12,0.301,0.271,0.301h1.176
			c0.149,0,0.269-0.135,0.269-0.301v-2.378C7.024,34.482,6.904,34.346,6.754,34.346z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M25.733,14.179c-0.059-0.079-0.149-0.119-0.271-0.119c-0.19,0-0.346,0.09-0.467,0.27c-0.121,0.18-0.182,0.383-0.182,0.611
			c0,0.131,0.034,0.236,0.102,0.314s0.158,0.119,0.273,0.119c0.187,0,0.339-0.087,0.457-0.261c0.118-0.174,0.178-0.367,0.178-0.579
			C25.823,14.377,25.793,14.26,25.733,14.179z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M26.237,34.344h-1.176c-0.149,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.121,0.301,0.271,0.301h1.176
			c0.15,0,0.271-0.135,0.271-0.301v-2.378C26.508,34.482,26.387,34.344,26.237,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M38.442,34.344h-1.177c-0.148,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.121,0.301,0.271,0.301h1.177
			c0.149,0,0.271-0.135,0.271-0.301v-2.378C38.713,34.482,38.594,34.344,38.442,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="6.76,26.531 6.76,26.814 9.558,26.812 9.558,26.81 9.558,26.533 9.558,26.531 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M51.261,28.846c-0.052,0.016-0.054,0.016-0.063,0.011c-0.007-0.004-0.015-0.008-0.019-0.01
			c-0.002,0-0.002-0.002-0.002-0.006c0.002-0.01-0.002-0.004-0.018,0.015c-0.007,0.009-0.016,0.024-0.018,0.034
			c0,0.011-0.004,0.022-0.008,0.027c-0.004,0.006-0.006,0.016-0.004,0.026c0,0.015,0.002,0.017,0.017,0.017
			c0.017,0,0.052-0.014,0.052-0.021c0-0.002,0.002-0.002,0.007,0c0.005,0.003,0.011,0,0.021-0.012
			c0.01-0.006,0.015-0.016,0.015-0.02c0-0.004,0.006-0.008,0.011-0.009c0.006-0.001,0.014-0.005,0.016-0.013
			c0-0.004,0.009-0.016,0.016-0.025C51.302,28.838,51.293,28.833,51.261,28.846z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="11.577,24.953 11.577,25.004 11.577,25.232 11.577,25.284 14.288,25.284 14.288,24.953 		" />
				</g>
			</g>
			<g>
				<g>
					<polygon points="6.76,24.953 6.76,25.284 9.558,25.284 9.558,25.232 9.558,25.004 9.558,24.953 		" />
				</g>
			</g>
			<g>
				<g>
					<rect x="14.288" y="26.812" width="0.335" height="1.297" />
				</g>
			</g>
			<g>
				<g>
					<polygon points="11.577,26.531 11.577,26.533 11.577,26.81 11.577,26.812 14.288,26.812 14.288,26.531 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M13.951,41.669c-0.051-0.159-0.086-0.299-0.122-0.438h-0.011c-0.031,0.139-0.07,0.288-0.117,0.434l-0.234,0.693h0.722
			L13.951,41.669z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="6.512" y="26.812" width="0.247" height="1.297" />
				</g>
			</g>
			<g>
				<g>
					<path d="M23.717,34.344h-1.176c-0.15,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.12,0.301,0.271,0.301h1.176
			c0.149,0,0.271-0.135,0.271-0.301v-2.378C23.987,34.482,23.866,34.344,23.717,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="6.512" y="23.706" width="0.247" height="1.247" />
				</g>
			</g>
			<g>
				<g>
					<path d="M14.317,34.344h-1.176c-0.15,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.12,0.301,0.271,0.301h1.176
			c0.149,0,0.271-0.135,0.271-0.301v-2.378C14.588,34.482,14.467,34.344,14.317,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="6.512" y="25.284" width="0.247" height="1.246" />
				</g>
			</g>
			<g>
				<g>
					<path d="M18.675,34.344h-1.177c-0.149,0-0.271,0.136-0.271,0.302v2.378c0,0.166,0.121,0.301,0.271,0.301h1.177
			c0.149,0,0.271-0.135,0.271-0.301v-2.378C18.945,34.482,18.824,34.344,18.675,34.344z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M9.049,41.18c-0.149,0-0.253,0.014-0.307,0.027v0.871h0.316c0.331,0,0.541-0.181,0.541-0.455
			C9.599,41.317,9.375,41.18,9.049,41.18z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M12.848,14.179c-0.06-0.079-0.15-0.119-0.271-0.119c-0.19,0-0.347,0.09-0.468,0.27c-0.12,0.18-0.181,0.383-0.181,0.611
			c0,0.131,0.033,0.236,0.101,0.314s0.158,0.119,0.273,0.119c0.187,0,0.339-0.087,0.458-0.261c0.118-0.174,0.177-0.367,0.177-0.579
			C12.937,14.377,12.908,14.26,12.848,14.179z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M21.807,41.18c-0.149,0-0.253,0.014-0.307,0.027v0.871h0.316c0.331,0,0.541-0.181,0.541-0.455
			C22.358,41.317,22.134,41.18,21.807,41.18z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="14.288" y="25.284" width="0.335" height="1.246" />
				</g>
			</g>
			<g>
				<g>
					<path d="M11.66,42.205h-0.257v0.914c0.064,0.012,0.149,0.016,0.26,0.016c0.32,0,0.615-0.119,0.615-0.467
			C12.278,42.343,11.997,42.205,11.66,42.205z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M11.678,41.176c-0.143,0-0.225,0.012-0.274,0.021v0.775h0.281c0.323,0.002,0.515-0.173,0.515-0.404
			C12.2,41.291,11.99,41.176,11.678,41.176z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M10.479,14.125c-0.06-0.057-0.146-0.084-0.257-0.084c-0.257,0-0.447,0.151-0.572,0.454h0.908
			c0.007-0.05,0.01-0.089,0.01-0.12C10.568,14.263,10.539,14.179,10.479,14.125z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M11.795,34.346H10.62c-0.149,0-0.271,0.135-0.271,0.301v2.378c0,0.166,0.122,0.301,0.271,0.301h1.175
			c0.15,0,0.271-0.135,0.271-0.301v-2.378C12.066,34.482,11.945,34.346,11.795,34.346z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M46.142,25.486c-0.01-0.006-0.01-0.01-0.004-0.028c0.003-0.01,0.004-0.027,0.003-0.04c0-0.012-0.002-0.02-0.005-0.013
			c-0.004,0.004-0.012,0.01-0.019,0.012c-0.006,0.001-0.013,0.008-0.015,0.013c-0.002,0.014,0.006,0.039,0.015,0.046
			c0.009,0.005,0.009,0.007-0.004,0.016C46.1,25.5,46.096,25.5,46.092,25.495c-0.004-0.004-0.004-0.003-0.006,0.003
			c-0.003,0.006,0,0.014,0.002,0.02c0.006,0.006,0.008,0.015,0.008,0.021c-0.008,0.042-0.008,0.045,0.004,0.052
			c0.01,0.005,0.013,0.004,0.017-0.004c0.004-0.005,0.011-0.008,0.017-0.006c0.01,0.004,0.012-0.005,0.015-0.051
			C46.151,25.5,46.149,25.491,46.142,25.486z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="43.45" y="24.453" width="0.004" height="0.009" />
				</g>
			</g>
			<g>
				<g>
					<polygon points="41.323,25.448 41.325,25.448 41.327,25.445 		" />
				</g>
			</g>
			<g>
				<g>
					<polygon points="45.242,24.151 45.239,24.151 45.237,24.153 45.248,24.153 		" />
				</g>
			</g>
			<g>
				<g>
					<polygon points="43.203,24.482 43.205,24.484 43.205,24.479 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M43.767,24.7c-0.002,0-0.002,0.002-0.004,0.002c0.005,0.001,0.009,0,0.009-0.002C43.775,24.7,43.769,24.7,43.767,24.7z" />
				</g>
			</g>
			<g>
				<g>
					<path d="M42.799,26.505c-0.008-0.008-0.021-0.013-0.029-0.012c-0.012,0-0.014-0.002-0.014-0.007c0-0.006-0.005-0.01-0.021-0.013
			c-0.022-0.005-0.055-0.027-0.055-0.039c0-0.003-0.002-0.004-0.004-0.002c0,0-0.006,0-0.01-0.004c-0.009-0.008-0.025-0.008-0.021,0
			c0.004,0.003,0.003,0.004-0.004,0.002c-0.004-0.001-0.012-0.002-0.018-0.002c-0.004,0-0.008-0.002-0.006-0.005
			c0.004-0.014-0.091-0.027-0.122-0.017c-0.008,0.003-0.024,0.009-0.04,0.013c-0.012,0.002-0.027,0.011-0.033,0.015
			c-0.006,0.006-0.013,0.01-0.018,0.01c-0.008,0.001-0.008,0.002,0,0.005c0.005,0.004,0.005,0.005-0.004,0.008
			c-0.016,0.004-0.016,0.01,0,0.012c0.005,0.002,0.016-0.002,0.025-0.007c0.006-0.005,0.016-0.01,0.021-0.01
			c0.006,0,0.015-0.004,0.021-0.01c0.01-0.008,0.02-0.011,0.035-0.011c0.014,0,0.016,0,0.014,0.002
			c-0.006,0-0.012,0.003-0.012,0.006c0,0.003,0.033,0.012,0.046,0.01c0.006,0,0.019,0.004,0.035,0.01
			c0.016,0.006,0.032,0.009,0.038,0.006c0.011-0.001,0.013-0.001,0.013,0.01c0,0.006,0.004,0.014,0.008,0.018
			c0.003,0.003,0.009,0.008,0.009,0.011c0,0.004,0.003,0.01,0.009,0.011c0.004,0.004,0.008,0.01,0.006,0.014
			c-0.006,0.008,0.108,0.001,0.131-0.008c0.004-0.001,0.01-0.004,0.016-0.006C42.809,26.512,42.803,26.508,42.799,26.505z
			 M42.676,26.512c-0.01,0.001-0.018-0.001-0.018-0.003c0-0.002,0.006-0.005,0.014-0.005s0.014,0.002,0.017,0.003
			C42.69,26.51,42.684,26.51,42.676,26.512z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M42.889,25.841c-0.006,0.001-0.004,0.004,0.004,0.006c0.006,0.002,0.008,0.002,0.006-0.002
			C42.896,25.843,42.893,25.841,42.889,25.841z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.018,26.562c-0.01-0.01-0.023-0.016-0.028-0.016c-0.008,0-0.013-0.004-0.013-0.007c0-0.01-0.043-0.018-0.071-0.012
			c-0.019,0.003-0.026,0.002-0.034-0.002c-0.007-0.006-0.011-0.006-0.023,0.002c-0.019,0.008-0.021,0.015,0,0.014
			c0.004,0,0.009,0.003,0.009,0.013c0,0.007,0.002,0.016,0.005,0.02c0.011,0.009-0.01,0.014-0.041,0.009
			c-0.023-0.003-0.028-0.003-0.031,0.005c-0.006,0.007-0.004,0.01,0.01,0.016c0.014,0.005,0.023,0.008,0.019,0.002
			c-0.002-0.003,0.013-0.004,0.029-0.004c0.029-0.001,0.033,0,0.043,0.011c0.004,0.005,0.008,0.01,0.012,0.014
			c0.004-0.005,0.007-0.012,0.013-0.018c0.013-0.017,0.021-0.021,0.022-0.013c0,0.003,0.008,0.002,0.018-0.003
			c0.016-0.008,0.047-0.007,0.051,0.001c0,0.003,0.006,0.005,0.01,0.004c0.005,0,0.009-0.001,0.005-0.003
			c-0.003-0.002,0.002-0.006,0.007-0.01C43.038,26.58,43.038,26.578,43.018,26.562z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.984,24.471c-0.012,0.001-0.012,0.004,0.004,0.006c0.005,0.002,0.009,0,0.005-0.002
			C43.993,24.474,43.988,24.471,43.984,24.471z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.887,24.385c-0.003,0,0,0.003,0,0.005c0.007-0.001,0.015-0.003,0.015-0.003C43.902,24.385,43.894,24.385,43.887,24.385
			z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="43.822,24.338 43.823,24.341 43.829,24.338 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M44.32,27.614c-0.003-0.004-0.017-0.007-0.039-0.01c-0.033-0.003-0.033-0.004-0.076-0.045
			c-0.017-0.015-0.043-0.034-0.06-0.043c-0.028-0.015-0.031-0.016-0.051-0.013c-0.018,0.003-0.026,0.003-0.039-0.002
			c-0.008-0.004-0.016-0.006-0.02-0.005c-0.003,0.003-0.012,0-0.02-0.004c-0.015-0.011-0.047-0.017-0.041-0.009
			c0.002,0.004,0,0.004-0.01,0.002c-0.014-0.006-0.023-0.015-0.021-0.022c0.002-0.004-0.002-0.009-0.006-0.014
			c-0.006-0.005-0.01-0.01-0.01-0.014c0-0.002-0.002-0.005-0.002-0.005c-0.004,0-0.009,0.002-0.009,0.006
			c0,0.004-0.036-0.012-0.043-0.021c-0.001-0.002-0.005-0.002-0.005,0c0,0.003-0.002,0.002-0.005-0.002
			c-0.011-0.005-0.023-0.01-0.045-0.013c-0.015-0.001-0.034,0.01-0.039,0.026c-0.009,0.021-0.011,0.011-0.004-0.012
			c0.01-0.029,0.01-0.031-0.011-0.035c-0.011-0.001-0.022-0.008-0.026-0.013c-0.004-0.007-0.011-0.011-0.015-0.011
			c-0.008,0-0.008-0.008,0-0.037c0.002-0.01,0-0.017-0.01-0.029c-0.007-0.008-0.016-0.015-0.018-0.015
			c-0.008,0-0.02-0.04-0.02-0.061c0-0.011-0.021-0.046-0.021-0.036c0,0.003-0.014-0.008-0.03-0.024
			c-0.016-0.014-0.033-0.027-0.037-0.027c-0.001,0-0.013-0.004-0.022-0.007c-0.021-0.01-0.08-0.015-0.085-0.008
			c-0.006,0.009-0.045-0.005-0.068-0.026c-0.01-0.01-0.023-0.019-0.027-0.019c-0.002,0-0.004-0.006-0.004-0.012
			c-0.002-0.006-0.006-0.016-0.012-0.022c-0.015-0.016-0.058-0.04-0.068-0.038c-0.012,0.004-0.021-0.006-0.023-0.024
			c0.009-0.011,0.005-0.021-0.008-0.027c-0.007-0.002-0.013-0.006-0.013-0.007c0-0.003-0.004-0.005-0.008-0.005
			c-0.008,0-0.014-0.003-0.018-0.006c-0.005-0.005-0.007-0.005-0.014,0.001c-0.002,0.004-0.004,0.005-0.004,0.002
			s-0.002-0.009-0.004-0.013c-0.002-0.005,0-0.008,0.006-0.008c0.002,0,0.012-0.005,0.018-0.008c0.01-0.008,0.005-0.007-0.039-0.004
			c-0.03,0.001-0.054,0.003-0.056,0.003s-0.005,0.003-0.005,0.006c0,0.003-0.006,0.01-0.012,0.013
			c-0.016,0.009-0.052-0.001-0.052-0.012c0-0.006-0.007-0.006-0.044-0.004c-0.018,0.001-0.031,0.003-0.047,0.005
			c0-0.005,0-0.009,0-0.014c0-0.014-0.025-0.031-0.045-0.03c-0.01-0.001-0.014-0.005-0.014-0.012c0-0.015-0.004-0.019-0.014-0.015
			c-0.003,0.002-0.007,0.01-0.007,0.019c0,0.015-0.004,0.018-0.015,0.021c-0.009,0.002-0.022,0.008-0.03,0.014
			c-0.015,0.008-0.017,0.007-0.013,0.001c0.002-0.005,0-0.008-0.002-0.008c-0.004,0-0.006-0.006-0.006-0.011
			c0-0.007,0.004-0.009,0.01-0.009c0.007,0,0.021-0.014,0.023-0.023c0-0.011-0.017-0.015-0.035-0.01
			c-0.006,0.003-0.014,0.008-0.014,0.014s-0.004,0.009-0.007,0.009c-0.003,0-0.015,0.005-0.021,0.011
			c-0.008,0.006-0.021,0.01-0.033,0.01c-0.012,0.001-0.021,0.003-0.021,0.008c-0.004,0.004-0.009,0.006-0.016,0.004
			c-0.023-0.004-0.05,0.036-0.048,0.063c0,0.002-0.002,0.004-0.002,0.004c-0.007,0-0.021,0.013-0.021,0.018
			c0,0.002-0.008,0.008-0.018,0.012c-0.01,0.008-0.014,0.012-0.012,0.018c0.001,0.004-0.006-0.001-0.021-0.014
			c-0.018-0.019-0.053-0.037-0.067-0.036c0,0-0.002,0-0.002,0.001c0.004,0.001,0.003,0.012,0,0.019
			c-0.005,0.001-0.006,0-0.015,0.002v-0.001c-0.004,0-0.019,0.007-0.038,0.017c-0.039,0.019-0.044,0.02-0.057,0.009
			c-0.007-0.005-0.011-0.005-0.011,0s0,0.005-0.008,0c-0.004-0.003-0.006-0.008-0.006-0.012c0.002-0.004-0.003-0.009-0.011-0.011
			c-0.007-0.003-0.015-0.009-0.015-0.013c0-0.004-0.002-0.007-0.004-0.007s-0.008-0.004-0.011-0.013
			c-0.004-0.006-0.009-0.016-0.012-0.021c-0.002-0.009-0.002-0.014,0-0.021c0.003-0.005,0.007-0.015,0.007-0.022
			c0-0.006,0.001-0.012,0.005-0.012c0.003,0,0.007-0.011,0.007-0.024c0-0.016,0.002-0.032,0.008-0.043
			c0.008-0.008,0.012-0.021,0.01-0.027c-0.002-0.005-0.002-0.01,0.004-0.013c0.003-0.001,0-0.007-0.01-0.015
			c-0.007-0.006-0.019-0.014-0.022-0.017c-0.011-0.009-0.075-0.014-0.084-0.008c-0.007,0.005-0.05,0.008-0.062,0.003
			c-0.002,0-0.006,0-0.012,0.004c-0.008,0.004-0.01,0.004-0.017,0.001c-0.004-0.004-0.011-0.004-0.015-0.002
			c-0.004,0.002,0-0.003,0.009-0.012c0.008-0.008,0.015-0.022,0.017-0.033c0.004-0.01,0.008-0.022,0.012-0.025
			c0.002-0.003,0.004-0.005,0-0.005c0,0,0.004-0.007,0.011-0.013c0.007-0.006,0.014-0.015,0.014-0.019
			c0-0.004,0.002-0.014,0.006-0.021c0.003-0.007,0.007-0.017,0.007-0.021c0-0.004,0.002-0.01,0.004-0.011
			c0.004-0.002,0.004-0.008,0.004-0.012c0-0.004,0.004-0.011,0.009-0.015c0.008-0.005,0.008-0.004,0.004,0.002
			c-0.004,0.011,0.006,0.01,0.015-0.002c0.004-0.007,0.004-0.01,0-0.01c-0.004,0-0.004-0.003,0-0.01
			c0.004-0.006,0.006-0.015,0.004-0.021c-0.004-0.01-0.006-0.011-0.035-0.008c-0.019,0.001-0.048,0.007-0.069,0.012
			c-0.026,0.007-0.036,0.012-0.041,0.022c-0.002,0.006-0.007,0.015-0.007,0.017c0,0.003-0.006,0.015-0.014,0.029
			c-0.009,0.025-0.033,0.042-0.065,0.046c-0.028,0.004-0.068,0.015-0.078,0.02c-0.01,0.005-0.012,0.005-0.024-0.005
			c-0.008-0.006-0.018-0.011-0.029-0.012c-0.011-0.002-0.019-0.013-0.028-0.04c0-0.008-0.009-0.017-0.011-0.021
			c-0.006-0.004-0.01-0.017-0.01-0.027c-0.002-0.01-0.004-0.023-0.008-0.03c-0.003-0.005-0.005-0.015-0.003-0.02
			c0.009-0.046,0.028-0.099,0.044-0.12c0.008-0.01,0.014-0.022,0.014-0.042c0.002-0.042,0.016-0.055,0.082-0.097
			c0.08-0.047,0.107-0.057,0.121-0.046c0,0.001,0.016,0.003,0.031,0.001c0.019,0,0.026,0.002,0.026,0.006
			c0,0.002,0.003,0.005,0.007,0.005c0.023,0.001,0.041-0.001,0.043-0.005c0.004-0.006,0.022-0.01,0.018-0.005
			c0,0.002,0,0.008,0.002,0.012c0.004,0.007,0.006,0.007,0.015,0c0.011-0.008,0.011-0.008-0.001-0.015
			c-0.012-0.007-0.012-0.008-0.002-0.016c0.005-0.004,0.011-0.011,0.013-0.014c0.001-0.009,0.113-0.019,0.13-0.012
			c0.006,0.005,0.012,0.01,0.012,0.013c0,0.012,0.018,0.011,0.033,0c0.017-0.013,0.029-0.013,0.038,0.001
			c0.005,0.005,0.009,0.014,0.015,0.018c0.014,0.011,0.014,0.021,0.002,0.039c-0.004,0.007-0.008,0.02-0.008,0.026
			c0.004,0.029,0.012,0.051,0.016,0.051c0.004,0,0.006,0.004,0.006,0.007s0.005,0.011,0.01,0.014
			c0.006,0.005,0.012,0.014,0.012,0.021c0,0.011,0.002,0.014,0.008,0.011c0.007-0.002,0.012-0.001,0.014,0.003
			c0.006,0.005,0.006,0.005,0.009-0.001c0.005-0.005,0.009-0.011,0.009-0.013s0.002-0.01,0.006-0.015
			c0.016-0.021,0.021-0.048,0.014-0.078c-0.002-0.018-0.004-0.033-0.002-0.035c0.002-0.003-0.002-0.018-0.006-0.034
			c-0.016-0.06,0.013-0.115,0.08-0.15c0.008-0.005,0.024-0.019,0.037-0.031c0.014-0.014,0.026-0.022,0.035-0.022
			c0.008-0.001,0.018-0.004,0.018-0.006c0.007-0.011,0.046-0.032,0.059-0.034c0.016-0.001,0.032-0.016,0.02-0.02
			c-0.006-0.003,0-0.007,0.015-0.015c0.023-0.011,0.023-0.012,0.017-0.002c-0.002,0.006-0.009,0.01-0.015,0.01
			c-0.005,0-0.005,0.003-0.002,0.004c0.004,0,0.011-0.001,0.017-0.005c0.012-0.005,0.014-0.01,0.014-0.039
			c0-0.018-0.004-0.034-0.008-0.038c-0.004-0.004-0.004-0.009,0.002-0.019c0.004-0.008,0.006-0.019,0.008-0.024
			c0.003-0.016,0.01-0.019,0.01-0.004c0,0.008-0.003,0.019-0.007,0.021c-0.005,0.003-0.005,0.011-0.005,0.017
			c0,0.009,0,0.008,0.012-0.006c0.008-0.009,0.011-0.018,0.009-0.02c-0.001-0.002,0-0.003,0.005-0.002
			c0.004,0.003,0.01-0.003,0.02-0.012c0.011-0.011,0.013-0.018,0.013-0.03c-0.004-0.009-0.002-0.017,0-0.019
			c0.004-0.002,0.005,0,0.005,0.003c0,0.013,0.004,0.011,0.028-0.01c0.011-0.012,0.026-0.028,0.03-0.037
			c0.006-0.016,0.009-0.019,0.035-0.025c0.037-0.011,0.069-0.026,0.053-0.025c-0.006,0,0-0.005,0.014-0.009
			c0.015-0.005,0.029-0.007,0.036-0.006c0.005,0.001,0.012-0.002,0.012-0.004c0.005-0.004,0.011-0.006,0.015-0.006
			c0.012-0.001,0.021-0.016,0.012-0.023c-0.004-0.003-0.006-0.002-0.006,0.003c0,0.012-0.008,0.012-0.016-0.001
			c-0.005-0.008-0.003-0.012,0.004-0.017c0.004-0.003,0.006-0.01,0.006-0.013c0-0.016,0.035-0.04,0.08-0.058
			c0.025-0.01,0.051-0.023,0.059-0.027c0.004-0.004,0.017-0.008,0.025-0.009c0.017-0.003,0.024-0.011,0.012-0.011
			s0.01-0.009,0.029-0.014c0.009-0.002,0.021-0.007,0.03-0.01c0.007-0.004,0.017-0.004,0.017-0.002
			c0.002,0.002-0.011,0.013-0.032,0.023c-0.03,0.018-0.035,0.023-0.037,0.035c0,0.009,0.004,0.019,0.005,0.021
			c0.009,0.007,0.025,0.008,0.025,0c0-0.003,0.006-0.006,0.011-0.006c0.005,0,0.011-0.002,0.011-0.004
			c0-0.008,0.041-0.035,0.049-0.033c0.002,0.002,0.006,0.001,0.01-0.001c0.014-0.008,0.092-0.038,0.096-0.036
			c0.001,0.001,0.005-0.002,0.009-0.009c0.005-0.006,0.011-0.011,0.019-0.011c0.011,0,0.037-0.019,0.037-0.026
			c0-0.004-0.004-0.006-0.008-0.006c-0.011,0-0.011-0.002-0.002-0.015c0.006-0.008,0.006-0.012,0-0.017
			c-0.005-0.003-0.015,0.003-0.039,0.029c-0.014,0.018-0.031,0.031-0.031,0.028c0-0.002-0.006-0.002-0.012,0.001
			c-0.014,0.006-0.059,0.001-0.053-0.005c0.001-0.006,0.027-0.007,0.027-0.002c0,0.002,0.004,0.004,0.012,0.004
			c0.005,0,0.011-0.003,0.011-0.006c0-0.002,0.005-0.009,0.015-0.014c0.013-0.009,0.011-0.009-0.015-0.006c-0.001,0,0,0-0.001,0
			c-0.002,0-0.004,0.001-0.008,0.001c-0.006,0.001-0.01-0.001-0.008-0.004s0.006,0,0.011,0.001c-0.024,0-0.039-0.002-0.035-0.012
			c0.003-0.007,0.003-0.009-0.004-0.006c-0.004,0.001-0.005,0.006-0.005,0.008c0,0.003-0.002,0.004-0.008,0.003
			c-0.012-0.004-0.012,0.005,0,0.015c0.011,0.01,0.011,0.011-0.002,0.01c-0.012-0.003-0.018-0.015-0.016-0.033
			c0-0.005,0.01-0.017,0.021-0.028c0.005-0.005,0.013-0.012,0.018-0.018c-0.005,0.001-0.009,0.003-0.014,0.004
			c-0.02,0.007-0.018,0,0.005-0.011c0.045-0.02,0.032-0.048-0.016-0.043c-0.033,0.005-0.103,0.032-0.137,0.053
			c-0.03,0.019-0.032,0.019-0.011-0.002c0.02-0.017,0.059-0.041,0.075-0.042c0.003,0,0.017-0.007,0.023-0.016
			c0.007-0.01,0.019-0.021,0.028-0.024c0.009-0.004,0.06-0.011,0.127-0.015c0.065-0.006,0.112-0.011,0.115-0.014
			c0-0.004,0.008-0.008,0.015-0.01c0.005-0.003,0.017-0.011,0.026-0.018c0.018-0.015,0.044-0.028,0.065-0.029
			c0.006,0,0.012,0,0.019-0.002c-0.007,0.009-0.016,0.018-0.024,0.025c-0.039,0.038-0.062,0.069-0.062,0.074
			c0,0.004-0.004,0.006-0.01,0.007c-0.006,0.001-0.01,0.005-0.01,0.008c-0.002,0.004-0.006,0.007-0.009,0.005
			c-0.004-0.003-0.021,0.011-0.021,0.017c0,0.002,0.004,0.002,0.011-0.001c0.008-0.002,0.004,0.003-0.011,0.012
			c-0.035,0.025-0.028,0.03,0.045,0.026c0.135-0.004,0.125-0.005,0.115,0.005s-0.01,0.02-0.002,0.02
			c0.004,0,0.006-0.003,0.004-0.004c-0.004-0.003-0.002-0.007,0.002-0.01c0.004-0.001,0.006-0.001,0.006,0.001
			c-0.004,0.017,0,0.021,0.012,0.018c0.021-0.005,0.055-0.055,0.036-0.055c-0.004,0.001-0.006,0.003-0.006,0.006
			c0,0.002-0.001,0.005-0.005,0.005c-0.002,0-0.004-0.004-0.004-0.008c0-0.003,0.002-0.006,0.004-0.004
			c0.002,0.001,0.005-0.002,0.011-0.006c0.005-0.008,0.005-0.008-0.002-0.008c-0.005,0-0.013,0.006-0.019,0.011
			c-0.006,0.007-0.013,0.012-0.017,0.012c-0.002,0,0.002-0.006,0.008-0.013c0.011-0.01,0.011-0.014,0.005-0.015
			c-0.004-0.001,0-0.002,0.01-0.002c0.011,0,0.017-0.003,0.018-0.01c0.002-0.006-0.001-0.007-0.009-0.004
			c-0.013,0.004-0.017-0.011-0.001-0.016c0.01-0.005,0.009-0.014-0.004-0.018c-0.008-0.003-0.012-0.005-0.008-0.005
			c0.004,0,0.008-0.003,0.004-0.007c-0.004-0.004-0.006-0.002-0.012,0.004c-0.003,0.006-0.007,0.007-0.007,0.004
			s-0.004-0.006-0.009-0.006c-0.004,0.001-0.007,0.002-0.007,0.004c0,0.003-0.009,0.005-0.017,0.005
			c-0.012,0.003-0.016,0.001-0.014-0.004s0.006-0.008,0.012-0.008c0.013-0.001,0.008-0.01-0.01-0.012
			c-0.007-0.001-0.013-0.002-0.018-0.002c0.005-0.006,0.011-0.012,0.018-0.017c0.011-0.011,0.019-0.014,0.021-0.012
			c0.006,0.008,0.011-0.003,0.007-0.013c-0.001-0.004-0.001-0.008,0.002-0.008c0.002-0.001,0.007-0.005,0.009-0.009
			c0.002-0.005,0-0.007-0.012-0.006c-0.01,0.001-0.012,0.002-0.008,0.005c0.006,0.003,0.002,0.004-0.01,0.004
			c-0.007,0-0.016,0.001-0.016,0.001c0-0.001,0.009-0.009,0.023-0.016c0.021-0.01,0.025-0.015,0.03-0.042
			c0.011-0.034,0.007-0.041-0.009-0.042c-0.018-0.003-0.03-0.021-0.016-0.029c0.011-0.008-0.007-0.021-0.025-0.018
			c-0.011,0.002-0.016,0.001-0.014-0.003c0-0.006-0.025-0.012-0.038-0.007c-0.005,0.001-0.007,0.001-0.003-0.002
			c0.011-0.008,0.007-0.018-0.006-0.029c-0.012-0.01-0.012-0.011-0.004-0.014c0.012-0.003,0.015-0.027,0.006-0.032
			c-0.002-0.002-0.002-0.009,0.004-0.015c0.007-0.013,0.007-0.014-0.002-0.018c-0.008-0.002-0.012-0.009-0.012-0.021
			c0-0.009-0.004-0.023-0.006-0.031c-0.007-0.008-0.009-0.023-0.009-0.036c0.002-0.012,0.002-0.022,0-0.021
			c-0.007,0-0.021,0.017-0.017,0.021c0.002,0.003,0,0.004-0.004,0.003c-0.004-0.001-0.02,0.011-0.039,0.029
			c-0.015,0.017-0.032,0.032-0.036,0.032c-0.005,0-0.014,0.006-0.026,0.013c-0.019,0.011-0.025,0.011-0.031,0.007
			c-0.019-0.012-0.029-0.022-0.029-0.031c0-0.01,0.014-0.047,0.021-0.051c0.004-0.003,0.006-0.007,0.002-0.009
			c0-0.002,0-0.004,0.004-0.004c0,0.002,0.004,0,0.004-0.002c0-0.004-0.008-0.005-0.019-0.005c-0.005,0-0.018-0.001-0.026-0.002
			c-0.011-0.001-0.013-0.003-0.007-0.01c0.007-0.007,0.007-0.009,0-0.012c-0.017-0.006-0.034-0.019-0.041-0.027
			c-0.006-0.005-0.013-0.007-0.017-0.007c-0.01,0.001-0.01,0.01,0,0.01c0.004,0,0.004,0.003-0.001,0.007
			c-0.011,0.006-0.019,0.006-0.082,0.003c-0.03-0.002-0.032-0.002-0.043,0.011c-0.007,0.007-0.015,0.018-0.015,0.024
			c-0.002,0.017-0.012,0.029-0.033,0.04c-0.021,0.011-0.025,0.016-0.004,0.009c0.015-0.003,0.015-0.003,0.011,0.008
			c-0.003,0.006-0.003,0.014-0.003,0.018c0,0.004-0.004,0.007-0.004,0.007c-0.004,0-0.016,0.01-0.028,0.021
			c-0.012,0.012-0.024,0.021-0.026,0.021c-0.006,0.001-0.021,0.016-0.021,0.02c0,0.002,0.002,0.003,0.006,0.003
			c0.009-0.001,0.032,0.016,0.032,0.027s-0.019,0.051-0.032,0.063c-0.018,0.019-0.082,0.053-0.11,0.061
			c-0.012,0.003-0.022,0.005-0.033,0.008c0.002,0.007,0.002,0.014,0.004,0.021c0,0.013,0,0.021-0.004,0.022
			c-0.002,0.001-0.004,0.007-0.004,0.013c0,0.007-0.004,0.014-0.006,0.015c-0.002,0.002-0.006,0.011-0.006,0.02
			c0,0.011-0.007,0.02-0.016,0.03c-0.012,0.007-0.016,0.014-0.014,0.016c0.002,0.004,0,0.006-0.002,0.006
			c-0.002,0-0.007-0.003-0.009-0.006c-0.001-0.004-0.007-0.002-0.016,0.008c-0.014,0.014-0.025,0.012-0.025-0.002
			c0-0.004-0.003-0.011-0.009-0.014c-0.005-0.003-0.012-0.01-0.012-0.013c0-0.004,0-0.011-0.004-0.015
			c-0.002-0.007,0-0.008,0.013-0.005c0.03,0.006,0.03,0.006,0.03-0.008c0-0.01-0.002-0.012-0.018-0.01
			c-0.014,0.003-0.016,0.002-0.014-0.006c0.002-0.006,0.005-0.012,0.007-0.014c0.004-0.001,0.005-0.008,0.005-0.014
			c0-0.006,0.002-0.012,0.004-0.012c0.008-0.001,0.025-0.033,0.018-0.034c-0.002-0.002-0.023-0.001-0.045,0
			c-0.025,0-0.045,0-0.051-0.005c-0.004-0.002-0.016-0.006-0.029-0.01c-0.011-0.002-0.023-0.006-0.023-0.01
			c0-0.008-0.014-0.021-0.031-0.024c-0.006,0-0.019-0.001-0.029-0.005c-0.014-0.003-0.021-0.003-0.036,0.003
			c-0.012,0.004-0.022,0.007-0.022,0.008c-0.006,0.001,0-0.025,0.01-0.046c0.007-0.02,0.003-0.023-0.02-0.02
			c-0.009,0-0.018,0-0.018-0.001c0-0.004,0.048-0.067,0.052-0.067c0.002,0,0.018-0.012,0.034-0.026
			c0.02-0.014,0.039-0.028,0.048-0.03c0.007-0.004,0.016-0.009,0.019-0.012c0-0.006,0.01-0.01,0.02-0.014
			c0.01-0.003,0.021-0.008,0.021-0.011c0-0.002,0.003-0.003,0.005-0.003c0.004,0.001,0.008-0.002,0.006-0.006
			c-0.002-0.005,0.002-0.006,0.028-0.002c0.007,0.002,0.007,0.002,0.003-0.002c-0.007-0.001-0.003-0.004,0.012-0.011
			c0.007-0.004,0.019-0.013,0.024-0.019c0.005-0.005,0.013-0.01,0.017-0.011c0.006,0,0.011-0.003,0.015-0.007
			c0.004-0.004,0.016-0.009,0.032-0.012c0.03-0.006,0.082-0.039,0.103-0.06c0.013-0.012,0.047-0.036,0.054-0.037
			c0.002,0-0.011,0.014-0.027,0.032c-0.017,0.018-0.03,0.035-0.03,0.038s-0.012,0.01-0.024,0.015c-0.031,0.012-0.031,0.022,0,0.017
			c0.019-0.005,0.019-0.005,0.017,0.006c-0.007,0.015,0.009,0.013,0.034-0.004c0.01-0.006,0.023-0.012,0.027-0.013
			c0.006-0.001,0.012-0.006,0.018-0.012c0.01-0.012,0.035-0.013,0.031-0.002c-0.003,0.011,0.036,0.01,0.059,0
			c0.015-0.007,0.018-0.011,0.013-0.017c-0.002-0.004-0.009-0.007-0.013-0.004c-0.003,0.001-0.008-0.002-0.012-0.009
			c-0.002-0.01-0.022-0.022-0.045-0.029c-0.008-0.001-0.01-0.005-0.008-0.016c0.004-0.009,0.006-0.016,0.008-0.018
			c0.002-0.004,0.011,0.01,0.008,0.015c-0.002,0.003,0,0.004,0.021,0.003c0.002,0,0.006-0.005,0.008-0.011
			c0-0.031,0-0.03,0.01-0.018c0.01,0.009,0.012,0.009,0.015,0c0.002-0.005,0.008-0.009,0.013-0.01c0.005,0,0.015-0.006,0.023-0.011
			c0.007-0.005,0.019-0.01,0.023-0.01c0.002-0.001,0.01-0.007,0.018-0.016c0.009-0.01,0.011-0.015,0.005-0.02
			c-0.007-0.006,0.008-0.02,0.03-0.032c0.007-0.005,0.018-0.014,0.021-0.021c0.007-0.01,0.007-0.012-0.003-0.012
			c-0.02,0.003-0.02-0.006,0.001-0.014c0.016-0.006,0.024-0.007,0.036-0.003c0.008,0.004,0.021,0.004,0.029,0
			c0.021-0.005,0.03,0.005,0.01,0.011c-0.016,0.007-0.032,0.022-0.018,0.02c0.002,0,0.002,0.005-0.006,0.011
			c-0.008,0.006-0.012,0.013-0.01,0.014c0.002,0.003,0.006,0.001,0.008-0.002s0.006-0.007,0.01-0.007
			c0.006-0.001,0.01-0.008,0.009-0.018c0-0.002,0.001-0.004,0.003-0.004c0.011-0.001,0.049-0.032,0.049-0.037
			c0-0.003-0.004-0.003-0.008,0c-0.021,0.009-0.03,0.01-0.03,0.006s0.006-0.007,0.015-0.008c0.01,0,0.017-0.005,0.019-0.014
			c0.004-0.005,0.006-0.007,0.006-0.004s0.003,0.005,0.009,0.005c0.004-0.001,0.008,0.003,0.004,0.008
			c-0.002,0.005-0.002,0.007,0.004,0.005c0.004-0.003,0.004-0.002,0.004-0.001c0,0.003,0.008,0.006,0.016,0.01
			c0.005,0.003,0.009,0.005,0.013,0.007c0.001-0.003,0.004-0.006,0.006-0.006c0.003,0,0.005,0.002,0.003,0.006
			c-0.001,0.002-0.007,0.006-0.009,0.005c-0.003,0-0.002-0.002-0.001-0.003c-0.004,0-0.008,0.001-0.012,0.001
			c-0.01,0-0.014-0.001-0.012-0.004c0.005-0.007-0.008-0.01-0.016-0.003c-0.008,0.006-0.008,0.009-0.004,0.014
			c0.004,0.003,0.004,0.009,0.004,0.012c-0.004,0.004,0,0.004,0.014,0.001c0.016-0.004,0.02-0.004,0.02,0.002
			c0,0.006-0.004,0.009-0.021,0.011c-0.035,0.003-0.074,0.032-0.063,0.047c0.007,0.011,0.076-0.013,0.076-0.027
			c0-0.003,0.004-0.01,0.008-0.013c0.007-0.005,0.007-0.005,0.007,0.002c0,0.003,0.008,0.003,0.01,0.004
			c-0.004-0.001-0.002-0.002,0.008-0.003c0.01-0.001,0.016-0.001,0.01,0.002c-0.006,0.001-0.014,0.001-0.018,0.001
			c0.004,0.001,0.002,0.004,0.01,0.004c0.016-0.001,0.021-0.003,0.021-0.009c0-0.004-0.005-0.007-0.013-0.007
			c-0.006,0-0.012-0.004-0.014-0.008c-0.002-0.006,0-0.006,0.006-0.002c0.008,0.005,0.01,0.005,0.012,0
			c0.002-0.006,0.004-0.006,0.004-0.001c0,0.003,0.005,0.005,0.01,0.005c0.008-0.002,0.01,0.002,0.01,0.01
			c-0.002,0.007-0.004,0.017-0.004,0.021c0,0.006-0.004,0.01-0.011,0.011c-0.004,0.001-0.011,0.005-0.016,0.009
			c-0.005,0.005-0.018,0.013-0.034,0.02c-0.034,0.015-0.039,0.02-0.03,0.027c0.009,0.008,0.007,0.013-0.004,0.014
			c-0.005,0.001-0.016,0.005-0.028,0.008c-0.012,0.004-0.029,0.006-0.047,0.005c-0.026-0.001-0.031,0.001-0.047,0.011
			c-0.029,0.017-0.039,0.03-0.031,0.037c0.006,0.01,0.031,0.009,0.055,0c0.013-0.006,0.029-0.01,0.039-0.01s0.025-0.002,0.031-0.002
			c0.006,0,0.012,0.002,0.01,0.004c-0.002,0.003,0,0.005,0.006,0.004c0.005-0.001,0.007,0.003,0.007,0.009
			c-0.002,0.005,0.004,0.014,0.009,0.02c0.008,0.007,0.014,0.015,0.014,0.021c0,0.011,0.007,0.013,0.022,0.004
			c0.01-0.005,0.014-0.004,0.028,0.003c0.005,0.006,0.016,0.009,0.023,0.008c0.008-0.001,0.025,0.002,0.041,0.006
			c0.011,0.004,0.027,0.006,0.027,0.004c0-0.002,0.003,0.002,0.009,0.007c0.003,0.002,0.003-0.003,0.005-0.003
			c-0.005,0-0.004-0.002,0.002-0.002c0.002-0.001,0.002,0,0.002,0c0.008-0.002,0.019,0.001,0.016-0.009
			c-0.002-0.009-0.02-0.011-0.02-0.001c0,0.002-0.002,0.003-0.005,0.003c-0.004-0.002-0.006-0.008-0.006-0.012
			c0-0.006-0.003-0.015-0.013-0.021c-0.008-0.005-0.012-0.011-0.012-0.013c0-0.004-0.006-0.007-0.012-0.007
			c-0.006,0-0.013-0.006-0.013-0.011c0-0.007,0.002-0.007,0.013,0c0.006,0.004,0.014,0.005,0.014,0.005
			c0.002-0.004,0.008-0.001,0.012,0.005c0.008,0.007,0.009,0.007,0.017,0.001c0.003-0.003,0.007-0.005,0.003-0.001
			c-0.002,0.004,0.004,0.007,0.012,0.009c0.008,0,0.015,0.006,0.015,0.011c0.002,0.01,0.014,0.013,0.022,0.002
			c0.002-0.004,0-0.008-0.004-0.01c-0.006-0.002-0.007-0.003,0-0.004c0.004-0.001,0.006-0.006,0.006-0.011
			c0-0.008,0.004-0.009,0.008-0.009c0.002,0.002,0.011-0.002,0.018-0.008c0.007-0.007,0.007-0.01,0-0.019
			c-0.006-0.008-0.007-0.014-0.002-0.021c0.003-0.012-0.002-0.018-0.018-0.017c-0.01,0-0.018-0.006-0.019-0.025
			c-0.002-0.005,0-0.008,0.005-0.009c0.01,0,0.01-0.007,0.002-0.015c-0.007-0.004-0.007-0.005,0-0.003
			c0.004,0.002,0.01,0.001,0.014-0.002c0.009-0.008,0.031,0.009,0.033,0.024c0.004,0.011,0.012,0.023,0.019,0.019
			c0-0.002,0.005-0.001,0.01,0.002c0.012,0.005,0.026-0.002,0.032-0.016c0.002-0.01,0.006-0.011,0.023-0.012
			c0.01,0.001,0.018-0.001,0.018-0.006c0-0.007,0.013-0.021,0.023-0.021c0.002-0.001,0.012-0.008,0.02-0.013
			c0.004-0.004,0.007-0.007,0.013-0.011c-0.006-0.002-0.011-0.006-0.017-0.009c-0.005-0.004-0.017-0.006-0.021-0.005
			c-0.003,0.003-0.005,0.001-0.003-0.002c0.003-0.004,0-0.006-0.004-0.006c-0.003,0.001-0.013-0.004-0.021-0.011
			c-0.005-0.007-0.015-0.01-0.022-0.007c-0.008,0.002-0.013,0.001-0.017-0.001c-0.001-0.004-0.009-0.009-0.021-0.011
			c-0.009-0.003-0.018-0.008-0.018-0.01c0-0.007,0.025-0.019,0.031-0.018c0.012,0.007,0.017-0.003,0.012-0.011
			c-0.004-0.004,0-0.003,0-0.005c-0.002,0-0.003,0.001-0.005,0.001c-0.01,0-0.019-0.001-0.019-0.002
			c0-0.002,0.009-0.004,0.019-0.004c0.003,0,0.003,0.001,0.005,0c0.007-0.004,0.004-0.012-0.001-0.019
			c-0.005,0.002-0.002,0.004-0.014,0.007c-0.042,0.013-0.05,0.012-0.016-0.001c0.013-0.006,0.021-0.011,0.022-0.013
			c-0.007-0.006-0.015-0.012-0.025-0.013c-0.012-0.002-0.022-0.001-0.026-0.001c0,0.002-0.004,0-0.004-0.003c0,0,0,0,0-0.001
			c-0.004,0.002-0.008,0.004-0.012,0.005c-0.01,0-0.01,0,0-0.005c0.012-0.005,0.008-0.001,0.011-0.001
			c-0.001-0.002-0.001-0.004-0.003-0.004c-0.003,0.002-0.007,0-0.009-0.006c-0.001-0.008-0.005-0.009-0.024-0.007
			c-0.013,0.002-0.025,0.008-0.029,0.01c-0.008,0.008-0.008,0.007-0.002-0.002c0.006-0.006,0.006-0.009,0.002-0.008
			c-0.004,0-0.006-0.001-0.004-0.005c0.002-0.002,0-0.004-0.006-0.003c-0.003,0-0.002-0.002-0.004-0.003
			c-0.002,0.001-0.003,0.002-0.004,0.002c-0.007,0-0.005-0.001,0.001-0.005c0-0.001-0.002-0.001-0.002-0.001
			c0-0.005-0.006-0.008-0.011-0.008c-0.006,0-0.017-0.002-0.023-0.002c-0.007,0-0.004-0.002,0.006-0.004
			c0.008-0.002,0.013-0.006,0.013-0.008c0-0.015-0.019-0.02-0.062-0.018c-0.033,0.001-0.041,0.004-0.05,0.011
			C43.6,24.288,43.6,24.288,43.6,24.28c0-0.01-0.025-0.008-0.072,0.009c-0.041,0.013-0.05,0.015-0.033,0.005
			c0.004-0.002,0.008-0.005,0.014-0.007c-0.006-0.001-0.01-0.002-0.014-0.003c-0.035-0.008-0.138,0.035-0.181,0.079
			c-0.005,0.005-0.014,0.01-0.015,0.01c-0.001,0-0.024,0.028-0.021,0.029c0,0.001,0.013,0.002,0.031,0.002
			c0.008,0.001,0.019,0.001,0.029,0.001c-0.01,0.002-0.021,0.003-0.031,0.003c-0.036,0.004-0.041,0.01-0.023,0.025
			c0.007,0.005,0.015,0.006,0.021,0.004c0.007-0.004,0.011-0.003,0.007,0c-0.003,0.007,0,0.007,0.032,0.002
			c0.013-0.001,0.018-0.001,0.013,0.002c-0.012,0.006-0.021,0.017-0.021,0.031c-0.002,0.006-0.008,0.011-0.012,0.011
			c-0.004,0.001-0.014,0.009-0.025,0.019c-0.02,0.016-0.029,0.021-0.023,0.011c0.002-0.002,0-0.004-0.004-0.003
			c-0.005,0-0.007,0.008-0.014,0.014c0.006-0.005,0.011-0.007,0.007,0.001c-0.002,0.002-0.007,0.006-0.009,0.007
			c-0.004,0-0.002-0.004,0-0.006c-0.002,0.004-0.01,0.008-0.008,0.009c0.002,0.002-0.004,0.007-0.015,0.012
			c-0.012,0.007-0.017,0.007-0.021,0.003c-0.008-0.009-0.002-0.02,0.013-0.029c0.032-0.018,0.018-0.049-0.011-0.035
			c-0.012,0.004-0.009,0-0.009-0.002c0,0.002,0.001,0.002,0,0.006c-0.002,0.01-0.013,0.021-0.016,0.018
			c-0.003-0.002,0.008-0.018,0.013-0.024c0-0.001-0.003,0.001-0.003-0.001c0.002-0.009,0-0.01-0.009-0.009
			c-0.006,0.001-0.003-0.007-0.003-0.012c-0.001,0.002-0.003,0.003-0.006,0.004c-0.004,0.001-0.004-0.002-0.004-0.004
			c0-0.003,0-0.005,0.004-0.006c0.003-0.001,0.005,0.002,0.006,0.004c0-0.003-0.005-0.005-0.002-0.007
			c0.005-0.002,0.005-0.005,0-0.013c-0.004-0.008-0.006-0.012,0-0.018c0.007-0.01,0.001-0.027-0.01-0.026
			c-0.006,0,0-0.004,0.013-0.012c0.012-0.005,0.024-0.013,0.026-0.017c0.004-0.002,0.017-0.009,0.033-0.011
			c0.037-0.009,0.117-0.055,0.105-0.06c-0.004-0.001-0.017-0.002-0.027,0c-0.014,0.001-0.031,0.003-0.043,0.003
			c-0.027,0-0.065,0.013-0.063,0.021c0.002,0.002-0.002,0.004-0.007,0.002c-0.006,0-0.014,0.004-0.021,0.01
			c-0.005,0.007-0.016,0.013-0.021,0.018c-0.008,0.001-0.01,0.007-0.008,0.011c0.004,0.013-0.013,0.028-0.037,0.041
			c-0.01,0.006-0.017,0.01-0.017,0.012s-0.006,0.004-0.013,0.006c-0.014,0.002-0.035,0.019-0.035,0.028
			c0,0.003-0.006,0.01-0.016,0.015c-0.008,0.005-0.012,0.013-0.012,0.018c0.002,0.008-0.002,0.012-0.013,0.014
			c-0.008,0.003-0.02,0.002-0.024-0.002c-0.007-0.005-0.012-0.004-0.025,0.002c-0.008,0.004-0.018,0.012-0.02,0.016
			c-0.002,0.003-0.013,0.01-0.024,0.012c-0.025,0.008-0.03,0.02-0.009,0.019c0.004,0,0.012,0.003,0.012,0.007
			c0.004,0.004-0.001,0.005-0.008,0.004c-0.007-0.001-0.013,0.002-0.021,0.012c-0.01,0.01-0.015,0.013-0.072,0.021
			c-0.048,0.008-0.061,0.009-0.063,0.004c-0.002-0.005-0.005-0.005-0.013-0.003c-0.014,0.006-0.028,0.001-0.012-0.007
			c0.008-0.005,0.008-0.005,0-0.006c-0.007-0.002-0.013,0-0.016,0.004c-0.004,0.006-0.004,0.005-0.007-0.004
			c0-0.006-0.005-0.01-0.009-0.012c-0.004-0.001-0.004,0.001-0.004,0.003c0.002,0.003-0.004,0.004-0.014,0.004
			c-0.005,0-0.013,0.001-0.016,0c0.003-0.002,0.009-0.006,0.014-0.009c0.012-0.006,0.018-0.006,0.02-0.005
			c0.009,0.005,0.018,0.004,0.014-0.001c-0.002-0.002,0-0.003,0.005-0.003c0.002,0.001,0.008,0.004,0.011,0.008
			c0.002,0.003,0.013,0.003,0.034,0c0.019-0.002,0.036-0.002,0.041,0c0.007,0.004,0.011,0.002,0.019-0.006
			c0.008-0.007,0.017-0.014,0.02-0.014c0.008-0.001,0.008-0.004,0.002-0.015c-0.005-0.006-0.004-0.008,0.006-0.009
			c0.006-0.002,0.01,0.001,0.01,0.004c0,0.004,0,0.006,0.002,0.006c0.008-0.001,0.037-0.026,0.037-0.031s-0.025-0.013-0.051-0.017
			c-0.013-0.002-0.013-0.01,0.002-0.018c0.006-0.004,0.01-0.011,0.01-0.014c0-0.017,0.025-0.043,0.051-0.059
			c0.015-0.009,0.029-0.018,0.029-0.019c0-0.005-0.014-0.007-0.033-0.005c-0.016,0.004-0.047,0.018-0.037,0.019
			c0.004,0.001,0.006,0.003,0.006,0.005c0,0.003-0.004,0.004-0.01,0.001c-0.022-0.006-0.043,0.001-0.061,0.021
			c-0.009,0.009-0.018,0.024-0.02,0.03c-0.004,0.007-0.008,0.012-0.012,0.012c-0.004,0.001-0.004-0.008,0.002-0.018
			c0.002-0.005,0-0.011-0.006-0.016c-0.011-0.01-0.033-0.008-0.033,0.003c0,0.008-0.006,0.01-0.035,0.004
			c-0.018-0.003-0.024-0.002-0.039,0.006c-0.014,0.005-0.016,0.005-0.012,0c0.01-0.01,0.008-0.014-0.002-0.013
			c-0.007,0.001-0.014-0.004-0.018-0.01c-0.01-0.012-0.022-0.014-0.059-0.01c-0.017,0.003-0.053,0.008-0.08,0.012
			c-0.026,0.003-0.056,0.008-0.063,0.011c-0.009,0.004-0.019,0.01-0.021,0.018c-0.004,0.006-0.01,0.013-0.014,0.013
			c-0.004,0.001-0.016,0.008-0.031,0.018c-0.012,0.009-0.026,0.017-0.03,0.017c-0.005,0.001-0.011,0.005-0.015,0.009
			c-0.004,0.004-0.019,0.013-0.033,0.021c-0.011,0.006-0.023,0.015-0.021,0.016c0.002,0.003,0.006,0.003,0.01,0.001
			c0.009-0.006,0.025,0.005,0.022,0.014c-0.006,0.012,0.007,0.012,0.032,0c0.016-0.006,0.035-0.013,0.046-0.016
			c0.013-0.002,0.026-0.009,0.034-0.014c0.018-0.015,0.078-0.043,0.08-0.039c0.004,0.002-0.002,0.007-0.012,0.009
			c-0.009,0.004-0.023,0.011-0.027,0.016c-0.01,0.008-0.01,0.008,0,0.013c0.009,0.004,0.009,0.005-0.008,0.013
			c-0.021,0.009-0.021,0.01-0.01,0.018c0.008,0.005,0.018,0.004,0.057-0.004c0.055-0.014,0.055-0.014,0.076-0.017
			c0.008,0,0.013,0,0.012,0.002c-0.001,0.002-0.03,0.008-0.062,0.014c-0.08,0.015-0.105,0.024-0.105,0.035
			c0,0.016,0.016,0.021,0.048,0.015c0.02-0.002,0.028-0.002,0.022,0.003c-0.004,0.003-0.008,0.01-0.008,0.014
			c0,0.007,0.008,0.006,0.047,0c0.026-0.007,0.058-0.015,0.072-0.02c0.018-0.005,0.038-0.012,0.052-0.015
			c0.006-0.003,0.016-0.004,0.023-0.006c-0.007,0.003-0.015,0.006-0.021,0.008c-0.044,0.014-0.055,0.022-0.051,0.042
			c0,0.003-0.004,0.005-0.02,0.005c-0.021,0-0.028-0.002-0.021-0.007c0-0.001,0.007-0.001,0.014,0
			c0.012,0.004,0.02-0.001,0.008-0.008c-0.004-0.004-0.01-0.004-0.021,0.002c-0.03,0.015-0.094,0.03-0.114,0.025
			c-0.009-0.001-0.01-0.001-0.007,0.003c0.001,0.003-0.002,0.005-0.021,0.007c-0.028,0.004-0.021-0.003,0.013-0.014
			c0.027-0.008,0.036-0.016,0.017-0.024c-0.008-0.004-0.02-0.005-0.041-0.001c-0.017,0.003-0.041,0.003-0.056,0.002
			c-0.013-0.002-0.028-0.003-0.032-0.003c-0.005,0.002-0.011,0-0.014-0.004c-0.009-0.007-0.052,0-0.073,0.014
			c-0.007,0.005-0.02,0.01-0.026,0.009c-0.012-0.001-0.017-0.009-0.006-0.011c0.002,0,0.006,0.001,0.006,0.004
			c0,0.002,0.002,0.004,0.006,0.003c0.002,0,0.004-0.002,0.002-0.004c-0.002-0.003,0-0.006,0.005-0.008
			c0.006-0.004,0.009-0.008,0.009-0.01c0-0.006-0.025,0.003-0.037,0.014c-0.007,0.006-0.035,0.022-0.041,0.023
			c-0.002,0.001,0-0.008,0-0.019c0.004-0.016,0.004-0.02-0.005-0.021c-0.004,0-0.011,0.001-0.013,0.004
			c-0.006,0.01-0.032,0.026-0.043,0.028c-0.008,0.001-0.01-0.001-0.008-0.007c0.004-0.006,0.002-0.006-0.004-0.002
			c-0.007,0.004-0.016,0.007-0.021,0.009c-0.036,0.011-0.099,0.036-0.109,0.042c-0.007,0.004-0.002,0.004,0.008,0
			c0.015-0.004,0.015-0.004,0.006,0.002c-0.005,0.004-0.011,0.006-0.022,0.003c-0.013-0.004-0.063,0.003-0.063,0.009
			c0,0.001-0.004,0.004-0.012,0.005c-0.005,0-0.013,0.006-0.018,0.011c-0.015,0.016-0.077,0.021-0.068,0.004
			c0-0.004-0.005-0.005-0.025-0.001c-0.036,0.004-0.057,0.005-0.057,0c0-0.003-0.018-0.001-0.039,0.003
			c-0.019,0.004-0.053,0.01-0.074,0.01c-0.016,0.001-0.053,0.006-0.078,0.009c-0.033,0.004-0.045,0.004-0.043,0
			c0-0.005-0.006-0.004-0.027,0.001s-0.029,0.007-0.032,0.003c-0.002-0.004-0.007-0.004-0.011-0.001
			c-0.005,0.001-0.009,0.001-0.014-0.003c-0.002-0.003-0.008-0.002-0.034,0.011c-0.016,0.01-0.044,0.02-0.065,0.024
			c-0.019,0.006-0.049,0.016-0.068,0.023c-0.016,0.009-0.036,0.017-0.045,0.018c-0.02,0.005-0.061,0.024-0.104,0.05
			c-0.016,0.011-0.048,0.024-0.075,0.032c-0.026,0.008-0.051,0.019-0.061,0.024c-0.005,0.005-0.019,0.011-0.024,0.011
			c-0.013,0.004-0.013,0.011,0.002,0.012c0.013,0,0.024,0.013,0.017,0.021c-0.01,0.01-0.008,0.013,0.004,0.011
			c0.009-0.002,0.011,0,0.007,0.005c-0.004,0.011-0.022,0.027-0.032,0.029c-0.012,0.002-0.04,0.001-0.031-0.001
			c0.006-0.001,0.012-0.006,0.017-0.009c0.007-0.011,0.002-0.01-0.046,0.01c-0.045,0.017-0.07,0.028-0.107,0.042
			c-0.033,0.012-0.044,0.019-0.029,0.021c0.006,0,0.006,0.002-0.004,0.015c-0.015,0.018-0.008,0.02,0.041,0.009
			c0.021-0.004,0.039-0.006,0.043-0.005c0.003,0.002,0.011-0.002,0.024-0.008c0.022-0.012,0.037-0.017,0.024-0.008
			c-0.006,0.005-0.006,0.006,0,0.005c0.01-0.002-0.019,0.025-0.033,0.031c-0.01,0.003-0.023,0.005-0.032,0.006
			c-0.011,0.002-0.021,0.003-0.021,0.005c0,0.007-0.031,0.02-0.052,0.022c-0.018,0-0.033,0.007-0.062,0.028
			c-0.006,0.006-0.014,0.011-0.021,0.011c-0.009,0.001-0.046,0.023-0.052,0.028c0,0.002-0.009,0.005-0.018,0.008
			c-0.014,0.007-0.026,0.028-0.021,0.033c0.002,0.002-0.002,0.007-0.009,0.01c-0.013,0.005-0.015,0.008-0.013,0.021
			c0,0.012,0,0.014,0.013,0.012c0.007-0.002,0.022-0.007,0.034-0.012c0.018-0.007,0.02-0.007,0.014,0.001
			c-0.003,0.005-0.012,0.01-0.014,0.013c-0.008,0.002-0.016,0.01-0.021,0.016c-0.004,0.007-0.013,0.013-0.019,0.014
			c-0.006,0.002-0.008,0.003-0.002,0.006c0.004,0.002,0.01,0.002,0.017-0.004c0.009-0.006,0.011-0.006,0.009-0.003
			c-0.003,0.008,0,0.006,0.018-0.005c0.02-0.01,0.046-0.013,0.041-0.004c-0.001,0.01,0.014,0.007,0.021-0.003
			c0.007-0.006,0.011-0.008,0.021-0.006c0.011,0.001,0.011,0.001-0.012,0.019c-0.012,0.01-0.035,0.024-0.048,0.031
			c-0.011,0.008-0.028,0.016-0.032,0.019c-0.009,0.003-0.032,0.014-0.053,0.023c-0.045,0.022-0.066,0.034-0.066,0.038
			c0,0.002-0.006,0.002-0.012,0.002c-0.01-0.002-0.027,0.004-0.088,0.034c-0.035,0.018-0.041,0.022-0.062,0.026
			c-0.026,0.007-0.065,0.031-0.059,0.035c0.004,0.001,0.008,0,0.016-0.003c0.006-0.004,0.017-0.01,0.027-0.011
			c0.006-0.001,0.02-0.007,0.029-0.013c0.007-0.004,0.021-0.009,0.025-0.008c0.007-0.001,0.018-0.005,0.021-0.008
			c0.009-0.005,0.013-0.006,0.017-0.007c0,0.002,0.004-0.001,0.008-0.003c0.005-0.009,0.027-0.011,0.031-0.002
			c0.004,0.008,0.01,0.008,0.017,0c0.006-0.004,0.009-0.004,0.015-0.003c0.008,0.004,0.01,0.002,0.016-0.005
			c0.004-0.009,0.004-0.01-0.002-0.009s-0.01,0-0.01-0.004c0-0.006,0.004-0.008,0.012-0.007c0.008,0,0.019-0.004,0.027-0.009
			s0.016-0.009,0.02-0.008c0.006,0.002,0.041-0.016,0.053-0.027c0.003-0.005,0.009-0.008,0.012-0.006c0,0,0.004-0.001,0.004-0.004
			c0-0.001,0.008-0.004,0.017-0.005c0.011,0,0.017,0,0.015-0.003s0.005-0.007,0.018-0.011c0.012-0.003,0.026-0.011,0.033-0.016
			c0.009-0.007,0.024-0.014,0.063-0.031c0-0.001,0.009-0.005,0.016-0.008c0.008-0.006,0.018-0.009,0.025-0.01
			c0.005-0.001,0.018-0.009,0.028-0.017c0.012-0.007,0.025-0.015,0.033-0.017c0.016-0.003,0.028-0.018,0.019-0.021
			c-0.009-0.002-0.007-0.003,0.001-0.004c0.008-0.002,0.015-0.003,0.019-0.003c0.003,0,0.007-0.005,0.009-0.008
			c0.002-0.004,0.01-0.008,0.019-0.01c0.007-0.001,0.013-0.004,0.013-0.007c0-0.002,0.004-0.005,0.01-0.005
			c0.004-0.002,0.014-0.005,0.02-0.009c0.014-0.012,0.029-0.018,0.029-0.014c0,0.001-0.008,0.007-0.015,0.011
			c-0.007,0.004-0.013,0.01-0.011,0.011c0.002,0.002-0.003,0.007-0.012,0.012c-0.01,0.005-0.02,0.01-0.02,0.012
			c0,0.006,0.03-0.003,0.066-0.02c0.019-0.01,0.035-0.017,0.035-0.017c0,0.003,0.004,0.001,0.007-0.002
			c0.01-0.007,0.041-0.014,0.054-0.013c0.006,0,0.017-0.004,0.034-0.013c0.021-0.012,0.031-0.023,0.019-0.02
			c-0.004,0-0.007,0.002-0.007,0.005c0,0.001-0.001,0.004-0.005,0.004c-0.002,0-0.004-0.003-0.004-0.007
			c0.002-0.006,0.008-0.007,0.014-0.008c0.008,0,0.012-0.004,0.012-0.006c0-0.003,0.006-0.006,0.014-0.007
			c0.016-0.004,0.016,0.007,0.002,0.013c-0.008,0.002-0.014,0.008-0.014,0.01c-0.002,0.005,0.002,0.004,0.017-0.002
			c0.012-0.005,0.02-0.007,0.032-0.006c0.015,0.001,0.015,0.001,0.006,0.006c-0.008,0.004-0.014,0.008-0.014,0.01
			s0.002,0.002,0.01,0c0.004-0.003,0.025-0.01,0.046-0.015c0.029-0.007,0.041-0.008,0.056-0.005c0.01,0.002,0.022,0.002,0.031-0.001
			c0.008-0.004,0.012-0.004,0.006,0c-0.006,0.005-0.006,0.007,0.008,0.012c0.006,0.004,0.015,0.012,0.017,0.018
			c0.002,0.006,0.005,0.009,0.009,0.009c0.007-0.002-0.002,0.032-0.014,0.045c-0.006,0.008-0.008,0.011-0.004,0.008
			c0.006-0.002,0.009,0,0.009,0.007c0,0.006,0,0.01-0.005,0.008c-0.002-0.001-0.004,0.002-0.004,0.005
			c0,0.011,0.011,0.009,0.02-0.005c0.005-0.006,0.01-0.01,0.012-0.009c0,0.001-0.005,0.008-0.014,0.016
			c-0.02,0.018-0.02,0.02-0.004,0.018c0.004,0,0.007,0,0.011-0.002c-0.004,0.002-0.007,0.003-0.011,0.006
			c-0.012,0.006-0.014,0.009-0.012,0.021c0.002,0.011,0.004,0.012,0.019,0.011c0.01-0.001,0.018-0.005,0.024-0.011
			c0.01-0.009,0.012-0.01,0.017-0.004c0.009,0.007,0.009,0.007,0.013-0.005c0.002-0.003,0.004-0.008,0.008-0.012
			c-0.002,0.004-0.004,0.009-0.004,0.012c-0.002,0.008,0,0.014,0.004,0.015c0.002,0.002,0.004,0.005,0.004,0.007
			c0,0.003,0,0.003-0.004,0c-0.004-0.005-0.008-0.004-0.01,0.004c-0.002,0.004-0.009,0.007-0.011,0.006
			c-0.005-0.004-0.023,0.012-0.017,0.016c0.002,0.003,0,0.007-0.002,0.008c-0.004,0.003,0.002,0.006,0.004,0.009
			c0-0.001,0-0.003,0-0.003c0.004,0,0.008,0.001,0.008,0.003s-0.002,0.004-0.004,0.005c0,0-0.002-0.001-0.004-0.002
			c0.003,0.002-0.002,0.003,0.003,0.006c0.007,0.004,0.009,0.007,0.005,0.007c-0.004,0.002-0.008,0.007-0.005,0.012
			c0.001,0.006,0.005,0.009,0.009,0.007c0.005-0.002,0.003,0.003-0.004,0.014c-0.008,0.013-0.01,0.019-0.005,0.02
			c0.003,0.001,0.003,0.003-0.003,0.006c-0.004,0.005-0.002,0.008,0.012,0.015c0.012,0.008,0.012,0.009,0.003,0.008
			c-0.015-0.002-0.022,0-0.037,0.005c-0.007,0.001-0.01,0.001-0.01,0.001c-0.001,0.001-0.005,0.001-0.004,0.004
			c0,0.007-0.002,0.012-0.007,0.013c-0.004,0,0,0.003,0.008,0.004c0.008,0.002,0.013,0.006,0.013,0.008
			c0.002,0.004,0.002,0.008,0.005,0.01c0,0.002,0.002,0.005,0.008,0.006c0.008,0,0.012,0.004,0.01,0.005
			c-0.002,0.003,0,0.005,0.005,0.005c0.004,0.001,0.008,0.004,0.006,0.005c-0.002,0.004,0,0.004,0.003,0.004
			c0.004,0,0.014,0.001,0.02,0.004c0.008,0.005,0.008,0.005-0.006,0.006c-0.015,0.002-0.017,0.004-0.033,0.04
			c-0.008,0.024-0.039,0.074-0.051,0.088c-0.004,0.003-0.013,0.015-0.02,0.023c-0.008,0.01-0.021,0.028-0.034,0.04
			c-0.028,0.029-0.037,0.044-0.048,0.07c-0.004,0.012-0.017,0.033-0.028,0.046c-0.021,0.019-0.021,0.025-0.017,0.031
			c0.006,0.005,0.004,0.011-0.004,0.024c-0.008,0.014-0.008,0.02-0.002,0.028c0.002,0.005,0.004,0.014,0.002,0.018
			c-0.002,0.005,0,0.009,0.002,0.011c0.004,0.001,0.004,0.009,0.002,0.018c-0.002,0.011,0,0.017,0.006,0.019
			c0.005,0.003,0.005,0.004,0,0.008c-0.008,0.003-0.008,0.008-0.002,0.029c0.005,0.014,0.009,0.031,0.009,0.039
			c0,0.014,0.002,0.014,0.021,0.014c0.013,0,0.02,0.001,0.02,0.006s0.009,0.007,0.023,0.005c0.002-0.002,0.002,0,0,0.005
			c0,0.005,0,0.006,0.005,0.005c0.013-0.001,0.024,0.013,0.024,0.033c0,0.018,0.004,0.071,0.006,0.071
			c0.002,0,0.002,0.011,0.004,0.022c0,0.018,0.002,0.025,0.013,0.029c0.004,0.005,0.013,0.013,0.021,0.02
			c0.01,0.013,0.01,0.015,0.004,0.025c-0.009,0.012-0.014,0.015-0.03,0.016c-0.007,0-0.011,0-0.011,0.003
			c0,0.007,0.045,0.041,0.047,0.037c0.009-0.005,0.043,0.023,0.043,0.032c0,0.004-0.004,0.012-0.008,0.019
			c-0.004,0.008-0.008,0.018-0.008,0.02c0,0.009,0.01,0.018,0.021,0.017c0.012-0.001,0.048,0.031,0.048,0.043
			c0,0.006,0.002,0.009,0.007,0.008c0.004-0.002,0.011-0.004,0.017-0.007c0.01-0.004,0.012-0.008,0.008-0.02
			c-0.002-0.009-0.004-0.019-0.004-0.024c0-0.004-0.002-0.006-0.006-0.006c0,0-0.004,0.002-0.002,0.005
			c0.002,0.003-0.002,0.001-0.007-0.004c-0.011-0.009-0.011-0.009-0.013-0.002c-0.002,0.008-0.004,0.007-0.004-0.002
			c0-0.006,0.002-0.014,0.004-0.014c0.009-0.006,0.007-0.013-0.002-0.012c-0.008,0.001-0.014-0.025-0.008-0.028
			c0.003-0.002,0.006-0.005,0.006-0.006c0-0.004-0.003-0.004-0.008-0.002c-0.004,0.002-0.004-0.003-0.004-0.008
			c0-0.01-0.02-0.046-0.027-0.048c-0.008-0.002-0.005-0.025,0.002-0.031c0.004-0.003,0.004-0.007,0.004-0.008
			c-0.004-0.002-0.008,0-0.011,0.003c-0.005,0.007-0.005,0.005-0.005-0.002c0-0.006-0.004-0.011-0.006-0.012
			c-0.002-0.002-0.004-0.006-0.004-0.008c0-0.003,0.004-0.001,0.006,0.003c0.011,0.008,0.011,0.008,0.006-0.005
			c-0.006-0.016-0.006-0.018-0.012-0.01c-0.004,0.003-0.008,0.002-0.017-0.008c-0.009-0.011-0.009-0.014-0.004-0.039
			c0.007-0.032,0.009-0.033,0.022-0.03c0.004,0.002,0.012,0.002,0.015,0.001c0.006-0.002,0.024,0.015,0.022,0.02
			c-0.002,0.004-0.002,0.017,0,0.03c0.004,0.017,0.004,0.028-0.002,0.036c-0.007,0.013-0.005,0.018,0.004,0.017
			c0.002-0.001,0.002-0.005,0-0.01c-0.002-0.007,0.002-0.005,0.008,0.005c0.006,0.008,0.013,0.017,0.017,0.02
			c0.005,0.002,0.007,0.007,0.007,0.009s0.006,0.004,0.012,0.005c0.006,0.002,0.008,0.004,0.006,0.013
			c-0.002,0.01,0,0.012,0.01,0.014c0.006,0,0.011,0.004,0.011,0.009s0.006,0.009,0.011,0.009c0.012,0.002,0.012,0.003,0.004,0.014
			c-0.006,0.006-0.009,0.014-0.009,0.017c0,0.006,0.018,0.02,0.026,0.02c0.004,0,0.009,0.006,0.013,0.012
			c0.003,0.006,0.013,0.015,0.021,0.021c0.007,0.005,0.016,0.017,0.022,0.026c0.005,0.007,0.017,0.021,0.021,0.028
			c0.014,0.012,0.016,0.021,0.016,0.059c0,0.028,0,0.031-0.009,0.032c-0.011,0.001-0.009,0.02,0.005,0.036
			c0.006,0.008,0.019,0.019,0.029,0.022c0.008,0.003,0.019,0.013,0.023,0.017c0.004,0.009,0.034,0.019,0.055,0.017
			c0.002,0,0.014,0.006,0.023,0.014c0.018,0.013,0.071,0.035,0.086,0.034c0.003,0.001,0.012,0.002,0.014,0.005
			c0.012,0.008,0.039,0.02,0.049,0.02c0.004,0,0.013,0.001,0.022,0.005c0.014,0.007,0.031,0.002,0.063-0.012
			c0.005-0.004,0.018-0.008,0.025-0.008c0.019-0.001,0.046,0.013,0.066,0.035c0.031,0.038,0.05,0.049,0.078,0.051
			c0.014,0.001,0.029,0.005,0.035,0.008c0.029,0.012,0.052,0.016,0.074,0.015c0.021-0.002,0.025-0.001,0.017,0.003
			c-0.007,0.004-0.005,0.008,0.013,0.024c0.049,0.048,0.057,0.064,0.046,0.08c-0.005,0.009,0.003,0.023,0.019,0.03
			c0.013,0.005,0.015,0.005,0.021-0.001c0.006-0.009,0.006-0.009,0.006,0c0,0.004,0.006,0.011,0.018,0.015
			c0.02,0.007,0.03,0.018,0.02,0.023c-0.007,0.003,0,0.016,0.008,0.016c0,0,0.01,0.005,0.019,0.01
			c0.011,0.008,0.011,0.009,0.011,0.001c0-0.005,0.003-0.007,0.012-0.005c0.002,0.001,0.012,0.003,0.016,0.003
			c0.013,0,0.02,0.013,0.011,0.021c-0.007,0.005-0.005,0.011,0.004,0.011c0.005,0,0.007-0.004,0.005-0.007
			c-0.005-0.011,0.009-0.004,0.021,0.01c0.005,0.011,0.007,0.01,0.025,0.003c0.021-0.009,0.023-0.014,0.012-0.028
			c-0.012-0.013-0.012-0.014,0.008-0.021c0.009-0.005,0.016-0.01,0.016-0.014c0-0.003,0.004-0.006,0.01-0.009
			c0.001,0.001,0.004,0.003,0.004,0.004c0.002,0.005,0.005,0.007,0.009,0.005c0.004-0.003,0.004,0,0,0.004
			c-0.009,0.009-0.004,0.029,0.007,0.038c0.004,0.004,0.012,0.012,0.016,0.018c0.007,0.016,0.009,0.1,0.002,0.103
			c-0.002,0.002-0.002,0.007,0,0.013c0.007,0.01,0,0.042-0.01,0.042c-0.004,0-0.006,0.002-0.006,0.006
			c0,0.004-0.009,0.01-0.018,0.013c-0.016,0.006-0.02,0.01-0.02,0.018c0,0.005-0.004,0.012-0.008,0.013
			c-0.008,0.002-0.008,0.007-0.006,0.014c0.004,0.011,0,0.014-0.021,0.021c-0.021,0.007-0.023,0.008-0.023,0.026
			c0,0.011-0.002,0.019-0.008,0.021c-0.003,0.001-0.01,0.008-0.01,0.014c0,0.007-0.002,0.015-0.01,0.017
			c-0.006,0.003-0.01,0.008-0.008,0.01c0.008,0.009,0.008,0.043,0,0.042c-0.003,0.001,0.004,0.006,0.014,0.014
			c0.008,0.008,0.018,0.018,0.018,0.019c0,0.003,0.002,0.006,0.004,0.006c0.003,0,0.007-0.003,0.007-0.006
			c0-0.001,0-0.003,0.003-0.001c0.004,0.004-0.005,0.018-0.01,0.018c-0.004-0.001-0.015,0.008-0.027,0.019
			c-0.02,0.019-0.02,0.022-0.018,0.04c0.002,0.01,0.008,0.023,0.011,0.027c0.009,0.009,0.011,0.019,0.005,0.019
			c-0.014,0-0.003,0.015,0.018,0.024c0.016,0.01,0.025,0.018,0.037,0.037c0.007,0.014,0.018,0.031,0.027,0.039
			c0.004,0.009,0.017,0.031,0.027,0.052c0.009,0.019,0.021,0.042,0.027,0.05c0.006,0.007,0.01,0.017,0.01,0.02
			c0,0.004,0.004,0.011,0.011,0.016c0.005,0.004,0.011,0.013,0.011,0.017c0,0.005,0.002,0.011,0.006,0.011
			c0.011,0.007,0.043,0.059,0.041,0.066c-0.006,0.011,0.006,0.031,0.031,0.055c0.025,0.024,0.096,0.069,0.127,0.08
			c0.01,0.005,0.018,0.01,0.018,0.011c0,0.002,0.01,0.008,0.019,0.014c0.011,0.006,0.021,0.012,0.021,0.015
			c0,0.005,0.015,0.019,0.018,0.019c0.012,0,0.027,0.024,0.034,0.045c0.015,0.053,0.027,0.17,0.017,0.168
			c0,0-0.006,0.006-0.006,0.012c0,0.009,0.004,0.014,0.004,0.014c0.008,0,0.016,0.202,0.008,0.218
			c-0.002,0.008-0.002,0.016,0.004,0.026c0.004,0.011,0.008,0.021,0.004,0.04c-0.004,0.019,0,0.03,0.008,0.049
			c0.006,0.013,0.011,0.028,0.011,0.034c0,0.005,0.004,0.009,0.006,0.009c0.003,0,0.007,0.01,0.005,0.021
			c0,0.011,0,0.026,0.002,0.031c0,0.011-0.004,0.068-0.009,0.108c-0.002,0.013-0.004,0.03-0.004,0.036
			c0,0.009-0.002,0.011-0.009,0.007c-0.006-0.003-0.008,0-0.004,0.011c0.002,0.007,0.008,0.017,0.011,0.021
			c0.004,0.004,0.008,0.013,0.008,0.016c0,0.002,0.007,0.015,0.015,0.03c0.009,0.015,0.013,0.026,0.011,0.036
			c-0.005,0.022,0.002,0.062,0.015,0.076c0.006,0.006,0.008,0.01,0.004,0.01c-0.004,0-0.006,0.004-0.002,0.015
			c0,0.008,0.004,0.022,0.006,0.031c0.004,0.029,0.022,0.042,0.033,0.021c0.003-0.006,0.003-0.011,0-0.017
			c-0.004-0.004-0.006-0.007-0.002-0.006c0.002,0,0-0.003-0.004-0.009c-0.004-0.004-0.004-0.008-0.002-0.006
			c0.002,0,0.002-0.004-0.002-0.008c-0.01-0.01-0.01-0.017,0.002-0.012c0.006,0.004,0.009,0.002,0.006-0.003
			c-0.002-0.004-0.002-0.006,0.002-0.005c0.003,0.003,0.006,0.008,0.006,0.012c0,0.003,0.002,0.007,0.004,0.007
			c0.004,0,0.004,0.002,0.004,0.005c-0.002,0,0,0.009,0.002,0.021c0.002,0.008,0.006,0.027,0.01,0.042
			c0,0.012,0.005,0.026,0.007,0.028c0.006,0.002,0.006,0.004-0.003,0.006c-0.006,0.002-0.012,0.003-0.016,0.004
			c0,0-0.002-0.006,0-0.012c0-0.013-0.004-0.022-0.018-0.022c-0.006-0.002-0.006,0.002-0.004,0.005
			c0.008,0.012,0.008,0.02-0.002,0.02c-0.003,0-0.01,0.002-0.012,0.008c-0.006,0.011-0.008,0.02,0,0.011
			c0.004-0.009,0.025,0.005,0.025,0.021c0,0.006-0.004,0.012-0.005,0.01c-0.004,0-0.006,0.005-0.004,0.016
			c0.002,0.009,0,0.017-0.006,0.021c-0.003,0.004-0.005,0.01-0.003,0.014c0.003,0.009,0.016,0.012,0.02,0.005
			c0-0.005,0.004-0.004,0.014,0.001c0.007,0.004,0.014,0.006,0.018,0.004c0.004,0,0.004,0.001,0.002,0.005
			c-0.002,0.005-0.002,0.009,0.002,0.009c0.009,0-0.002,0.026-0.015,0.03c-0.007,0.002-0.009,0.005-0.007,0.009
			c0,0.002,0.004,0.006,0.004,0.008c0,0.008,0.01,0.026,0.014,0.026c0.004,0,0.004,0.003,0.004,0.003c0,0.008,0.01,0.03,0.02,0.038
			c0.004,0.004,0.01,0.014,0.011,0.023c0.004,0.008,0.008,0.015,0.01,0.016c0.001,0.001,0.007,0.002,0.011,0.002
			s0.01,0.011,0.012,0.021c0,0.012,0.002,0.019,0.002,0.016c0-0.003,0.008,0.003,0.014,0.014c0.007,0.015,0.013,0.018,0.023,0.017
			c0.005-0.002,0.014,0.003,0.023,0.012c0.008,0.006,0.014,0.011,0.018,0.011c0,0,0.002,0.002,0.002,0.006
			c0,0.006-0.006,0.004-0.027-0.006c-0.007-0.005-0.006,0,0.007,0.012c0.013,0.014,0.019,0.017,0.021,0.013
			c0.008-0.006,0.019,0.002,0.014,0.011c-0.002,0.003-0.002,0.006,0.002,0.006c0.007,0,0.021,0.01,0.021,0.018
			c0,0.004,0.008,0.008,0.018,0.009c0.012,0.002,0.014-0.001,0.012-0.006c-0.001-0.005-0.005-0.006-0.009-0.006
			c0.006,0.001,0.011-0.002,0.013,0.004c0.004,0.006,0.006,0.013,0.006,0.014c-0.002,0.002,0.002,0.006,0.01,0.009
			c0.002,0.004,0.013,0.01,0.016,0.014c0.01,0.01,0.018,0.011,0.018,0.002c0-0.004,0-0.002,0.004,0.003
			c0.004,0.005,0.008,0.008,0.012,0.009c0.002,0.001,0.011,0.006,0.02,0.009c0.008,0.004,0.016,0.007,0.019,0.007
			c0.004-0.003,0.006,0,0.009,0.002c0.002,0.004,0.006,0.006,0.009,0.004c0.002,0,0.007,0,0.007,0.002
			c0,0.004,0.007,0.008,0.022,0.008c0.01,0,0.026,0.005,0.03,0.01c0.004,0.005,0.011,0.005,0.011,0.005
			c0.005-0.001,0.001-0.008-0.007-0.011c-0.006-0.008-0.004-0.008,0.021-0.004c0.026,0.006,0.03,0.006,0.026-0.002
			c0-0.004,0.002-0.008,0.008-0.006c0.003,0,0.009-0.002,0.009-0.006c0.004-0.005,0.008-0.007,0.01-0.007
			c-0.003-0.002-0.003-0.005-0.01-0.006c-0.027-0.003-0.084-0.038-0.115-0.071c-0.013-0.016-0.026-0.031-0.024-0.035
			c0-0.002-0.007-0.01-0.013-0.016c-0.019-0.012-0.045-0.052-0.045-0.066c0-0.008,0.004-0.011,0.013-0.013
			c0.017-0.001,0.023-0.012,0.015-0.032c-0.007-0.017-0.006-0.022,0.021-0.043c0.012-0.007,0.02-0.014,0.016-0.019
			c-0.002-0.003-0.004-0.011-0.005-0.015c-0.002-0.015-0.01-0.025-0.029-0.027c-0.016-0.002-0.05-0.023-0.057-0.036
			c-0.013-0.023,0.009-0.048,0.034-0.046c0.014,0.001,0.022-0.005,0.013-0.012c-0.004-0.004-0.004-0.004,0.001-0.006
			c0.006-0.004,0.006-0.009-0.001-0.026c-0.004-0.006-0.008-0.014-0.01-0.022c0.004-0.004,0.01-0.009,0.017-0.013
			c0.007-0.008,0.019-0.012,0.021-0.012c0.011,0,0.012-0.01,0.002-0.025c-0.002-0.009-0.007-0.011-0.027-0.01
			c-0.005,0-0.015,0-0.023,0c-0.002-0.007-0.008-0.016-0.012-0.026c-0.008-0.016-0.011-0.031-0.008-0.031
			c0.002,0,0.011,0.004,0.025,0.011c0.025,0.012,0.043,0.014,0.061,0.008c0.014-0.004,0.018-0.018,0.008-0.026
			c-0.005-0.002-0.005-0.011-0.003-0.021c0.005-0.014,0-0.02-0.007-0.029c-0.007-0.01-0.007-0.011,0.002-0.006
			c0.008,0.003,0.01,0.002,0.005-0.002c-0.001-0.004,0.003-0.005,0.021-0.003c0.057,0.005,0.131-0.013,0.131-0.034
			c0-0.004,0.002-0.01,0.004-0.013c0.019-0.019,0.016-0.062-0.004-0.062c-0.012-0.002-0.016-0.011-0.016-0.029
			c0-0.014-0.014-0.026-0.039-0.041c-0.01-0.006-0.02-0.016-0.021-0.021c0-0.01,0-0.01,0.009-0.001
			c0.005,0.006,0.017,0.012,0.026,0.012c0.011,0.002,0.019,0.004,0.019,0.006c0,0.006,0.026,0.014,0.061,0.018
			c0.045,0.006,0.064-0.004,0.068-0.032c0.002-0.008,0.006-0.016,0.008-0.015c0.003,0.001,0.019-0.026,0.022-0.045
			c0.001-0.01,0.011-0.022,0.021-0.031c0.016-0.009,0.02-0.017,0.018-0.023c-0.004-0.008-0.005-0.008-0.008,0.002
			c-0.003,0.01-0.019,0.018-0.021,0.013c-0.002-0.001,0-0.011,0.01-0.019c0.008-0.009,0.013-0.022,0.013-0.027
			c0-0.008,0.004-0.012,0.01-0.012c0.005,0,0.007,0.004,0.005,0.013c-0.002,0.008,0,0.013,0.004,0.016
			c0.006,0,0.009-0.007,0.011-0.016c-0.001-0.001-0.002,0.001-0.002-0.001c0-0.006,0.002-0.008,0.004-0.006c0,0.001,0,0.003,0,0.004
			c0-0.001,0.001,0,0.001-0.001c0.014-0.046,0.016-0.052,0.033-0.067c0.022-0.019,0.029-0.053,0.018-0.081
			c-0.008-0.019-0.01-0.077-0.003-0.074c0.005,0,0.048-0.039,0.048-0.046c0-0.001,0.009-0.005,0.016-0.006
			c0.01-0.002,0.018-0.005,0.018-0.008s0.007-0.003,0.02-0.001c0.012,0.003,0.018,0.003,0.018-0.001
			c0-0.002-0.004-0.005-0.006-0.005c-0.002,0-0.002-0.002-0.002-0.006c0.004-0.008,0.073-0.022,0.1-0.021
			c0.027,0,0.029-0.001,0.033-0.013c0.002-0.007,0.01-0.015,0.02-0.022c0.014-0.007,0.018-0.011,0.014-0.021
			c-0.002-0.01,0.008-0.034,0.037-0.09c0.004-0.008,0.006-0.022,0.004-0.039c-0.002-0.02,0-0.028,0.006-0.034
			c0.011-0.009,0.018-0.052,0.016-0.093c0-0.009-0.002-0.039-0.004-0.068c0-0.05,0.004-0.064,0.018-0.064
			c0.004,0,0.013-0.017,0.027-0.041c0.019-0.041,0.023-0.047,0.036-0.058c0.014-0.014,0.057-0.071,0.063-0.087
			c0.012-0.032,0.012-0.069,0-0.115C44.329,27.638,44.32,27.617,44.32,27.614z M43.458,25.286c0.005,0,0.011,0.002,0.013,0.004
			c0.004,0.006-0.004,0.007-0.013,0.002C43.45,25.289,43.45,25.289,43.458,25.286z M43.792,25.018c0,0-0.008,0.005-0.017,0.009
			c-0.017,0.007-0.017,0.006-0.009-0.002C43.776,25.018,43.791,25.013,43.792,25.018z M43.534,24.612
			c-0.005,0-0.007-0.003-0.005-0.007c0.003-0.009,0.012-0.008,0.012,0C43.541,24.607,43.538,24.611,43.534,24.612z M43.599,24.643
			c-0.002,0-0.006-0.002-0.006-0.004c0-0.004,0.004-0.005,0.01-0.006c0.006-0.001,0.006,0.001,0.006,0.004
			C43.607,24.64,43.604,24.645,43.599,24.643z M43.862,24.362c0,0.001-0.006,0.004-0.012,0.006c-0.006,0.002-0.01,0.001-0.008-0.001
			C43.844,24.362,43.86,24.359,43.862,24.362z M43.722,24.497c0,0.003,0.006,0.006,0.014,0.006c0.019,0.002,0.009,0.009-0.016,0.016
			c-0.021,0.006-0.024,0.005-0.021-0.003C43.705,24.5,43.723,24.487,43.722,24.497z M43.642,24.435
			c-0.006,0-0.004-0.002,0.002-0.002c0.007-0.002,0.009,0,0.007,0.001C43.65,24.435,43.645,24.437,43.642,24.435z M43.625,24.281
			c-0.004-0.002-0.002-0.003,0-0.004c0.004,0,0.008,0.005,0.01,0.01c0.002,0.008,0.003,0.009,0.024,0.004s0.021-0.005,0.011,0.002
			c-0.008,0.002-0.025,0.008-0.037,0.01c-0.024,0.005-0.024,0.004-0.015-0.007C43.623,24.291,43.625,24.284,43.625,24.281z
			 M43.523,24.406c0.009-0.002,0.013-0.001,0.009,0.002c-0.005,0-0.014,0.002-0.02,0.001C43.508,24.409,43.512,24.408,43.523,24.406
			z M43.439,24.426c-0.01,0.002-0.01,0-0.004-0.005c0.012-0.007,0.017-0.007,0.017-0.001C43.452,24.422,43.447,24.424,43.439,24.426
			z M43.446,24.317c0.001-0.002,0.012-0.008,0.021-0.015c0.009-0.007,0.024-0.013,0.028-0.013c0.004-0.001-0.001,0.003-0.009,0.008
			c-0.007,0.005-0.019,0.017-0.025,0.027c-0.003,0.006-0.007,0.013-0.011,0.018c-0.001-0.004-0.003-0.009-0.005-0.012
			C43.443,24.323,43.443,24.318,43.446,24.317z M43.44,24.336c0.004-0.001,0.001,0.001,0,0.002
			c-0.006,0.003-0.006,0.008-0.002,0.014c0.005,0.008-0.002,0.013-0.01,0.005C43.424,24.351,43.43,24.338,43.44,24.336z
			 M43.346,24.422c-0.006-0.001-0.002-0.002,0.004-0.003c0.004-0.001,0.007,0,0.004,0.002C43.352,24.422,43.35,24.422,43.346,24.422
			z M43.249,24.57c0.015-0.002,0.017,0,0.017,0.014c0,0.01-0.002,0.013-0.004,0.01c0-0.002-0.004-0.005-0.013-0.003
			c-0.007,0-0.009-0.001-0.007-0.002c0.002-0.003,0-0.007-0.004-0.011C43.235,24.575,43.237,24.573,43.249,24.57z M43.139,24.619
			c0.011,0.006,0.011,0.006-0.002,0.006c-0.008,0-0.016-0.003-0.018-0.006C43.117,24.612,43.125,24.611,43.139,24.619z
			 M42.991,24.562c0.005-0.005,0.009-0.01,0.005-0.013c-0.002-0.003-0.002-0.005,0-0.003c0.005,0.003,0.023-0.009,0.021-0.014
			c0-0.002,0.007-0.008,0.021-0.013c0.013-0.004,0.021-0.011,0.021-0.013c-0.002-0.003-0.006-0.003-0.01,0
			c-0.004,0.002-0.004,0-0.004-0.006c0.002-0.007,0.008-0.009,0.014-0.01c0.007,0,0.014-0.001,0.016-0.005
			c0.002-0.005,0.023-0.008,0.023-0.003c0,0.002-0.006,0.009-0.014,0.014c-0.016,0.012-0.018,0.021-0.002,0.016
			c0.008-0.003,0.01-0.003,0.008,0c-0.006,0.006-0.046,0.024-0.067,0.032c-0.009,0.003-0.017,0.011-0.021,0.018
			c-0.004,0.009-0.009,0.012-0.014,0.011C42.98,24.573,42.98,24.573,42.991,24.562z M42.977,24.585c0.008-0.001,0.012,0,0.008,0.001
			c-0.002,0.003-0.008,0.004-0.012,0.003C42.969,24.586,42.971,24.585,42.977,24.585z M42.948,24.559c0.003-0.001,0.007,0,0.007,0
			c0,0.002-0.004,0.005-0.007,0.007c-0.006,0.002-0.01,0.002-0.01-0.001S42.941,24.56,42.948,24.559z M42.689,25.482
			c-0.018,0.005-0.023,0.011-0.041,0.032c-0.023,0.026-0.03,0.043-0.03,0.063c0,0.008-0.007,0.02-0.022,0.035
			c-0.026,0.025-0.039,0.03-0.041,0.014c-0.002-0.028,0.02-0.069,0.065-0.119c0.023-0.026,0.028-0.038,0.009-0.021
			c-0.004,0.005-0.004,0,0.006-0.008c0.006-0.008,0.016-0.014,0.021-0.013c0.006,0.001,0.015-0.002,0.021-0.006
			c0.013-0.011,0.056-0.021,0.056-0.015C42.733,25.448,42.699,25.481,42.689,25.482z M42.804,25.601
			c-0.051,0.022-0.072,0.028-0.086,0.024c-0.013-0.006-0.013-0.013,0-0.014c0.006-0.001,0.014,0.001,0.014,0.003
			c0,0.005,0,0.005,0.003,0.001c0.002-0.003,0.018-0.014,0.036-0.024c0.026-0.015,0.035-0.017,0.056-0.017
			c0.017,0,0.021,0,0.017-0.003c-0.006-0.004-0.004-0.005,0.01-0.009c0.008,0,0.021-0.003,0.025-0.003
			c0.004-0.001,0.008-0.001,0.008,0.001C42.887,25.565,42.844,25.585,42.804,25.601z M42.726,25.603
			c0-0.003,0.007-0.005,0.014-0.005c0.007-0.001,0.014,0,0.009,0.002c0,0.004-0.007,0.006-0.013,0.007
			C42.731,25.607,42.727,25.604,42.726,25.603z M42.889,25.765c-0.002-0.004-0.002-0.008,0-0.007c0.004,0,0.008,0.001,0.01,0.004
			C42.905,25.77,42.899,25.773,42.889,25.765z M42.894,25.523c0.013-0.007,0.034-0.011,0.055-0.014
			c0.019-0.002,0.036-0.004,0.036-0.006s0.005-0.004,0.011-0.004c0.006-0.001,0.012,0,0.012,0.003c0,0.015-0.033,0.029-0.068,0.029
			c-0.012-0.001-0.035,0.001-0.047,0.005C42.868,25.546,42.868,25.539,42.894,25.523z M42.829,25.429
			c0.002,0-0.005,0.003-0.021,0.004c-0.011,0.002-0.023,0.001-0.023,0C42.785,25.429,42.816,25.427,42.829,25.429z M42.836,25.443
			c-0.003,0.001-0.007,0.003-0.01,0.004c-0.002,0-0.004-0.001-0.004-0.004c0-0.002,0.004-0.004,0.007-0.005
			C42.833,25.437,42.838,25.44,42.836,25.443z M42.828,25.45c0.016,0.005,0.016,0.005,0.028-0.005
			c0.012-0.017,0.025-0.016,0.033,0.002c0.013,0.032-0.009,0.053-0.027,0.026c-0.014-0.018-0.024-0.018-0.02,0
			c0.002,0.01,0,0.015-0.016,0.027c-0.009,0.007-0.018,0.019-0.02,0.027c-0.004,0.011-0.021,0.03-0.03,0.031
			c-0.002,0-0.005-0.01-0.002-0.021c0.002-0.019,0-0.02-0.009-0.018c-0.006,0.002-0.017,0.007-0.021,0.011
			c-0.004,0.004-0.009,0.007-0.013,0.007c-0.001,0.001,0.007-0.01,0.02-0.023s0.023-0.027,0.023-0.028
			c-0.004-0.003-0.002-0.005,0.002-0.007c0.009-0.007,0.005-0.014-0.01-0.018c-0.008-0.003-0.012-0.008-0.012-0.012
			c0-0.004,0.004-0.006,0.025-0.005C42.792,25.445,42.811,25.448,42.828,25.45z M42.616,24.637c0,0.001-0.005,0.003-0.01,0.004
			c-0.008,0-0.012,0-0.01-0.004C42.6,24.632,42.615,24.633,42.616,24.637z M42.597,25.382c0.011-0.005,0.024-0.011,0.028-0.013
			c0-0.004,0.003-0.005,0.007-0.005c0.004,0,0.002,0.004-0.004,0.008c-0.005,0.004-0.007,0.008-0.003,0.008
			c0.011-0.002,0.033-0.015,0.033-0.019c0-0.002-0.003-0.003-0.009,0c-0.004,0.001-0.008,0-0.012-0.003
			c-0.002-0.003-0.002-0.003,0-0.002c0.004,0.001,0.019-0.004,0.035-0.01c0.031-0.013,0.047-0.017,0.061-0.013
			c0.005,0,0.005,0.005,0.005,0.013c-0.002,0.013,0.014,0.021,0.028,0.016c0.01-0.005,0.01-0.004,0.004,0.003
			c-0.004,0.005-0.006,0.009-0.004,0.011c0.002,0.001,0.002,0.006-0.002,0.011c-0.004,0.012-0.006,0.014-0.008,0.024
			c0,0.004-0.002,0.007-0.004,0.008c-0.005,0-0.007-0.004-0.005-0.008c0.004-0.005,0-0.005-0.021-0.001
			c-0.013,0.003-0.028,0.009-0.033,0.011c-0.014,0.007-0.036,0.004-0.039-0.004c0-0.003-0.008-0.005-0.012-0.005
			c-0.008,0.001-0.006-0.002,0.008-0.011c0.021-0.014,0.019-0.02-0.002-0.012c-0.008,0.003-0.012,0.007-0.012,0.01
			c0.004,0.003,0.004,0.003,0,0.002c-0.002-0.001-0.012,0.003-0.023,0.008c-0.01,0.005-0.023,0.013-0.032,0.015
			c-0.009,0.004-0.022,0.009-0.03,0.011c-0.015,0.004-0.015,0.004-0.004-0.004c0.01-0.008,0.01-0.008-0.011-0.004
			c-0.01,0.001-0.022,0.004-0.022,0.003C42.514,25.424,42.573,25.393,42.597,25.382z M42.435,25.284
			c-0.01,0.011-0.016,0.014-0.016,0.011c0-0.005,0.006-0.013,0.014-0.019c0.013-0.013,0.017-0.013,0.017-0.01
			C42.45,25.267,42.443,25.275,42.435,25.284z M42.429,25.2c0.002-0.002,0.008-0.004,0.012-0.007
			c-0.004,0.002-0.008,0.002-0.012,0.002c-0.018,0.002-0.016-0.002,0.002-0.017c0.016-0.014,0.031-0.02,0.037-0.012
			c0.004,0.007-0.009,0.077-0.016,0.078c-0.002,0.001-0.004-0.001-0.004-0.002c0-0.003-0.003-0.007-0.005-0.01
			c-0.004-0.005-0.007-0.005-0.013,0c-0.006,0.004-0.01,0.004-0.01,0c0-0.004,0-0.011-0.004-0.016
			C42.413,25.208,42.416,25.205,42.429,25.2z M42.267,26.137c-0.004,0.001-0.006-0.001-0.004-0.004
			c0.002-0.002,0.002-0.004,0.004-0.004c0.002,0,0.005,0.001,0.007,0.003C42.275,26.135,42.272,26.137,42.267,26.137z
			 M42.287,24.877c-0.013,0.01-0.035,0.021-0.052,0.026c-0.012,0.003-0.028,0.012-0.03,0.015c-0.007,0.007-0.089,0.027-0.1,0.022
			c-0.008-0.002-0.008-0.002,0-0.005c0.006,0,0.013-0.001,0.019-0.002c0.004,0,0.014-0.005,0.024-0.011
			c0.007-0.006,0.021-0.012,0.031-0.016c0.017-0.006,0.019-0.008,0.021-0.021c0.002-0.014,0.004-0.015,0.015-0.012
			c0.006,0.003,0.014,0.007,0.02,0.009c0.01,0.005,0.038-0.001,0.07-0.016C42.311,24.864,42.305,24.867,42.287,24.877z
			 M42.134,24.745c-0.008,0.001-0.014,0.002-0.019,0.003c0.005,0,0.011-0.001,0.019-0.001c0.006,0,0.016,0,0.016,0.003
			c0,0.002,0,0.004,0,0.004c-0.003,0-0.015-0.001-0.031-0.002c-0.009,0-0.019,0-0.029,0c0.016-0.004,0.027-0.009,0.041-0.014
			c0.023-0.006,0.059-0.018,0.078-0.024c0.041-0.015,0.049-0.017,0.034-0.009c-0.005,0.002-0.016,0.009-0.028,0.015
			c-0.018,0.008-0.018,0.009-0.004,0.011c0.006,0.001,0.023-0.001,0.039-0.006c0.005-0.001,0.015-0.004,0.025-0.007
			c-0.008,0.005-0.014,0.009-0.018,0.013c-0.01,0.006-0.025,0.014-0.037,0.016c-0.025,0.004-0.045,0.013-0.041,0.017
			c0.001,0.003-0.01,0.008-0.021,0.007c-0.001,0.001-0.011,0.006-0.022,0.012c-0.009,0.005-0.019,0.008-0.023,0.007
			c-0.003-0.003,0.037-0.023,0.047-0.022c0.003,0.001,0.01-0.001,0.01-0.003c0.005-0.009-0.039,0.007-0.059,0.021
			c-0.009,0.01-0.017,0.012-0.025,0.009c-0.01-0.002-0.01-0.003-0.002-0.004c0.006,0,0.008-0.003,0.008-0.006
			c0-0.004,0.006-0.006,0.012-0.007c0.006-0.001,0.015-0.006,0.019-0.01c0.003-0.005,0.013-0.009,0.019-0.009
			c0.005,0,0.014-0.003,0.02-0.004C42.182,24.749,42.16,24.739,42.134,24.745z M42.067,24.633c0.006-0.001,0.007-0.001,0.007,0.001
			c-0.003,0.001-0.007,0.002-0.01,0.002C42.059,24.637,42.061,24.635,42.067,24.633z M42.02,24.65
			c0.014-0.007,0.024-0.009,0.014-0.001c-0.005,0.002-0.012,0.006-0.016,0.007C42.012,24.656,42.012,24.655,42.02,24.65z
			 M41.923,24.68c0.012-0.003,0.01-0.002-0.007,0.007c-0.025,0.012-0.037,0.015-0.021,0.003
			C41.904,24.685,41.914,24.68,41.923,24.68z M40.676,25.312c-0.002,0.001-0.006,0-0.006-0.001c0-0.003,0-0.005,0.002-0.005
			c0.004-0.001,0.006,0,0.006,0.002C40.678,25.312,40.676,25.314,40.676,25.312z M40.788,25.232c-0.005,0-0.001-0.001,0.007-0.003
			c0.01-0.002,0.014-0.002,0.01,0C40.803,25.231,40.795,25.232,40.788,25.232z M40.825,25.226c-0.005,0.003-0.009,0.003-0.007,0
			c0.002-0.005,0.018-0.009,0.018-0.005C40.836,25.221,40.83,25.224,40.825,25.226z M40.877,25.047
			c-0.004,0.001-0.004-0.001,0.002-0.005c0.012-0.005,0.012-0.006,0.008,0C40.885,25.042,40.879,25.047,40.877,25.047z
			 M40.977,25.153c-0.005,0.007-0.023,0.019-0.023,0.015c0-0.004,0.011-0.012,0.025-0.02C40.979,25.148,40.979,25.151,40.977,25.153
			z M40.989,25.146c-0.006-0.001-0.004-0.002,0.001-0.004c0.008-0.001,0.01,0,0.008,0.001
			C40.998,25.145,40.992,25.146,40.989,25.146z M40.989,24.961c-0.006-0.001-0.004-0.001,0.001-0.004c0.008,0,0.01,0,0.008,0.002
			C40.998,24.961,40.992,24.963,40.989,24.961z M41.033,25.135c-0.002,0-0.007,0-0.007-0.003c0-0.002,0.005-0.003,0.007-0.003
			c0.006,0,0.009,0.002,0.009,0.003C41.042,25.132,41.039,25.133,41.033,25.135z M41.346,25.292c0,0.001,0-0.004,0-0.01
			c0-0.01,0.002-0.014,0.006-0.012c0.008,0.001,0.008,0.004,0.002,0.011C41.35,25.286,41.346,25.292,41.346,25.292z M41.351,25.241
			c-0.01,0.006-0.01,0.006-0.004-0.001c0.008-0.009,0.012-0.012,0.012-0.008C41.359,25.232,41.355,25.237,41.351,25.241z
			 M41.357,25.193c-0.006,0.001-0.006-0.002-0.006-0.002c0.002-0.003,0-0.005-0.004-0.006c-0.004-0.002-0.002-0.003,0.002-0.006
			c0.01-0.007,0.012-0.007,0.008-0.001c-0.002,0.002-0.002,0.008,0.002,0.009C41.362,25.19,41.362,25.193,41.357,25.193z
			 M41.357,25.344c-0.001-0.001,0.001-0.001,0.001-0.002c0,0.001,0,0.002,0.001,0.003C41.358,25.344,41.358,25.344,41.357,25.344z
			 M41.385,25.164c0.002,0.002,0.002,0.003-0.002,0.004c-0.002,0.001-0.008-0.001-0.01-0.002c-0.002-0.003,0-0.004,0.002-0.005
			C41.379,25.161,41.383,25.161,41.385,25.164z M41.371,25.148c0.004,0.002,0.006,0.005,0.004,0.007
			c-0.002,0.002-0.005,0-0.005-0.003C41.366,25.146,41.366,25.146,41.371,25.148z M41.381,25.252c-0.002,0-0.004-0.003-0.008-0.006
			c0-0.004,0-0.006,0.004-0.007c0.004,0,0.004,0.002,0.004,0.006C41.381,25.25,41.381,25.252,41.381,25.252z M41.393,25.213
			c-0.004,0-0.008,0.002-0.01,0.006c-0.002,0.006-0.006,0.007-0.011,0.003c-0.002-0.001,0-0.004,0.005-0.005
			c0.004,0,0.004-0.002,0.002-0.004c-0.002-0.001,0.002-0.003,0.004-0.005c0.004-0.001,0.01,0,0.012,0.004
			C41.396,25.213,41.396,25.213,41.393,25.213z M41.401,25.193c-0.009,0.009-0.023,0.013-0.03,0.008
			c-0.002-0.003,0.002-0.005,0.009-0.004c0.004,0.001,0.012-0.001,0.012-0.004c0.004-0.003,0.008-0.007,0.011-0.007
			C41.407,25.187,41.407,25.189,41.401,25.193z M41.407,25.237c-0.004,0-0.004-0.001,0.002-0.003c0.007-0.001,0.011,0,0.007,0.002
			C41.416,25.236,41.411,25.237,41.407,25.237z M41.415,25.523c-0.011,0.002-0.013,0-0.005,0c0.005-0.003,0.012-0.005,0.016-0.008
			c0.006-0.005,0.016-0.003,0.012,0.003C41.434,25.52,41.428,25.523,41.415,25.523z M41.45,25.502
			c-0.002-0.004-0.008-0.004-0.009-0.001c-0.006,0.001-0.008,0.001-0.006-0.002c0.002-0.004-0.004-0.011-0.014-0.016
			c-0.014-0.01-0.018-0.023-0.007-0.025c0.005,0,0.007,0.002,0.007,0.006c0,0.006,0.004,0.009,0.018,0.01
			c0.014,0.002,0.014,0.004,0.011,0.009c-0.006,0.004-0.006,0.005,0,0.005c0.003-0.001,0.005,0.004,0.005,0.009
			C41.455,25.505,41.453,25.505,41.45,25.502z M42.32,26.903c-0.016-0.001-0.016-0.002-0.01-0.007
			c0.01-0.005,0.01-0.006,0.003-0.009c-0.009,0-0.011-0.006-0.011-0.011c0-0.008,0.002-0.008,0.012-0.002
			c0.008,0.003,0.015,0.012,0.019,0.018C42.34,26.903,42.338,26.905,42.32,26.903z M42.865,26.982c-0.002,0-0.008-0.008-0.012-0.019
			c-0.006-0.015-0.006-0.02,0.004-0.03c0.002-0.003,0.006-0.006,0.01-0.01c0.003,0.004,0.005,0.009,0.007,0.012
			c0.003,0.006,0.005,0.016,0.007,0.022C42.881,26.969,42.872,26.982,42.865,26.982z M42.977,28.047
			c0-0.005-0.004-0.008-0.008-0.009c-0.004-0.001-0.008-0.006-0.01-0.01c-0.005-0.015,0.005-0.013,0.021,0.002
			c0.008,0.008,0.009,0.015,0.008,0.019C42.982,28.055,42.98,28.055,42.977,28.047z M43.119,29.294
			c-0.002-0.007-0.003-0.013,0-0.013c0.002,0,0.006,0.005,0.008,0.012C43.133,29.307,43.127,29.307,43.119,29.294z M43.137,29.268
			c0.002,0,0.002,0.004,0.002,0.009c-0.002,0.003-0.006,0.003-0.01,0.002C43.123,29.276,43.129,29.267,43.137,29.268z
			 M43.142,29.315c-0.004,0.001-0.008,0-0.008-0.002c0-0.004,0.004-0.006,0.008-0.006c0.006,0.002,0.009,0.004,0.009,0.004
			S43.148,29.315,43.142,29.315z M43.3,29.674c0,0,0.006,0.004,0.012,0.002c0.001,0,0.001,0.001,0.001,0.002
			C43.307,29.677,43.3,29.669,43.3,29.674z M43.392,29.656c0,0.012,0.008,0.019,0.029,0.021c0.016,0,0.016,0,0.004,0.004
			c-0.006,0.002-0.01,0.008-0.01,0.011c0,0-0.002-0.004-0.005-0.007c-0.008-0.008-0.011-0.01-0.013-0.004
			c-0.002,0.004-0.006,0.002-0.01-0.008c-0.008-0.015-0.01-0.035-0.004-0.031C43.387,29.646,43.389,29.651,43.392,29.656z
			 M43.344,29.656c-0.008,0-0.008-0.001,0.002-0.007c0.011-0.005,0.014-0.005,0.008,0.004
			C43.352,29.656,43.348,29.656,43.344,29.656z M43.413,29.218c0.004,0.002,0.009,0.002,0.011,0.006
			c0.002,0.002-0.002,0.004-0.007,0.004c-0.007,0-0.011-0.002-0.011-0.006C43.406,29.219,43.408,29.218,43.413,29.218z
			 M44.002,28.414c0,0-0.005-0.003-0.005-0.006c-0.004-0.002-0.002-0.004,0-0.004c0.005,0,0.007,0.002,0.007,0.004
			C44.004,28.413,44.002,28.414,44.002,28.414z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.74,24.307c0,0.001,0.002,0.002,0.003,0.003c0.004-0.001,0.008-0.002,0.009-0.004
			C43.756,24.301,43.756,24.301,43.74,24.307z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="43.519,25.356 43.524,25.357 43.529,25.357 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M43.888,24.356c-0.006,0.003-0.01,0.002-0.006-0.002c0.002-0.003,0.004-0.006,0-0.006c-0.002,0-0.004,0.006-0.004,0.011
			c0.003,0.003,0.006,0.004,0.008,0.007c0.006-0.003,0.018-0.006,0.016-0.008C43.9,24.354,43.892,24.354,43.888,24.356z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M41.332,25.437c-0.005-0.001-0.004,0.005-0.005,0.007c0.002-0.002,0.004-0.005,0.009-0.006
			C41.346,25.435,41.346,25.435,41.332,25.437z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M46.762,25.768c-0.006-0.005-0.021-0.01-0.035-0.01c-0.016-0.002-0.028-0.004-0.03-0.006c0-0.003-0.002-0.004-0.005-0.002
			c-0.004,0.001-0.008,0-0.012-0.003c0-0.003-0.004-0.003-0.006-0.002c-0.004,0.002-0.004,0.007-0.004,0.012
			c0,0.007,0.006,0.011,0.021,0.014c0.011,0.001,0.023,0.005,0.025,0.009c0.005,0.001,0.014,0.001,0.033,0
			c0.019-0.004,0.017-0.007,0.016-0.012C46.765,25.768,46.767,25.771,46.762,25.768z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="46.765,25.768 46.765,25.768 46.77,25.764 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M47.076,25.754c-0.005,0.004-0.021,0.007-0.03,0.007c-0.009,0-0.02,0.002-0.02,0.005c0,0.002-0.006,0.006-0.014,0.005
			c-0.014,0-0.01,0.016,0.011,0.025c0.012,0.009,0.014,0.009,0.03-0.002c0.006-0.006,0.018-0.011,0.021-0.011
			c0.002,0,0.004-0.002,0.002-0.007s0.002-0.012,0.009-0.015c0.01-0.007,0.014-0.015,0.006-0.015
			C47.087,25.745,47.082,25.75,47.076,25.754z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M55.026,9.939H1.454C0.65,9.939,0,10.59,0,11.393v33.694c0,0.803,0.65,1.455,1.454,1.455h53.572
			c0.804,0,1.454-0.651,1.454-1.454V11.393C56.48,10.59,55.83,9.939,55.026,9.939z M46.103,11.666c0.434,0,0.838,0.118,1.188,0.32
			c0.351-0.202,0.755-0.32,1.187-0.32c1.312,0,2.375,1.062,2.375,2.374c0,1.311-1.063,2.374-2.375,2.374
			c-0.432,0-0.836-0.118-1.187-0.32c-0.349,0.202-0.753,0.32-1.188,0.32c-1.311,0-2.373-1.063-2.373-2.374
			C43.73,12.728,44.793,11.666,46.103,11.666z M25.365,13.572c0.277,0,0.472,0.117,0.583,0.347h0.008l0.22-1.045h0.569l-0.62,2.916
			h-0.556l0.058-0.272h-0.008c-0.181,0.22-0.408,0.329-0.683,0.329c-0.217,0-0.392-0.077-0.523-0.231
			c-0.131-0.154-0.196-0.362-0.196-0.622c0-0.373,0.11-0.703,0.331-0.991C24.768,13.716,25.041,13.572,25.365,13.572z
			 M23.052,13.625h0.545L23.518,14h0.008c0.19-0.287,0.41-0.431,0.66-0.431c0.048,0,0.092,0.003,0.131,0.008l-0.123,0.578
			c-0.038-0.005-0.09-0.008-0.156-0.008c-0.352,0-0.565,0.172-0.637,0.516l-0.24,1.125h-0.569L23.052,13.625z M21.755,14.072
			c-0.057-0.034-0.131-0.051-0.224-0.051c-0.258,0-0.417,0.103-0.477,0.308h-0.551c0.134-0.504,0.505-0.756,1.11-0.756
			c0.236,0,0.431,0.043,0.581,0.127c0.152,0.085,0.227,0.203,0.227,0.353c0,0.036-0.005,0.076-0.015,0.121l-0.26,1.242
			c-0.009,0.042-0.014,0.092-0.014,0.151c0,0.079,0.026,0.126,0.077,0.139l-0.018,0.085h-0.604
			c-0.013-0.069-0.015-0.154-0.006-0.256c-0.209,0.209-0.467,0.313-0.776,0.313c-0.182,0-0.324-0.046-0.426-0.137
			c-0.102-0.092-0.153-0.223-0.153-0.393c0-0.208,0.071-0.382,0.214-0.521c0.142-0.14,0.324-0.225,0.548-0.255l0.607-0.082
			c0.162-0.021,0.244-0.099,0.244-0.234C21.839,14.156,21.812,14.106,21.755,14.072z M18.011,13.392
			c0.321-0.397,0.733-0.595,1.237-0.595c0.328,0,0.584,0.081,0.771,0.243c0.186,0.162,0.28,0.362,0.28,0.6
			c0,0.072-0.003,0.135-0.009,0.188h-0.608c-0.004-0.336-0.184-0.504-0.54-0.504c-0.293,0-0.53,0.148-0.71,0.443
			c-0.18,0.296-0.27,0.611-0.27,0.945c0,0.211,0.049,0.368,0.145,0.472c0.096,0.103,0.232,0.155,0.407,0.155
			c0.382,0,0.635-0.184,0.76-0.55h0.608c-0.24,0.718-0.72,1.077-1.441,1.077c-0.349,0-0.622-0.092-0.818-0.276
			c-0.196-0.183-0.295-0.437-0.295-0.761C17.528,14.267,17.689,13.788,18.011,13.392z M15.063,14.034l0.087-0.407h0.295l0.123-0.583
			h0.568l-0.123,0.583h0.354l-0.087,0.407h-0.354l-0.258,1.212c-0.004,0.017-0.006,0.031-0.006,0.043
			c0,0.033,0.013,0.056,0.037,0.071c0.024,0.015,0.066,0.022,0.128,0.022c0.061,0,0.116-0.003,0.166-0.008l-0.089,0.422
			c-0.092,0.014-0.199,0.02-0.323,0.02c-0.178,0-0.309-0.026-0.394-0.08c-0.086-0.054-0.128-0.133-0.128-0.237
			c0-0.047,0.006-0.097,0.018-0.153l0.279-1.313L15.063,14.034z M14.42,12.844h0.568l-0.112,0.527h-0.569L14.42,12.844z
			 M14.823,13.625l-0.46,2.162h-0.569l0.46-2.162H14.823z M11.661,14.004c0.221-0.288,0.494-0.432,0.817-0.432
			c0.278,0,0.473,0.117,0.583,0.347h0.008l0.221-1.045h0.569l-0.621,2.916h-0.556l0.058-0.272h-0.008
			c-0.18,0.22-0.408,0.329-0.682,0.329c-0.218,0-0.393-0.077-0.524-0.231c-0.131-0.154-0.196-0.362-0.196-0.622
			C11.33,14.62,11.44,14.291,11.661,14.004z M10.302,13.573c0.259,0,0.464,0.077,0.612,0.229c0.149,0.153,0.223,0.352,0.223,0.598
			c0,0.143-0.022,0.297-0.069,0.464H9.563c-0.004,0.046-0.006,0.083-0.006,0.109c0,0.278,0.127,0.418,0.381,0.418
			c0.215,0,0.368-0.078,0.46-0.234h0.585c-0.213,0.46-0.591,0.69-1.135,0.69c-0.255,0-0.462-0.072-0.622-0.217
			c-0.159-0.145-0.238-0.346-0.238-0.604c0-0.388,0.124-0.727,0.372-1.018C9.609,13.716,9.924,13.572,10.302,13.573z M7.837,13.625
			h0.545L8.303,14h0.008c0.19-0.287,0.41-0.431,0.66-0.431c0.048,0,0.092,0.003,0.131,0.008L8.98,14.156
			c-0.039-0.005-0.091-0.008-0.157-0.008c-0.352,0-0.564,0.172-0.637,0.516l-0.24,1.125H7.378L7.837,13.625z M5.078,13.392
			c0.321-0.397,0.733-0.595,1.237-0.595c0.328,0,0.584,0.081,0.771,0.243c0.186,0.162,0.279,0.362,0.279,0.6
			c0,0.072-0.002,0.135-0.008,0.188H6.749c-0.004-0.336-0.185-0.504-0.54-0.504c-0.293,0-0.53,0.148-0.71,0.443
			c-0.18,0.296-0.27,0.611-0.27,0.945c0,0.211,0.048,0.368,0.145,0.472c0.096,0.103,0.232,0.155,0.406,0.155
			c0.383,0,0.636-0.184,0.76-0.55h0.608c-0.24,0.718-0.721,1.077-1.442,1.077c-0.348,0-0.621-0.092-0.817-0.276
			c-0.197-0.183-0.295-0.437-0.295-0.761C4.594,14.267,4.755,13.788,5.078,13.392z M3.74,17.241h49v0.5h-49V17.241z M21.742,34.429
			v2.814c0,0.197-0.16,0.357-0.358,0.357h-1.553c-0.198,0-0.358-0.16-0.358-0.357v-2.814c0-0.197,0.16-0.357,0.358-0.357h1.553
			C21.582,34.072,21.742,34.232,21.742,34.429z M5.032,37.244V34.43c0-0.197,0.16-0.357,0.358-0.357h1.553
			c0.198,0,0.358,0.159,0.358,0.357v2.814c0,0.197-0.16,0.357-0.358,0.357H5.39C5.192,37.601,5.032,37.442,5.032,37.244z
			 M7.656,43.354l-0.057-1.051c-0.018-0.336-0.039-0.74-0.039-1.035H7.552c-0.085,0.276-0.181,0.578-0.302,0.91L6.827,43.34H6.592
			l-0.391-1.141c-0.114-0.342-0.206-0.649-0.274-0.932H5.92c-0.007,0.299-0.025,0.698-0.046,1.06L5.81,43.353H5.515l0.167-2.396
			h0.395l0.409,1.159c0.099,0.295,0.178,0.559,0.242,0.807h0.007c0.064-0.243,0.15-0.505,0.256-0.807l0.427-1.159h0.395l0.149,2.397
			H7.656z M7.554,37.244V34.43c0-0.197,0.159-0.357,0.356-0.357h1.555c0.197,0,0.357,0.159,0.357,0.357v2.814
			c0,0.197-0.16,0.357-0.357,0.357H7.91C7.713,37.601,7.553,37.442,7.554,37.244z M9.673,43.354
			c-0.039-0.066-0.092-0.273-0.156-0.571c-0.071-0.331-0.202-0.455-0.483-0.466H8.742v1.037H8.433v-2.36
			c0.156-0.032,0.384-0.053,0.594-0.053c0.33,0,0.547,0.063,0.696,0.198c0.117,0.103,0.188,0.267,0.188,0.455
			c0,0.313-0.202,0.523-0.451,0.608v0.011c0.182,0.064,0.291,0.234,0.352,0.483c0.078,0.333,0.136,0.564,0.182,0.658H9.673z
			 M10.074,37.244V34.43c0-0.197,0.16-0.357,0.357-0.357h1.554c0.197,0,0.357,0.159,0.357,0.357v2.814
			c0,0.197-0.16,0.357-0.357,0.357h-1.554C10.234,37.601,10.074,37.442,10.074,37.244z M12.399,43.15
			c-0.164,0.153-0.433,0.224-0.825,0.224c-0.212,0-0.376-0.013-0.479-0.028v-2.354c0.134-0.032,0.351-0.053,0.565-0.053
			c0.308,0,0.507,0.053,0.653,0.174c0.125,0.092,0.203,0.235,0.203,0.423c0,0.234-0.156,0.437-0.405,0.526v0.011
			c0.227,0.053,0.494,0.243,0.494,0.598C12.605,42.875,12.523,43.034,12.399,43.15z M14.51,43.353l-0.256-0.754h-0.85l-0.249,0.754
			h-0.32l0.814-2.396h0.373l0.817,2.396H14.51z M14.864,37.242c0,0.197-0.16,0.357-0.357,0.357h-1.555
			c-0.196,0-0.357-0.16-0.357-0.357v-2.814c0-0.197,0.161-0.357,0.357-0.357h1.555c0.197,0,0.357,0.159,0.357,0.357V37.242z
			 M14.902,24.952v0.331v1.249v0.278v1.58h-0.615v-0.279h-2.711h-0.182v0.279h0.182h2.431v1.083h-2.612v0.281H9.652v-0.281H7.04
			V28.39h2.518h0.094v-0.279H9.558H6.759v0.279H6.232v-1.58v-0.278v-1.249v-0.331v-1.526H6.76v0.28h2.799h0.094v-0.28H9.559H7.04
			v-1.083h2.613v-0.28h1.743v0.28h2.612v1.083h-2.431h-0.182v0.28h0.182h2.711v1.246h0.335v-1.247h-0.335v-0.279h0.615
			L14.902,24.952z M16.985,43.354h-0.313l-0.761-1.217c-0.167-0.267-0.327-0.541-0.444-0.799l-0.011,0.002
			c0.018,0.302,0.021,0.59,0.021,0.988v1.023h-0.291v-2.396h0.341l0.765,1.212c0.174,0.281,0.317,0.53,0.427,0.779l0.011-0.003
			c-0.028-0.32-0.036-0.611-0.036-0.981v-1.006h0.292L16.985,43.354z M18.848,43.351l-0.786-1.176l-0.231,0.263v0.913h-0.309v-2.396
			h0.309v1.156h0.011c0.064-0.092,0.127-0.179,0.188-0.256l0.732-0.9h0.384l-0.867,1.017l0.936,1.379H18.848z M19.22,37.242
			c0,0.197-0.159,0.357-0.356,0.357h-1.555c-0.197,0-0.357-0.16-0.357-0.357v-2.814c0-0.197,0.16-0.357,0.357-0.357h1.555
			c0.197,0,0.356,0.159,0.356,0.357V37.242z M20.793,43.351h-1.351v-2.396h1.298v0.26h-0.985v0.758h0.929v0.256h-0.929v0.863h1.038
			V43.351z M22.432,43.354c-0.039-0.066-0.092-0.273-0.156-0.571c-0.071-0.331-0.202-0.455-0.483-0.466h-0.292v1.037h-0.309v-2.36
			c0.156-0.032,0.384-0.053,0.594-0.053c0.33,0,0.547,0.063,0.696,0.198c0.117,0.103,0.188,0.267,0.188,0.455
			c0,0.313-0.202,0.523-0.451,0.608v0.011c0.181,0.064,0.291,0.234,0.352,0.483c0.078,0.333,0.136,0.564,0.182,0.658H22.432z
			 M24.263,37.242c0,0.197-0.16,0.357-0.358,0.357h-1.554c-0.197,0-0.357-0.16-0.357-0.357v-2.814c0-0.197,0.16-0.357,0.357-0.357
			h1.554c0.198,0,0.358,0.159,0.358,0.357V37.242z M26.784,37.242c0,0.197-0.161,0.357-0.357,0.357h-1.555
			c-0.197,0-0.356-0.16-0.356-0.357v-2.814c0-0.197,0.159-0.357,0.356-0.357h1.555c0.196,0,0.357,0.159,0.357,0.357V37.242z
			 M31.426,37.242c0,0.197-0.16,0.357-0.355,0.357h-1.555c-0.197,0-0.358-0.16-0.358-0.357v-2.814c0-0.197,0.161-0.357,0.358-0.357
			h1.555c0.195,0,0.355,0.159,0.355,0.357V37.242z M33.947,37.242c0,0.197-0.16,0.357-0.357,0.357h-1.554
			c-0.198,0-0.358-0.16-0.358-0.357v-2.814c0-0.197,0.16-0.357,0.358-0.357h1.554c0.197,0,0.357,0.159,0.357,0.357V37.242z
			 M36.469,37.242c0,0.197-0.16,0.357-0.357,0.357h-1.555c-0.196,0-0.356-0.16-0.356-0.357v-2.814c0-0.197,0.16-0.357,0.356-0.357
			h1.555c0.197,0,0.357,0.159,0.357,0.357V37.242z M38.989,37.242c0,0.197-0.161,0.357-0.356,0.357h-1.556
			c-0.196,0-0.356-0.16-0.356-0.357v-2.814c0-0.197,0.16-0.357,0.356-0.357h1.556c0.195,0,0.356,0.159,0.356,0.357V37.242z
			 M38.717,27.476v-1.329c0-2.235,1.813-4.046,4.047-4.046h6.682c2.234,0,4.046,1.811,4.046,4.046v1.329
			c0,2.235-1.812,4.046-4.046,4.046h-6.682C40.529,31.523,38.719,29.711,38.717,27.476z M50.975,44.913
			c-0.396,0-0.774-0.079-1.123-0.22c-0.001,0-0.002,0.001-0.002,0.001c-0.001,0-0.001-0.001-0.002-0.001
			c-0.345,0.141-0.727,0.22-1.123,0.22c-0.397,0-0.778-0.079-1.125-0.221c-0.001,0-0.002,0-0.003,0.001
			c-0.347,0.141-0.727,0.22-1.122,0.22c-1.647,0-2.983-1.335-2.983-2.982c0-1.646,1.336-2.981,2.983-2.981
			c0.394,0,0.773,0.079,1.119,0.22c0.001,0,0.002-0.001,0.003-0.001c0.002,0.001,0.003,0.002,0.005,0.003
			c0.347-0.143,0.727-0.221,1.123-0.221s0.776,0.079,1.122,0.221c0.001,0,0.002-0.001,0.003-0.002
			c0.001,0.001,0.002,0.001,0.004,0.002c0.348-0.142,0.726-0.221,1.121-0.221c1.647,0,2.982,1.335,2.982,2.981
			C53.957,43.578,52.622,44.914,50.975,44.913z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M51.646,25.153c-0.008-0.004-0.01-0.007-0.01-0.008c0.002-0.002,0-0.004,0-0.004c-0.007-0.002-0.014-0.008-0.019-0.012
			c-0.011-0.012-0.011-0.012,0.003-0.009c0.007,0.001,0.016,0.001,0.018-0.003c0-0.004,0-0.004-0.006-0.004
			c-0.006,0-0.008-0.001-0.006-0.004c0-0.002-0.006-0.01-0.015-0.014c-0.023-0.018-0.114-0.055-0.153-0.063
			c-0.027-0.006-0.041-0.012-0.062-0.024c-0.03-0.019-0.266-0.119-0.344-0.146c-0.028-0.008-0.071-0.021-0.093-0.027
			c-0.068-0.017-0.13-0.034-0.141-0.04c-0.009-0.004-0.009-0.003,0.004,0.01c0.009,0.008,0.019,0.014,0.021,0.016
			c0.002,0,0.011,0.006,0.023,0.014c0.006,0.004,0.013,0.011,0.02,0.014c-0.002,0-0.009-0.001-0.014-0.002
			c-0.008-0.001-0.017-0.005-0.021-0.008c-0.004-0.003-0.018-0.009-0.031-0.014s-0.026-0.01-0.026-0.013
			c-0.002-0.001,0.002-0.001,0.007,0.001c0.006,0.003,0.012,0.003,0.012,0c0-0.002-0.002-0.004-0.004-0.005
			c-0.001,0-0.011-0.002-0.021-0.005c-0.007-0.001-0.012-0.003-0.02-0.005c0.008,0,0.013,0.001,0.02,0.001
			c0.006,0,0.012,0,0.02,0.001c-0.008-0.001-0.014-0.003-0.02-0.004c-0.031-0.009-0.035-0.01-0.035-0.004
			c0,0.005-0.004,0.005-0.025,0.001c-0.041-0.01-0.137-0.031-0.146-0.032c-0.017-0.001-0.062-0.019-0.062-0.026
			c0-0.008-0.024-0.022-0.067-0.041c-0.03-0.01-0.054-0.017-0.119-0.028c-0.068-0.012-0.086-0.015-0.103-0.024
			c-0.008-0.005-0.027-0.014-0.045-0.018c-0.035-0.011-0.049-0.017-0.045-0.021c0.008-0.012-0.095-0.044-0.113-0.036
			c-0.004,0.003-0.008,0.003-0.004-0.001c0.004-0.005-0.005-0.011-0.105-0.037c-0.108-0.028-0.106-0.028-0.087-0.017
			c0.004,0.003,0.007,0.007,0.013,0.009c-0.008,0-0.015-0.001-0.02-0.002c-0.018-0.004-0.02-0.003-0.018,0.004
			c0.002,0.004,0.01,0.009,0.018,0.01c0.007,0.001,0.012,0.003,0.011,0.004c-0.004,0.002,0.001,0.008,0.011,0.012
			c0.004,0.003,0.01,0.007,0.014,0.009c-0.004-0.002-0.01-0.003-0.016-0.006c-0.007-0.003-0.018-0.006-0.027-0.006
			c-0.004-0.001-0.023-0.004-0.041-0.008c-0.021-0.002-0.041-0.005-0.047-0.004c-0.008,0.002-0.021-0.002-0.035-0.008
			c-0.014-0.005-0.028-0.01-0.036-0.01c-0.005-0.002-0.011-0.004-0.007-0.007c0-0.002-0.002-0.003-0.006-0.004
			c-0.004-0.001-0.006,0.003-0.004,0.007c0.014,0.034,0.014,0.032-0.006,0.026c-0.027-0.01-0.068-0.035-0.089-0.056
			c-0.009-0.009-0.02-0.018-0.026-0.019c-0.004,0-0.006-0.005-0.006-0.007c0-0.014-0.096-0.055-0.105-0.044
			c0,0.001-0.006,0-0.008-0.001c-0.006-0.003-0.012-0.006-0.02-0.008c-0.009-0.002-0.025-0.008-0.037-0.013
			c-0.017-0.006-0.025-0.007-0.025-0.004c-0.002,0.003-0.004,0.003-0.006,0.001c-0.004-0.002-0.006,0-0.006,0.002
			s0.006,0.008,0.01,0.012c0.007,0.005,0.012,0.01,0.012,0.011c0,0.002-0.046-0.006-0.096-0.019
			c-0.018-0.004-0.034-0.007-0.039-0.009c-0.01-0.002-0.01-0.003-0.004-0.005c0.004-0.002,0.004-0.004,0.002-0.007
			c-0.008-0.005-0.137-0.025-0.144-0.023c-0.009,0.003-0.045-0.022-0.045-0.032c0-0.004-0.005-0.008-0.024-0.01
			c-0.018-0.003-0.022-0.002-0.025,0.006c0,0.005-0.006,0.006-0.014,0.004c-0.006-0.002-0.015-0.003-0.018-0.002
			c-0.008,0.001-0.008,0.003-0.004,0.009c0.005,0.008,0.004,0.01-0.013,0.011c-0.027,0.003-0.052-0.001-0.048-0.007
			c0.002-0.002,0.011-0.002,0.016-0.002c0.008,0.002,0.016,0,0.02-0.003c0.004-0.006,0.02-0.012,0.049-0.021
			c0.013-0.004,0.027-0.01,0.029-0.013c0.004-0.011-0.017-0.034-0.037-0.034c-0.004,0-0.009,0-0.004-0.001
			c0.004-0.001,0.004-0.003-0.005-0.01c-0.011-0.009-0.02-0.011-0.014-0.004c0.005,0.01-0.004,0.006-0.015-0.005
			c-0.01-0.008-0.021-0.013-0.076-0.021c-0.032-0.004-0.069-0.008-0.075-0.006c-0.011,0.001-0.013,0.001-0.011-0.004
			c0.004-0.01-0.006-0.026-0.014-0.027c-0.007-0.001-0.009,0.001-0.007,0.004c0.007,0.008-0.016,0.003-0.032-0.007
			c-0.012-0.01-0.084-0.026-0.102-0.022c-0.014,0.004-0.02,0.016-0.012,0.026c0.003,0.007,0.002,0.007-0.018,0.005
			c-0.012-0.002-0.025-0.002-0.027,0c0,0.002-0.01,0.005-0.021,0.004c-0.017,0.001-0.021,0-0.017-0.003
			c0.004-0.008-0.097-0.015-0.108-0.006c-0.003,0.002-0.022,0.005-0.042,0.004c-0.02-0.001-0.041,0.002-0.048,0.005
			c-0.006,0.003-0.018,0.004-0.024,0.002c-0.008-0.002-0.016-0.002-0.016,0c0,0.003-0.003,0.005-0.007,0.004
			c-0.005,0-0.007,0.004-0.009,0.008c0,0.006-0.002,0.006-0.002,0.002c-0.005-0.009-0.022-0.01-0.026,0c-0.002,0.004,0,0.01,0,0.012
			c0.004,0,0.005,0,0.007-0.003c0-0.005,0.006-0.004,0.016,0.003c0.005,0.004,0.014,0.009,0.021,0.009
			c0.004-0.001,0.009,0.002,0.012,0.005c0.004,0.009-0.039,0.005-0.045-0.003c-0.002-0.003-0.007-0.007-0.013-0.007
			c-0.007,0-0.007,0,0,0.004c0.007,0.007-0.007,0.009-0.06,0.005c-0.015-0.001-0.029-0.002-0.045-0.004
			c0.006,0.006,0.012,0.01,0.018,0.015c0.007,0.006,0.019,0.018,0.023,0.024c0.008,0.008,0.023,0.016,0.037,0.018
			c0.013,0.004,0.025,0.008,0.025,0.011c0,0.002,0.004,0.005,0.009,0.006c0.006,0.003,0,0.002-0.009,0.001
			c-0.01,0-0.025-0.005-0.032-0.009c-0.013-0.009-0.044-0.02-0.101-0.033c-0.01-0.003-0.016-0.002-0.021,0.002
			c-0.007,0.008-0.005,0.009,0.005,0.01c0.013,0.002,0.009,0.003-0.014,0.006c-0.014,0.001-0.014,0.002-0.004,0.009
			c0.004,0.004,0.017,0.012,0.027,0.016c0.016,0.008,0.016,0.008-0.008,0.003c-0.024-0.005-0.039-0.017-0.039-0.034
			c0-0.009-0.022-0.032-0.031-0.033c-0.004,0-0.012-0.004-0.018-0.008c-0.007-0.005-0.013-0.005-0.016-0.002
			c-0.006,0.005-0.002,0.008,0.011,0.013c0.008,0.004,0.018,0.005,0.022,0.004c0.002-0.002,0.002,0,0,0.002
			c0,0.003,0,0.008,0.002,0.01c0.011,0.006,0.008,0.013-0.01,0.014c-0.008,0.001-0.016,0.005-0.016,0.009
			c0,0.003-0.004,0.006-0.008,0.007c-0.003,0.001,0.006,0.011,0.025,0.021C48,24.323,48,24.325,47.998,24.341
			c-0.003,0.012-0.001,0.018,0.008,0.024c0.008,0.005,0.012,0.011,0.012,0.015c0,0.005,0.009,0.007,0.039,0.008
			c0.034,0.001,0.036,0.001,0.018,0.004c-0.028,0.004-0.032,0.014-0.012,0.031c0.014,0.011,0.016,0.016,0.01,0.02
			c-0.004,0.003-0.007,0.011-0.007,0.017c0,0.009-0.005,0.011-0.017,0.013c-0.01,0.002-0.015,0.006-0.015,0.01
			c0,0.006-0.004,0.007-0.019,0.002c-0.007-0.002-0.018-0.006-0.022-0.01c-0.004-0.004-0.004-0.005,0.004-0.001
			c0.005,0.003,0.011-0.002,0.019-0.017c0.004-0.011,0.011-0.02,0.012-0.02c0.004,0.001,0.004-0.007,0.004-0.016
			c0-0.015-0.002-0.019-0.023-0.028c-0.012-0.007-0.021-0.014-0.021-0.018c0-0.003-0.009-0.014-0.013-0.025
			c-0.008-0.01-0.014-0.023-0.016-0.028c-0.001-0.013-0.011-0.025-0.029-0.03c-0.01-0.005-0.013-0.007-0.008-0.01
			c0.008-0.008,0.006-0.025-0.002-0.037c-0.005-0.007-0.02-0.013-0.044-0.018c-0.023-0.005-0.031-0.007-0.019-0.007
			c0.019,0.001,0.019,0.001,0.009-0.007c-0.015-0.011-0.054-0.017-0.049-0.007c0.001,0.004-0.002,0.01-0.007,0.013
			c-0.004,0.004-0.006,0.015-0.006,0.026c0,0.018-0.002,0.023-0.018,0.028c-0.02,0.007-0.02,0.019-0.002,0.027
			c0.012,0.005,0.014,0.01,0.014,0.021c0,0.017,0.011,0.03,0.022,0.028c0.002-0.002,0.013,0.004,0.022,0.01
			c0.007,0.006,0.019,0.015,0.026,0.018c0.013,0.005,0.017,0.014,0.009,0.017c-0.002,0.002-0.022-0.005-0.043-0.016
			c-0.053-0.026-0.059-0.026-0.035,0.001c0,0.002,0.002,0.003,0.004,0.005c-0.004-0.003-0.006-0.005-0.008-0.008
			c-0.025-0.026-0.058-0.04-0.121-0.051c-0.057-0.009-0.059-0.01-0.091-0.026c-0.018-0.01-0.028-0.012-0.03-0.008
			c-0.006,0.004-0.004,0.009,0.004,0.017c0.008,0.007,0.014,0.011,0.017,0.01c0.006-0.003,0.049,0.019,0.058,0.03
			c0.012,0.011,0.01,0.013-0.006,0.012c-0.011-0.001-0.012,0.001-0.011,0.007c0.004,0.005,0,0.006-0.005,0.004
			c-0.006-0.003-0.01-0.005-0.01-0.007c0.002-0.009-0.006-0.027-0.017-0.029c-0.011-0.005-0.014,0.003-0.003,0.007
			c0.003,0.001-0.004,0.003-0.015,0.005c-0.012,0.001-0.022,0.003-0.026,0.005c-0.002,0.001-0.015,0.002-0.029,0.001
			c-0.014,0-0.028,0-0.033,0.004c-0.004,0.002-0.014,0.004-0.021,0.002c-0.015-0.002-0.021-0.018-0.005-0.021
			c0.005-0.001,0-0.002-0.011-0.004c-0.013,0-0.028,0.002-0.034,0.005s-0.019,0.007-0.027,0.007
			c-0.012-0.001-0.029,0.005-0.039,0.011c-0.012,0.006-0.026,0.01-0.033,0.01c-0.013-0.001-0.031,0.009-0.031,0.021
			c0,0.006-0.003,0.012-0.008,0.014c-0.016,0.006-0.041,0.002-0.047-0.006c-0.006-0.005-0.012-0.009-0.016-0.009
			c-0.012-0.001-0.004-0.014,0.016-0.016c0.007-0.003,0.014-0.004,0.021-0.005c-0.007-0.004-0.012-0.009-0.018-0.014
			c-0.017-0.013-0.028-0.017-0.057-0.021c-0.039-0.006-0.056-0.001-0.029,0.006c0.015,0.005,0.017,0.007,0.017,0.028
			c0,0.018,0.002,0.024,0.013,0.029c0.014,0.007,0.018,0.015,0.012,0.028c-0.004,0.005-0.008,0.005-0.02,0
			c-0.005-0.004-0.014-0.007-0.02-0.007c-0.008-0.001-0.012-0.005-0.012-0.009c0-0.006-0.005-0.004-0.018,0.006
			c-0.009,0.009-0.019,0.015-0.023,0.015c-0.005,0-0.016,0.005-0.023,0.011c-0.008,0.004-0.014,0.009-0.018,0.012
			c0.004,0.005,0.008,0.008,0.014,0.013c0.02,0.018,0.008,0.024-0.028,0.013c-0.017-0.004-0.035-0.012-0.042-0.016
			c-0.006-0.004-0.013-0.004-0.023,0c-0.013,0.007-0.011,0.021,0.002,0.021c0.004,0,0.008,0.002,0.008,0.006
			c0,0.003,0.007,0.006,0.015,0.006c0.011,0,0.019,0.004,0.021,0.008c0.002,0.015-0.029,0.011-0.057-0.005
			c-0.009-0.006-0.023-0.011-0.027-0.011c-0.004-0.001-0.01-0.005-0.01-0.008c0-0.004-0.004-0.012-0.011-0.017
			c-0.007-0.008-0.009-0.013-0.004-0.02c0.006-0.012-0.005-0.023-0.028-0.03c-0.009-0.001-0.016-0.007-0.018-0.011
			c0-0.005,0.009-0.004,0.043,0.004c0.11,0.027,0.141,0.027,0.172,0.006c0.02-0.012,0.022-0.023,0.006-0.04
			c-0.009-0.011-0.048-0.032-0.054-0.028c0,0.002-0.011-0.002-0.019-0.007c-0.009-0.005-0.018-0.009-0.02-0.009
			c-0.004,0-0.016-0.005-0.043-0.018c-0.013-0.006-0.046-0.015-0.072-0.02c-0.031-0.004-0.049-0.01-0.049-0.014
			c0-0.008-0.037-0.016-0.044-0.01c-0.004,0.001-0.016,0.001-0.03-0.002c-0.007-0.001-0.015-0.001-0.023-0.002
			c0.007-0.002,0.012-0.004,0.019-0.006c0.009-0.003,0.017-0.008,0.013-0.011c-0.004-0.006-0.063-0.024-0.07-0.021
			c-0.002,0.002-0.006,0-0.006-0.001c0-0.001-0.004-0.001-0.005-0.002c-0.005,0.002-0.013,0.005-0.014,0.002
			c-0.002-0.002,0.002-0.003,0.006-0.004c-0.003,0-0.003-0.002-0.006-0.002c-0.01-0.001-0.018,0.003-0.026,0.01
			c-0.004,0.008-0.006,0.01-0.008,0.004c0-0.002-0.005-0.005-0.008-0.008c-0.001,0.002-0.002,0.004-0.005,0.004
			c-0.002,0-0.004-0.002-0.002-0.005c0.001-0.001,0.002-0.001,0.003-0.002c-0.002-0.001-0.002-0.003-0.004-0.004
			c-0.015-0.008-0.019-0.008-0.023-0.001c-0.002,0.003-0.009,0.004-0.014,0.002c-0.004-0.002-0.014,0.002-0.02,0.008
			c-0.01,0.007-0.014,0.009-0.012,0.005c0.004-0.005-0.002-0.006-0.013-0.007c-0.022,0-0.042,0.005-0.041,0.01
			c0.004,0.007-0.02,0.012-0.037,0.01c-0.009,0-0.013,0.001-0.009,0.006c0.004,0.005,0.004,0.006-0.004,0
			c-0.012-0.012-0.049-0.008-0.049,0.006c0,0.008,0,0.008-0.02,0.009c-0.021,0.003-0.027,0.008-0.025,0.02
			c0.002,0.005,0,0.011-0.002,0.013c-0.004,0.003-0.006,0.002-0.002-0.003c0.004-0.005,0.002-0.005-0.01-0.002
			c-0.015,0.002-0.016,0.002-0.011-0.005c0.011-0.011,0-0.011-0.026,0.003c-0.018,0.008-0.023,0.013-0.021,0.019
			c0.002,0.006,0,0.007-0.002,0.004c-0.007-0.004-0.044,0.011-0.044,0.018c0,0.009,0.001,0.009,0.021-0.001
			c0.012-0.004,0.02-0.006,0.02-0.005c0,0.001,0.008,0.001,0.018-0.003c0.01-0.002,0.016-0.002,0.016,0.001
			s-0.004,0.004-0.006,0.004c-0.007,0-0.01,0.005-0.01,0.011c0,0.006-0.009,0.018-0.027,0.029c-0.012,0.011-0.025,0.021-0.025,0.024
			c0,0.004-0.006,0.007-0.012,0.007c-0.006,0.002-0.01,0.008-0.01,0.017c0,0.018-0.017,0.036-0.035,0.036
			c-0.014-0.001-0.014,0-0.006,0.004c0.006,0.007,0.006,0.008,0,0.009c-0.004,0.002-0.015,0.012-0.029,0.023
			c-0.015,0.019-0.02,0.02-0.031,0.014c-0.01-0.007-0.012-0.006-0.016,0.001c-0.004,0.005-0.012,0.007-0.016,0.006
			c-0.005-0.002-0.007,0-0.004,0.005c0.002,0.007-0.001,0.009-0.012,0.011c-0.01,0.003-0.018,0.007-0.018,0.009
			c0,0.004-0.005,0.006-0.012,0.008c-0.009,0.002-0.016,0.006-0.016,0.009c0,0.002-0.003,0.003-0.004,0.001
			c-0.001-0.002-0.011,0.001-0.021,0.007c-0.016,0.01-0.016,0.012-0.016,0.051c0,0.034,0,0.039,0.007,0.038
			c0.009-0.004,0.011,0.005,0.004,0.01c-0.007,0.004-0.007,0.036-0.002,0.036c0.004,0,0.006,0.005,0.006,0.011
			s0.009,0.015,0.021,0.024c0.026,0.018,0.058,0.018,0.08,0.001c0.012-0.007,0.028-0.018,0.041-0.024
			c0.011-0.007,0.022-0.016,0.022-0.021c0-0.006,0-0.007,0.004-0.001c0,0.005,0.005,0.011,0.009,0.013
			c0.006,0.003,0.01,0.012,0.01,0.019c0,0.007,0.004,0.02,0.009,0.028c0.007,0.007,0.011,0.015,0.011,0.017
			c0,0.003,0.009,0.014,0.02,0.025c0.012,0.011,0.018,0.02,0.014,0.022c-0.002,0.001-0.006,0.005-0.006,0.009
			c0,0.005-0.002,0.007-0.004,0.007c-0.008-0.002-0.039,0.008-0.039,0.011c0,0.006-0.029,0.014-0.033,0.009
			c-0.002-0.001,0-0.007,0.004-0.012c0.003-0.01,0.003-0.01,0.005-0.001c0,0.005,0.002,0.008,0.004,0.006
			c0.005-0.002,0.005-0.005,0.005-0.008c-0.003-0.003,0-0.005,0.002-0.008c0.01-0.005,0.006-0.018-0.007-0.018
			c-0.009,0-0.015-0.014-0.006-0.019c0.008-0.004,0.006-0.035,0-0.035c0,0-0.003,0.002-0.003,0.005s-0.004,0.005-0.007,0.005
			c-0.007,0-0.013,0.003-0.015,0.008c-0.003,0.005-0.014,0.008-0.023,0.008c-0.026,0-0.046,0.056-0.026,0.078
			c0.007,0.01,0.009,0.017,0.003,0.021c-0.003,0.001-0.005,0.006-0.003,0.011c0.003,0.006,0.005,0.007,0.011,0.002
			c0.006-0.006,0.006-0.005,0.006,0.002c0,0.004-0.016,0.007-0.02,0.007s-0.004,0.003,0.002,0.008
			c0.012,0.015-0.002,0.024-0.026,0.021c-0.012-0.003-0.024-0.002-0.032,0.003c-0.008,0.005-0.019,0.006-0.029,0.005
			c-0.026-0.004-0.049,0.011-0.052,0.033c-0.006,0.028-0.04,0.06-0.079,0.075c-0.023,0.006-0.027,0.009-0.031,0.027
			c-0.003,0.017-0.005,0.021-0.024,0.025c-0.012,0.003-0.024,0.012-0.028,0.017c-0.007,0.012-0.025,0.013-0.037,0.004
			c-0.002-0.005-0.012-0.01-0.021-0.012c-0.015-0.004-0.015-0.004-0.011,0.008c0.009,0.036,0.009,0.039-0.012,0.039
			c-0.011,0.001-0.022-0.002-0.027-0.003c-0.006-0.005-0.047,0.001-0.059,0.008c-0.012,0.005-0.012,0.032-0.003,0.031
			c0.003,0,0.007,0.003,0.007,0.006c0,0.002,0.004,0.004,0.008,0.002c0.01-0.005,0.033,0.007,0.031,0.016
			c-0.002,0.005-0.002,0.008,0.002,0.008c0.004,0,0.006-0.002,0.004-0.005c-0.006-0.01,0.013-0.006,0.024,0.005
			c0.008,0.006,0.009,0.01,0.006,0.011c-0.006,0-0.004,0.003,0.002,0.008c0.005,0.003,0.009,0.01,0.009,0.013
			c0,0.002,0.004,0.004,0.008,0.004c0.004,0,0.007,0.002,0.004,0.005c0,0.002,0,0.005,0.003,0.005c0.002,0,0.006,0.004,0.006,0.007
			c0,0.004,0,0.009,0.001,0.013c0.002,0.007-0.005,0.06-0.012,0.081c-0.006,0.02-0.021,0.031-0.036,0.026
			c-0.01-0.002-0.082-0.007-0.147-0.007c-0.013-0.001-0.027-0.002-0.031-0.004c-0.006-0.008-0.024-0.004-0.035,0.007
			c-0.006,0.006-0.016,0.01-0.02,0.01c-0.016,0-0.024,0.014-0.016,0.029c0.002,0.007,0.006,0.029,0.006,0.05
			c0.001,0.039-0.009,0.084-0.027,0.097c-0.004,0.004-0.005,0.007-0.005,0.006c0.003,0.001,0.003,0.008,0,0.017
			c-0.004,0.01-0.004,0.016,0,0.015c0.003,0.001,0.005,0.005,0.005,0.009s0.004,0.007,0.008,0.006
			c0.008-0.001,0.008,0.026,0.002,0.049c-0.002,0.013-0.002,0.014,0.019,0.013c0.013,0,0.028-0.002,0.035-0.004
			c0.021-0.007,0.032,0.001,0.043,0.022c0.007,0.024,0.028,0.034,0.039,0.02c0.011-0.015,0.043-0.025,0.073-0.027
			c0.02,0,0.039,0,0.041,0c0.006,0,0.014-0.007,0.019-0.016c0.004-0.011,0.014-0.019,0.024-0.02c0.01-0.003,0.017-0.007,0.025-0.024
			c0.004-0.011,0.01-0.02,0.014-0.02c0.004,0,0.01-0.004,0.014-0.008c0.007-0.007,0.007-0.01,0-0.015
			c-0.016-0.011-0.016-0.023-0.006-0.037c0.019-0.028,0.052-0.064,0.06-0.064c0.003,0,0.018-0.006,0.035-0.014
			c0.028-0.015,0.045-0.036,0.034-0.047c-0.002-0.003-0.006-0.011-0.006-0.017c0-0.027,0.058-0.037,0.087-0.016
			c0.019,0.012,0.03,0.01,0.051-0.007c0.009-0.009,0.022-0.018,0.03-0.02c0.009-0.001,0.017-0.006,0.021-0.012
			c0.004-0.006,0.01-0.011,0.014-0.013c0.011-0.004,0.056,0.016,0.056,0.027c0,0.004,0.003,0.012,0.007,0.018
			c0.005,0.01,0.005,0.013-0.002,0.019c-0.006,0.005-0.007,0.007-0.004,0.009v0.001c0.004,0.003,0.008,0.001,0.011-0.002
			c0.005-0.006,0.007-0.006,0.013,0c0.006,0.004,0.01,0.011,0.01,0.014c0,0.004,0.004,0.008,0.009,0.008
			c0.007,0,0.011,0.001,0.011,0.003c0,0.008,0.052,0.047,0.066,0.047c0.005,0,0.016,0.007,0.023,0.015
			c0.007,0.009,0.017,0.015,0.023,0.015c0.005,0,0.009,0.005,0.009,0.008c0,0.005,0.007,0.013,0.015,0.016
			c0.011,0.004,0.018,0.011,0.024,0.029c0.006,0.015,0.006,0.025,0.002,0.026c-0.002,0.002-0.004,0.007-0.004,0.012
			c0,0.013-0.05,0.023-0.087,0.02c-0.032-0.003-0.041,0-0.041,0.012c0,0.01,0.047,0.038,0.062,0.038c0.004,0,0.01,0.003,0.012,0.006
			c0.003,0.005,0.009,0.01,0.016,0.011c0.014,0.004,0.026-0.011,0.02-0.024c-0.002-0.004-0.002-0.015,0-0.022
			c0.004-0.011,0.006-0.013,0.016-0.011c0.013,0.005,0.035-0.015,0.035-0.031c0-0.005,0.006-0.011,0.011-0.013
			c0.017-0.004,0.015-0.024-0.002-0.034c-0.011-0.007-0.013-0.009-0.007-0.021c0.003-0.011,0.009-0.013,0.023-0.012
			c0.008,0.002,0.016,0.005,0.018,0.007c0,0.003,0.005,0.009,0.011,0.013c0.007,0.009,0.007,0.009,0.015-0.001
			c0.005-0.016-0.011-0.033-0.058-0.055c-0.03-0.016-0.033-0.021-0.03-0.029c0.004-0.006,0.004-0.009,0-0.01
			c-0.002,0-0.011,0-0.025-0.001c-0.018-0.001-0.025-0.006-0.039-0.021c-0.012-0.01-0.02-0.024-0.02-0.029
			c-0.003-0.014-0.01-0.025-0.033-0.039c-0.023-0.014-0.03-0.03-0.021-0.042c0.005-0.005,0.005-0.009,0-0.01
			c-0.009-0.007-0.005-0.017,0.013-0.024c0.021-0.006,0.035-0.006,0.026-0.001c-0.005,0.004-0.005,0.009-0.002,0.018
			c0.005,0.018,0.017,0.025,0.021,0.016c0.004-0.007,0.006-0.005,0.006,0.004c0,0.005,0.002,0.013,0.006,0.014
			c0.004,0.002,0.006-0.002,0.006-0.007c0-0.007,0.001-0.013,0.007-0.015c0.003-0.003,0.003,0,0.002,0.005
			c-0.002,0.006-0.002,0.014,0.005,0.018c0.006,0.008,0.006,0.01,0.002,0.013c-0.007,0.004,0.017,0.026,0.028,0.026
			c0.005,0,0.013,0.006,0.017,0.011c0.005,0.007,0.017,0.012,0.022,0.012c0.004,0.001,0.009,0.002,0.015,0.002
			c-0.006,0-0.011,0.001-0.015,0.002c-0.013,0.004-0.013,0.004,0.004,0.005c0.01,0.001,0.019,0.003,0.021,0.006
			c0.002,0.001,0.008,0.005,0.012,0.005c0.007,0,0.021,0.01,0.037,0.021c0.007,0.007,0.016,0.014,0.027,0.02
			c-0.002,0.012-0.004,0.022-0.004,0.033c-0.003,0.031-0.001,0.033,0.012,0.043c0.01,0.005,0.012,0.01,0.01,0.013
			c-0.008,0.004-0.002,0.021,0.007,0.021c0.005,0.001,0.013,0.005,0.016,0.009c0.009,0.009,0.009,0.019,0.002,0.037
			c-0.004,0.006-0.004,0.011,0,0.013c0.005,0.001,0.007,0.008,0.009,0.013c0.004,0.01,0.016,0.012,0.016,0.003
			c0-0.013,0.018,0.014,0.021,0.032c0.004,0.016,0.006,0.018,0.02,0.016c0.009-0.003,0.012-0.002,0.012,0.003
			s0.002,0.008,0.004,0.008c0.004,0.001,0.008-0.002,0.008-0.004c0-0.004,0.002-0.005,0.002-0.005c0.006,0,0.006,0.006,0.006,0.013
			c0,0.009,0.004,0.014,0.008,0.014c0.01,0,0.008-0.027-0.006-0.051c-0.004-0.012-0.004-0.015-0.002-0.01
			c0.006,0.004,0.014,0.007,0.02,0.007c0.007,0,0.01-0.002,0.007-0.012c-0.004-0.008-0.002-0.01,0.009-0.008
			c0.01,0.001,0.012,0,0.012-0.008c-0.002-0.01-0.002-0.011,0.007-0.005c0.004,0.005,0.008,0.005,0.008,0.004
			c0-0.003,0.003-0.002,0.009,0.002c0.004,0.004,0.008,0.012,0.008,0.018c0,0.008,0,0.008,0.008,0.001
			c0.008-0.008,0.008-0.01-0.002-0.021c-0.006-0.006-0.013-0.01-0.013-0.007c0,0.008-0.016-0.009-0.021-0.022
			c-0.004-0.008-0.01-0.012-0.018-0.011c-0.007-0.001-0.012-0.006-0.016-0.017c-0.004-0.01-0.01-0.016-0.012-0.016
			c-0.006,0-0.022-0.033-0.02-0.04c0.002-0.002,0.01,0.002,0.018,0.01c0.017,0.014,0.029,0.018,0.021,0.008
			c-0.002-0.003,0-0.004,0.008-0.001c0.006,0.003,0.008,0.002,0.004-0.007c-0.004-0.007-0.002-0.008,0.002-0.003
			c0.014,0.014,0.018,0.008,0.006-0.006c-0.012-0.013-0.012-0.013-0.002-0.019c0.008-0.003,0.012-0.003,0.016,0.001
			c0.002,0.003,0.002,0.004-0.004,0.001c-0.002,0-0.006,0-0.006,0.003c0,0.01,0.015,0.006,0.019-0.004
			c0.002-0.01,0.005-0.01,0.021-0.004c0.009,0.003,0.022,0.01,0.029,0.014c0.016,0.01,0.033,0.007,0.047-0.006
			c0.005-0.008,0.011-0.012,0.026-0.01c0.01,0,0.02-0.003,0.024-0.006c0.007-0.007,0.007-0.007-0.012-0.015
			c-0.016-0.008-0.041-0.051-0.031-0.058c0.004-0.004,0.004-0.011,0.004-0.018c0-0.006,0.006-0.012,0.011-0.014
			c0.009-0.002,0.011-0.007,0.013-0.027c0.002-0.023,0.006-0.027,0.019-0.033c0.009-0.005,0.013-0.01,0.013-0.02
			c0-0.007,0.007-0.02,0.018-0.029c0.008-0.01,0.016-0.02,0.016-0.023c0-0.004,0.005-0.007,0.014-0.007
			c0.008,0,0.014,0.005,0.014,0.008c0,0.009,0.029,0.017,0.057,0.018c0.009-0.001,0.018,0.001,0.015,0.004
			c-0.002,0.002-0.006,0.004-0.009,0.004c-0.004,0-0.027,0.02-0.021,0.021c0.005,0.002,0.012,0.004,0.026,0.009
			c0.009,0.003,0.013,0.007,0.011,0.018c-0.002,0.012,0.002,0.015,0.016,0.016c0.008,0.001,0.016-0.001,0.021-0.006
			c0-0.004,0.008-0.009,0.016-0.008c0.007,0,0.014-0.001,0.016-0.004c0-0.002,0.009-0.005,0.021-0.005
			c0.013,0.001,0.018-0.002,0.018-0.007c0-0.009,0.004-0.007,0.029,0.015c0.002,0.005,0.012,0.01,0.016,0.01
			c0.004,0,0.012,0.005,0.018,0.008c0.006,0.005,0.016,0.009,0.019,0.009c0.004,0,0.011,0.004,0.017,0.01
			c0.007,0.006,0.013,0.007,0.026,0.003c-0.004,0.002-0.007,0.004-0.013,0.005c0.004,0.004,0.008,0.008,0.013,0.013
			c0.008,0.007,0.024,0.019,0.041,0.028c0.022,0.013,0.034,0.021,0.041,0.035c0.019,0.038-0.006,0.053-0.078,0.053
			c-0.031,0-0.052-0.002-0.052-0.005c0-0.003-0.008-0.004-0.018-0.004c-0.009,0-0.016-0.004-0.018-0.006
			c-0.002-0.002-0.01-0.004-0.014-0.002c-0.008,0.001-0.012-0.001-0.016-0.009c-0.005-0.008-0.011-0.01-0.018-0.008
			c-0.01,0.002-0.016,0-0.02-0.008c-0.01-0.009-0.014-0.01-0.053-0.007c-0.043,0.003-0.074,0.015-0.089,0.033
			c-0.006,0.005-0.017,0.005-0.045,0.001c-0.039-0.004-0.056,0-0.042,0.014c0.012,0.014-0.008,0.02-0.059,0.02
			c-0.025,0-0.047,0.002-0.047,0.005c-0.002,0.002-0.006,0.003-0.012,0.002c-0.003-0.002-0.007,0-0.007,0.003
			c0,0.004,0.004,0.006,0.009,0.006c0.004,0,0.008,0.005,0.008,0.014c0,0.012,0.004,0.015,0.02,0.014
			c0.004,0,0.006,0.004,0.004,0.008c0,0.006,0,0.013,0.004,0.016c0,0.002,0.003,0.01,0.003,0.016c0,0.005,0,0.006-0.002,0.002
			c-0.005-0.011-0.017-0.007-0.017,0.005c0.002,0.009,0.008,0.015,0.021,0.019c0.011,0.005,0.015,0.007,0.011,0.007
			c-0.004,0-0.004,0.003,0,0.013c0.004,0.007,0.012,0.014,0.014,0.015c0,0.003,0,0.007-0.006,0.01
			c-0.009,0.006-0.007,0.007,0.017,0.008c0.009,0,0.009,0,0.002,0.002c-0.006,0.002-0.011,0.005-0.011,0.008
			s0.005,0.004,0.009,0.003c0.003-0.003,0.009-0.001,0.009,0.001c0,0.003,0.003,0.005,0.008,0.005c0.006,0,0.008-0.002,0.008-0.005
			c0-0.008,0.025,0,0.037,0.012c0.008,0.009,0.016,0.011,0.03,0.01c0.021-0.001,0.025-0.003,0.021-0.024
			c-0.002-0.015,0.041,0,0.066,0.023c0.015,0.015,0.06,0.01,0.072-0.008c0.014-0.014,0.021-0.015,0.036-0.006
			c0.008,0.005,0.012,0.005,0.021-0.003c0.014-0.012,0.023-0.006,0.01,0.006c-0.006,0.005-0.008,0.011-0.004,0.014
			c0.004,0.003,0.004,0.01,0.002,0.017c-0.004,0.005-0.004,0.013-0.002,0.016c0.008,0.007,0.01,0.043,0.008,0.047
			c-0.008,0.009-0.027,0.066-0.034,0.097c-0.005,0.033-0.016,0.048-0.036,0.048c-0.007,0-0.019,0-0.029,0.002
			c-0.014,0.001-0.015,0.006-0.016,0.015c-0.001-0.011-0.003-0.021-0.005-0.021c-0.014-0.019-0.078-0.013-0.1,0.009
			c-0.004,0.006-0.013,0.008-0.021,0.007c-0.008-0.002-0.021-0.006-0.033-0.009c-0.015-0.004-0.029-0.008-0.036-0.011
			c-0.016-0.009-0.053-0.016-0.067-0.014c-0.01,0.002-0.015,0-0.018-0.009c-0.002-0.008-0.008-0.011-0.02-0.011
			c-0.031,0-0.055-0.01-0.059-0.022c-0.001-0.006-0.007-0.01-0.014-0.01c-0.008,0-0.014-0.003-0.016-0.005
			c-0.008-0.013-0.059-0.002-0.082,0.019c-0.018,0.014-0.022,0.038-0.01,0.054c0.008,0.014-0.013,0.043-0.033,0.043
			c-0.004,0-0.016-0.007-0.027-0.015c-0.021-0.016-0.057-0.029-0.078-0.029c-0.018,0-0.034-0.012-0.039-0.033
			c-0.002-0.009-0.007-0.018-0.018-0.021c-0.007-0.002-0.019-0.009-0.028-0.014c-0.011-0.005-0.029-0.008-0.045-0.008
			c-0.017,0.001-0.033-0.003-0.048-0.009c-0.023-0.012-0.036-0.028-0.028-0.035c0-0.001,0-0.003-0.008-0.003
			c-0.003,0-0.007,0.002-0.007,0.006c0,0.008-0.015,0.006-0.022-0.005c-0.004-0.009,0-0.014,0.013-0.024
			c0.005-0.008,0.016-0.013,0.022-0.011c0.005,0.001,0.007,0,0.007-0.004c0-0.002-0.002-0.004-0.005-0.002
			c-0.004,0.002-0.004-0.004,0-0.018c0.002-0.018,0-0.023-0.009-0.027c-0.016-0.008-0.018-0.019-0.004-0.031
			c0.005-0.005,0.007-0.012,0.005-0.016c0-0.004,0-0.006,0.004-0.004c0.004,0.001,0.004,0.001,0.004-0.002
			c0-0.009-0.008-0.01-0.017-0.002c-0.01,0.009-0.012,0.009-0.012-0.002c-0.002-0.009-0.016-0.017-0.029-0.018
			c-0.001,0-0.011,0.005-0.022,0.009c-0.019,0.011-0.045,0.011-0.06,0.003c-0.002-0.002-0.008,0-0.01,0.003
			c0,0.004-0.006,0.004-0.018-0.001c-0.012-0.006-0.016-0.006-0.018-0.001c-0.002,0.003-0.006,0.006-0.014,0.006
			c-0.003,0-0.011,0.003-0.014,0.007c-0.006,0.004-0.01,0.004-0.02,0c-0.014-0.009-0.029-0.008-0.1,0.004
			c-0.061,0.011-0.096,0.022-0.096,0.035c0,0.001-0.004,0.003-0.008,0.002c-0.006-0.002-0.008-0.001-0.01,0.002
			c-0.002,0.003-0.009,0.005-0.014,0.004c-0.004,0-0.014,0.006-0.02,0.013c-0.005,0.009-0.017,0.014-0.021,0.015
			c-0.013,0.001-0.031-0.001-0.035-0.005c-0.002-0.002-0.017-0.001-0.033,0c-0.036,0.006-0.055-0.001-0.06-0.021
			c-0.003-0.01-0.01-0.012-0.021-0.005c-0.002,0.002-0.012,0.017-0.019,0.033c-0.016,0.044-0.03,0.058-0.063,0.074
			c-0.031,0.016-0.063,0.045-0.063,0.058c0,0.005-0.005,0.017-0.011,0.026c-0.012,0.013-0.014,0.023-0.014,0.05
			c0,0.02-0.002,0.037-0.006,0.04c-0.004,0.004-0.008,0.011-0.008,0.013s-0.009,0.013-0.02,0.021
			c-0.009,0.009-0.023,0.021-0.028,0.026c-0.013,0.011-0.043,0.026-0.056,0.026c-0.01,0-0.019,0.01-0.031,0.039
			c-0.008,0.014-0.018,0.026-0.02,0.026c-0.014,0-0.041,0.044-0.041,0.066c0,0.013-0.016,0.034-0.037,0.055
			c-0.003,0.005-0.016,0.024-0.022,0.042c-0.007,0.018-0.017,0.035-0.021,0.038c-0.006,0.004-0.012,0.016-0.014,0.027
			c-0.002,0.012-0.006,0.026-0.007,0.033c-0.002,0.009,0,0.011,0.005,0.01c0.004-0.001,0.012,0.003,0.018,0.011
			c0.014,0.013,0.018,0.026,0.008,0.027c-0.008,0-0.01,0.027-0.002,0.038c0.012,0.016,0.01,0.063-0.004,0.095
			c-0.004,0.016-0.01,0.033-0.01,0.041c0,0.017-0.025,0.061-0.035,0.061c-0.004,0-0.006,0.002-0.006,0.004
			c0,0.003,0.002,0.006,0.006,0.006c0.006,0,0.012,0.007,0.014,0.014c0.004,0.009,0.009,0.02,0.013,0.024
			c0.002,0.005,0.002,0.011-0.002,0.011c-0.003,0.003-0.003,0.016-0.003,0.03c0,0.02,0.001,0.026,0.014,0.039
			c0.019,0.018,0.023,0.026,0.01,0.022c-0.008-0.004-0.01-0.003-0.006,0.009c0.003,0.018,0.007,0.021,0.021,0.011
			c0.007-0.007,0.007-0.009,0.003-0.011c-0.007-0.003-0.009-0.017-0.002-0.017c0.002,0,0.008,0.006,0.011,0.015
			c0.002,0.009,0.01,0.016,0.012,0.016c0.004,0,0.006,0.005,0.006,0.008c0,0.005,0.002,0.008,0.009,0.008
			c0.004,0,0.007,0.002,0.007,0.004s0.011,0.016,0.025,0.032c0.016,0.019,0.027,0.034,0.027,0.043c0,0.023,0.004,0.034,0.012,0.038
			c0.002,0.002,0.002,0.007,0.002,0.01c-0.002,0.003-0.002,0.006,0,0.006c0.004,0,0.007,0.002,0.004,0.006
			c0,0.004,0.007,0.01,0.021,0.016c0.023,0.01,0.041,0.024,0.104,0.082c0.065,0.058,0.08,0.063,0.129,0.039
			c0.052-0.025,0.13-0.026,0.176-0.004c0.004,0.002,0.011,0.002,0.023-0.003c0.047-0.025,0.084-0.039,0.096-0.04
			c0.006,0,0.013-0.004,0.016-0.007c0.004-0.011,0.045-0.02,0.096-0.021c0.031-0.001,0.039,0.001,0.049,0.009
			c0.012,0.013,0.031,0.046,0.031,0.058c0,0.005,0.006,0.015,0.013,0.02c0.009,0.009,0.015,0.011,0.03,0.008
			c0.011-0.001,0.027-0.004,0.039-0.005c0.013-0.002,0.029-0.003,0.039-0.004c0.014-0.001,0.019,0,0.02,0.01
			c0.002,0.006,0.01,0.015,0.015,0.015c0.005,0.002,0.011,0.008,0.011,0.012c0,0.003,0.002,0.01,0.008,0.012
			c0.011,0.009,0.006,0.06-0.01,0.088c-0.006,0.013-0.006,0.02-0.004,0.02c0.004,0.002,0.006,0.007,0.002,0.013
			c-0.002,0.005-0.004,0.021-0.004,0.035c-0.005,0.023-0.012,0.033-0.021,0.029c-0.007-0.005-0.005,0.01,0.003,0.027
			c0.004,0.009,0.013,0.024,0.018,0.037c0.006,0.012,0.017,0.03,0.029,0.04c0.037,0.036,0.084,0.107,0.084,0.127
			c0,0.006,0.003,0.013,0.002,0.017c0.004,0.003,0.005,0.006,0.004,0.008c-0.002,0.004,0.001,0.018,0.01,0.034
			c0.005,0.015,0.016,0.041,0.021,0.057c0.009,0.028,0.009,0.029-0.001,0.036c-0.011,0.007-0.011,0.009,0.009,0.052
			c0.014,0.03,0.016,0.051,0.016,0.068c0,0.027-0.009,0.053-0.018,0.053c-0.011,0-0.036,0.045-0.039,0.07
			c-0.002,0.031-0.017,0.08-0.021,0.08c-0.006,0-0.004,0.097,0.004,0.116c0.004,0.009,0.01,0.017,0.012,0.017
			c0.005,0,0.028,0.048,0.049,0.105c0.004,0.011,0.015,0.032,0.025,0.044c0.009,0.02,0.013,0.033,0.013,0.063
			c0.002,0.02,0.003,0.049,0.007,0.063c0.004,0.014,0.012,0.048,0.016,0.075c0.011,0.055,0.018,0.073,0.045,0.102
			c0.012,0.014,0.023,0.035,0.031,0.059c0.012,0.039,0.029,0.076,0.039,0.082c0.002,0,0.006,0.012,0.006,0.027
			c0.004,0.021,0.002,0.025-0.004,0.025c-0.004,0-0.008,0.003-0.012,0.006c-0.002,0.007,0.008,0.026,0.016,0.03
			c0.002,0.003,0.004,0.01,0,0.018c-0.002,0.019,0.006,0.032,0.014,0.021c0.004-0.006,0.006-0.006,0.006-0.002
			c0,0.003,0.003,0.007,0.007,0.007c0.005,0,0.013,0.005,0.017,0.01c0.013,0.013,0.022,0.013,0.037,0
			c0.006-0.006,0.015-0.009,0.03-0.009c0.013,0,0.028-0.004,0.035-0.008c0.015-0.012,0.054-0.014,0.082-0.01
			c0.012,0.004,0.022,0.002,0.028-0.002c0.004-0.002,0.013-0.004,0.02-0.002c0.006,0,0.012-0.002,0.012-0.006
			c0-0.004,0.008-0.009,0.021-0.009c0.028-0.005,0.082-0.042,0.112-0.077c0.004-0.007,0.018-0.021,0.031-0.033
			c0.021-0.02,0.055-0.072,0.055-0.082c0-0.007,0.035-0.045,0.043-0.045c0.006,0,0.015-0.02,0.029-0.062
			c0.008-0.03,0.01-0.069,0.004-0.063c-0.01,0.005-0.006-0.007,0.006-0.021c0.006-0.009,0.028-0.02,0.049-0.031
			c0.028-0.012,0.039-0.02,0.05-0.034c0.007-0.01,0.009-0.019,0.007-0.021c-0.003-0.004-0.003-0.01,0-0.021
			c0.006-0.016,0.006-0.058,0-0.065c-0.003-0.004-0.007-0.008-0.007-0.012c0-0.002,0.004-0.002,0.007,0.004
			c0.002,0.006,0.004,0.004,0.004-0.006c0-0.007-0.002-0.011-0.005-0.011c-0.006,0.002-0.008-0.004-0.008-0.017
			c-0.002-0.01-0.005-0.02-0.009-0.02c-0.002-0.001-0.006-0.008-0.006-0.014c0-0.013,0.049-0.068,0.062-0.069
			c0.005,0,0.009-0.001,0.009-0.004c0-0.002,0.004-0.01,0.012-0.017c0.016-0.028,0.037-0.043,0.064-0.055
			c0.017-0.008,0.034-0.015,0.034-0.017c0.004-0.003,0.007-0.007,0.013-0.009c0.014-0.01,0.049-0.058,0.055-0.079
			c0.008-0.017,0.008-0.026,0.002-0.042c-0.004-0.014-0.004-0.053-0.004-0.091c0-0.089,0.002-0.085-0.014-0.091
			c-0.007-0.004-0.013-0.013-0.021-0.042c-0.01-0.045-0.008-0.052,0.004-0.056c0.006-0.001,0.011-0.006,0.011-0.009
			c0-0.003-0.005-0.004-0.009-0.002c-0.012,0.004-0.018-0.01-0.01-0.022c0.004-0.007,0-0.013-0.01-0.027
			c-0.01-0.009-0.017-0.022-0.017-0.026c0-0.01,0.016-0.059,0.024-0.064c0.004-0.003,0.027-0.059,0.031-0.078
			c0.002-0.007,0.01-0.014,0.016-0.017c0.016-0.009,0.043-0.039,0.055-0.058c0.022-0.045,0.095-0.122,0.138-0.15
			c0.028-0.017,0.111-0.108,0.113-0.123c0-0.002,0.009-0.016,0.021-0.033c0.008-0.016,0.018-0.033,0.018-0.037
			c0-0.011,0.031-0.08,0.04-0.082c0.001-0.001,0.003-0.005,0.003-0.007s0.006-0.017,0.013-0.031s0.015-0.032,0.015-0.044
			c0-0.013,0.002-0.018,0.01-0.021c0.011-0.002,0.014-0.01,0.005-0.012c-0.005-0.002-0.007-0.03-0.004-0.048
			c0.002-0.014-0.018-0.022-0.029-0.009c-0.011,0.01-0.057,0.026-0.069,0.021c-0.005,0-0.014,0.001-0.021,0.004
			c-0.007,0.003-0.017,0.005-0.023,0.004c-0.005-0.003-0.016,0-0.025,0.007c-0.011,0.008-0.019,0.01-0.032,0.008
			c-0.013-0.001-0.022,0-0.034,0.007c-0.027,0.017-0.047,0.009-0.068-0.023c-0.003-0.008-0.014-0.017-0.021-0.018
			c-0.005-0.001-0.009-0.002-0.014-0.003c0.005-0.002,0.009-0.005,0.012-0.007c0.021-0.01,0.019-0.025-0.007-0.052
			c-0.012-0.013-0.026-0.029-0.03-0.035c-0.004-0.006-0.011-0.011-0.014-0.012c-0.002,0.001-0.01-0.007-0.014-0.016
			c-0.008-0.008-0.015-0.017-0.02-0.019c-0.006-0.002-0.014-0.007-0.018-0.011c-0.002-0.003-0.01-0.006-0.014-0.003
			c-0.004,0.001-0.008-0.003-0.009-0.012c-0.002-0.009-0.002-0.013,0.005-0.012c0.005,0,0.005-0.001-0.002-0.008
			c-0.009-0.011-0.014-0.011-0.016,0.003c-0.004,0.012-0.004,0.012-0.012,0.001c-0.006-0.005-0.011-0.02-0.013-0.032
			c-0.017-0.059-0.025-0.075-0.05-0.095c-0.028-0.024-0.037-0.041-0.037-0.093c-0.002-0.019-0.006-0.039-0.009-0.043
			c-0.005-0.005-0.009-0.015-0.009-0.022c0-0.007,0-0.013-0.002-0.013c0,0-0.012-0.009-0.025-0.021
			c-0.025-0.023-0.039-0.06-0.023-0.06c0.006,0,0.004-0.004-0.007-0.013c-0.007-0.006-0.018-0.026-0.028-0.043
			c-0.007-0.017-0.02-0.04-0.027-0.052c-0.006-0.011-0.01-0.023-0.01-0.027c0-0.004-0.006-0.012-0.01-0.02
			c-0.004-0.006-0.01-0.017-0.01-0.022c0-0.007-0.009-0.02-0.021-0.032c-0.01-0.013-0.018-0.028-0.018-0.034
			s-0.002-0.013-0.006-0.015c-0.001-0.001-0.002-0.008-0.003-0.01c0.004,0.009,0.007,0.02,0.011,0.024
			c0.006,0.007,0.008,0.015,0.008,0.019c0,0.009,0.039,0.052,0.047,0.052c0.002,0,0.008-0.003,0.012-0.008
			c0.005-0.004,0.014-0.008,0.02-0.008c0.006,0.001,0.01,0.005,0.01,0.008c0,0.005,0.002,0.009,0.004,0.009
			c0.005-0.001,0.007,0.001,0.007,0.005c0,0.003,0.005,0.01,0.011,0.016c0.003,0.005,0.012,0.019,0.018,0.03
			c0.005,0.011,0.015,0.025,0.021,0.032c0.011,0.013,0.025,0.039,0.021,0.043c-0.004,0.005,0.014,0.034,0.021,0.034
			c0.006,0,0.01,0.004,0.012,0.008c0.002,0.004,0.006,0.006,0.008,0.003c0.002,0,0.004,0,0.002,0.003s0,0.013,0.008,0.019
			c0.006,0.008,0.011,0.018,0.011,0.022c0,0.004,0.002,0.01,0.006,0.012c0.003,0.002,0.003,0.01,0.003,0.023
			c-0.003,0.014,0,0.02,0.004,0.026c0.006,0.004,0.008,0.011,0.008,0.016c-0.004,0.006,0.002,0.013,0.009,0.018
			c0.008,0.006,0.015,0.013,0.017,0.017c0.002,0.006,0.01,0.015,0.018,0.02c0.01,0.007,0.016,0.013,0.016,0.015
			c-0.002,0.002,0.003,0.006,0.011,0.009c0.009,0.005,0.013,0.011,0.013,0.023c0,0.01,0.004,0.02,0.011,0.024
			c0.004,0.004,0.01,0.01,0.01,0.012c0,0.003,0.009,0.013,0.021,0.025c0.01,0.011,0.02,0.023,0.02,0.027
			c0,0.004,0.001,0.007,0.005,0.008c0.002,0,0.007,0.006,0.009,0.016c0.008,0.016,0.008,0.016-0.004,0.007
			c-0.005-0.006-0.009-0.012-0.007-0.014c0.002-0.002-0.002-0.003-0.005-0.001c-0.006,0-0.01,0-0.008-0.004
			c0.002-0.005-0.004-0.008-0.01-0.009c-0.006-0.002-0.006,0-0.004,0.007c0.002,0.01,0.002,0.01-0.006,0
			c-0.004-0.005-0.01-0.007-0.01-0.005c-0.005,0.006,0.023,0.051,0.027,0.046c0.005-0.004,0.027,0.019,0.027,0.029
			c0,0.004,0.004,0.011,0.004,0.014c0.003,0.008,0.022,0.067,0.024,0.077c0,0.025,0.005,0.036,0.026,0.038
			c0.007,0.002,0.02,0.002,0.022-0.001c0.005-0.002,0.009-0.004,0.013-0.003c0.003,0.003,0.013-0.005,0.024-0.013
			c0.011-0.012,0.022-0.016,0.034-0.016c0.018,0,0.054-0.012,0.059-0.02c0.001-0.004,0.01-0.005,0.021-0.005
			s0.019-0.004,0.025-0.014c0.008-0.008,0.029-0.018,0.057-0.028c0.052-0.017,0.076-0.031,0.074-0.041
			c-0.004-0.013,0.005-0.024,0.026-0.032c0.041-0.016,0.045-0.018,0.063-0.018c0.018,0.001,0.029-0.007,0.029-0.025
			c0.002-0.01,0.006-0.013,0.02-0.014c0.019-0.001,0.029-0.009,0.029-0.022c0-0.011,0.017-0.025,0.033-0.024
			c0.012,0,0.014-0.002,0.012-0.032c0-0.032,0.006-0.041,0.02-0.026c0.006,0.006,0.01,0.005,0.016-0.002
			c0.009-0.01,0.009-0.023,0-0.018c-0.01,0.006-0.006-0.007,0.009-0.021c0.012-0.018,0.028-0.044,0.028-0.054
			c0-0.006-0.006-0.009-0.012-0.009c-0.007,0-0.013-0.004-0.015-0.011c-0.001-0.005-0.003-0.01-0.007-0.01
			c-0.004,0-0.006-0.003-0.006-0.005c0-0.012-0.017-0.023-0.041-0.028c-0.047-0.01-0.07-0.039-0.07-0.089
			c0-0.033-0.008-0.039-0.016-0.018c-0.014,0.021-0.076,0.08-0.082,0.078c-0.004-0.001-0.008,0-0.008,0.002
			c0,0.003-0.01,0.003-0.019,0.002c-0.011-0.002-0.019-0.005-0.019-0.007c0-0.003-0.004-0.005-0.007-0.005
			c-0.002,0-0.004,0.003-0.004,0.007c0,0.011-0.012,0.014-0.021,0.005c-0.006-0.006-0.014-0.009-0.016-0.009
			c-0.007,0-0.007-0.004-0.005-0.013c0.007-0.016,0.005-0.061-0.004-0.066c-0.01-0.008-0.018-0.004-0.026,0.016
			c-0.002,0.009-0.004,0.015-0.006,0.021c-0.003-0.004-0.007-0.008-0.01-0.014c-0.011-0.016-0.013-0.022-0.004-0.022
			c0.004,0,0.006,0.004,0.006,0.008c0,0.004,0.001,0.006,0.005,0.005c0.009-0.005,0.005-0.019-0.005-0.021
			c-0.006-0.003-0.013-0.006-0.013-0.008c0-0.003,0-0.008,0-0.011c0-0.002-0.007-0.009-0.013-0.013
			c-0.006-0.004-0.012-0.011-0.012-0.014s-0.004-0.006-0.007-0.005c-0.004,0-0.006-0.001-0.006-0.004
			c0-0.003-0.003-0.007-0.011-0.008c-0.006-0.002-0.012-0.007-0.012-0.012s-0.002-0.008-0.002-0.008
			c-0.008,0-0.029-0.051-0.025-0.061c0.002-0.006,0.002-0.008-0.002-0.004c-0.004,0.004-0.008,0.004-0.012,0.001
			c-0.009-0.004-0.009-0.005,0-0.005c0.004,0,0.012-0.005,0.018-0.008c0.016-0.018,0.021-0.02,0.031-0.015
			c0.006,0.002,0.013,0.003,0.019,0.002c0.006-0.002,0.013,0.004,0.021,0.014s0.013,0.02,0.013,0.024
			c0,0.003,0.002,0.006,0.006,0.007c0.002,0.001,0.011,0.014,0.019,0.028c0.012,0.019,0.02,0.028,0.035,0.032
			c0.01,0.003,0.022,0.014,0.033,0.022c0.006,0.008,0.017,0.014,0.023,0.014c0.007,0,0.007,0.001,0.002,0.004
			c-0.006,0.004-0.002,0.005,0.013,0.008c0.009,0.003,0.017,0.007,0.017,0.009c-0.005,0.002-0.002,0.005,0.002,0.005
			c0.002,0,0.004-0.003,0.004-0.005c0-0.003,0.004-0.003,0.012,0c0.008,0.004,0.013,0.005,0.017,0.003
			c0.003-0.001,0.012-0.004,0.024-0.005c0.02-0.002,0.035-0.009,0.029-0.017c-0.002-0.008,0.017-0.005,0.021,0.002
			c0.004,0.004,0.007,0.016,0.011,0.028c0.007,0.024,0.026,0.037,0.076,0.043c0.039,0.004,0.055,0.008,0.075,0.013
			c0.021,0.006,0.068,0.009,0.068,0.004c0-0.001,0.007-0.003,0.021-0.001c0.012,0.002,0.02,0.001,0.02-0.002
			c0-0.002,0.008-0.002,0.021,0c0.011,0.003,0.033,0.003,0.053,0.002c0.04-0.005,0.052-0.002,0.052,0.012
			c0,0.007,0.002,0.01,0.01,0.01c0.006,0.001,0.012,0.006,0.013,0.014c0.006,0.018,0.018,0.033,0.028,0.033
			c0.002,0.001,0.013,0.007,0.022,0.016c0.02,0.022,0.043,0.032,0.064,0.029c0.016-0.001,0.018-0.001,0.007,0.006
			c-0.012,0.007-0.019,0.009-0.035,0.006c-0.006,0-0.011,0.002-0.011,0.005c0,0.01,0.071,0.078,0.083,0.078
			c0.03,0,0.069-0.033,0.056-0.043c-0.006-0.005-0.002-0.024,0.005-0.024c0.002,0.001,0.004,0.006,0,0.013
			c0,0.007,0,0.012,0.006,0.012c0.004,0,0.004,0.003,0.002,0.005c-0.002,0.002,0.002,0.012,0.006,0.021
			c0.006,0.011,0.007,0.021,0.006,0.033c-0.002,0.01-0.002,0.025,0.001,0.035c0.013,0.046,0.022,0.082,0.03,0.109
			c0.004,0.016,0.015,0.044,0.026,0.064c0.01,0.021,0.022,0.046,0.03,0.056c0.003,0.01,0.012,0.03,0.018,0.047
			c0.003,0.016,0.015,0.04,0.024,0.055c0.021,0.028,0.047,0.087,0.047,0.102c0,0.014,0.028,0.056,0.041,0.062
			c0.019,0.008,0.035-0.003,0.041-0.023c0.003-0.015,0.02-0.023,0.041-0.017c0.007,0.002,0.01,0.001,0.009-0.003
			c-0.002-0.004-0.006-0.007-0.011-0.007c-0.009,0-0.009-0.004-0.002-0.024c0.004-0.01,0.007-0.013,0.019-0.013
			c0.01,0,0.012-0.001,0.008-0.014c-0.004-0.024-0.004-0.064,0.002-0.07c0.01-0.01,0.01-0.051,0.002-0.088
			c-0.009-0.054-0.007-0.068,0.014-0.062c0.011,0.002,0.014,0.001,0.016-0.01c0.002-0.008,0.008-0.012,0.012-0.012
			c0.016,0.001,0.033-0.011,0.033-0.025c0-0.007,0.002-0.014,0.002-0.014c0.012,0.001,0.063-0.046,0.082-0.07
			c0.008-0.012,0.024-0.026,0.037-0.03c0.01-0.005,0.024-0.014,0.031-0.02c0.014-0.01,0.014-0.015,0.012-0.039
			c-0.002-0.006,0.002-0.014,0.006-0.015c0.004-0.001,0.012-0.004,0.02-0.008c0.011-0.003,0.014-0.003,0.016,0.003
			c0.004,0.007,0.025,0.007,0.053,0c0.008-0.002,0.02-0.003,0.029-0.003c0.017,0.001,0.039-0.014,0.036-0.023
			c-0.002-0.005-0.002-0.01,0.001-0.011c0.002-0.001,0.006,0.003,0.006,0.008c0.004,0.006,0.008,0.009,0.01,0.007
			c0.004-0.003,0.013,0.009,0.013,0.022c0,0.012,0.011,0.03,0.034,0.056c0.01,0.011,0.019,0.021,0.019,0.023
			c0,0.003,0.004,0.005-0.021,0.006c0.009-0.003,0.025,0.01,0.021,0.018c-0.002,0.004,0,0.01,0.002,0.015
			c0.004,0.006,0.002,0.008-0.002,0.008c-0.006,0-0.006,0.002,0,0.006c0.006,0.003,0.008,0.004,0.01,0.001
			c0.005-0.009,0.014-0.001,0.025,0.023c0.012,0.018,0.012,0.029,0.01,0.059c-0.002,0.011-0.002,0.024-0.004,0.035
			c0.006,0.002,0.008,0.003,0.017,0.005c0.03,0.008,0.038,0.008,0.046-0.003c0.004-0.005,0.014-0.014,0.025-0.02
			c0.014-0.007,0.02-0.015,0.02-0.021c0.002-0.015,0.035,0.053,0.041,0.076c0.001,0.011,0.007,0.026,0.01,0.032
			c0.006,0.007,0.01,0.018,0.01,0.025c0,0.008,0.002,0.012,0.006,0.012c0.004,0,0.011,0.008,0.015,0.015
			c0.007,0.021,0.007,0.024-0.002,0.021c-0.009-0.003-0.009-0.001-0.007,0.005c0.003,0.004,0.007,0.008,0.009,0.008
			c0.003,0,0.003,0.007,0,0.014c-0.006,0.011-0.009,0.012-0.011,0.008c-0.004-0.003-0.006-0.004-0.006-0.002
			c0,0.009,0.008,0.017,0.02,0.016c0.011,0,0.018,0.013,0.008,0.022c-0.004,0.002-0.008,0.002-0.015-0.002
			c-0.005-0.003-0.007-0.003-0.007-0.001c0,0.003,0.002,0.008,0.009,0.01c0.011,0.005,0.013,0.037,0.005,0.064
			c-0.005,0.019,0.002,0.06,0.009,0.055c0.01-0.01,0.025-0.007,0.025,0.005c0,0.009,0.002,0.009,0.005,0.003
			c0.004-0.005,0.006-0.005,0.006,0c0,0.003,0.007,0.014,0.016,0.024c0.016,0.017,0.018,0.018,0.008,0.023
			c-0.008,0.004-0.008,0.005,0,0.01s0.01,0.005,0.01-0.003s0-0.007,0.007,0.001c0.005,0.006,0.007,0.019,0.007,0.029
			c0,0.012,0.002,0.022,0.004,0.024c0.004,0.002,0.01,0.011,0.012,0.023c0.002,0.011,0.009,0.025,0.016,0.032
			c0.007,0.007,0.013,0.018,0.013,0.023c0,0.007,0.011,0.017,0.039,0.034c0.003,0.002,0.001,0.001,0.003,0.003
			c-0.005-0.002-0.012-0.004-0.013-0.005c-0.004-0.002-0.012-0.004-0.019-0.002c-0.007,0.002-0.011,0-0.015-0.007
			c-0.001-0.006-0.006-0.01-0.01-0.01c-0.002,0-0.002-0.002-0.002-0.004c0-0.007-0.01-0.014-0.032-0.028
			c-0.01-0.006-0.02-0.014-0.025-0.018c-0.003-0.004-0.011-0.006-0.014-0.006c-0.002,0-0.005-0.004-0.005-0.007
			c0-0.004-0.002-0.01-0.004-0.013c-0.004-0.004-0.012-0.011-0.018-0.017c-0.009-0.009-0.014-0.011-0.035-0.011
			c-0.018-0.001-0.029-0.004-0.039-0.011c-0.011-0.009-0.022-0.011-0.029-0.004c-0.01,0.015,0.006,0.036,0.068,0.089
			c0.017,0.015,0.03,0.03,0.03,0.036c0,0.004,0.01,0.013,0.024,0.022c0.011,0.007,0.022,0.017,0.022,0.018
			c-0.004,0.011,0.014,0.058,0.025,0.061c0.016,0.005,0.055,0.067,0.059,0.089c0,0.013,0.017,0.033,0.039,0.053
			c0.009,0.007,0.015,0.015,0.015,0.017s0.022,0.021,0.049,0.043c0.044,0.04,0.046,0.04,0.062,0.035
			c0.011-0.003,0.019-0.004,0.025,0.002c0.005,0.005,0.007,0.009,0,0.019c-0.006,0.013-0.016,0.017-0.016,0.007
			c0-0.007-0.006,0-0.009,0.008c0,0.005,0.003,0.007,0.021,0.007c0.016,0,0.037,0.01,0.031,0.018c0,0,0.01,0.003,0.021,0.005
			c0.009,0.002,0.024,0.005,0.03,0.007c0.018,0.007,0.027,0.008,0.029,0.005c0.005-0.006,0.046-0.002,0.067,0.006
			s0.101,0.017,0.125,0.007c0.003-0.001,0.009-0.001,0.009,0.002c0,0.003,0.002,0.004,0.007,0.003c0.002,0,0.011,0,0.023,0.004
			c0.023,0.008,0.027,0.007,0.023-0.004c-0.002-0.007,0-0.008,0.008-0.006c0.008,0.001,0.012,0.006,0.012,0.011
			c0.001,0.01,0.001,0.01,0.007,0.001c0.005-0.005,0.009-0.006,0.009-0.003s0.01,0.007,0.02,0.007
			c0.012,0.001,0.027,0.004,0.033,0.006s0.02,0.001,0.037-0.003c0.012-0.003,0.022-0.007,0.018-0.007c-0.006,0-0.006,0-0.006-0.001
			c0.005-0.003,0.008-0.002,0.012,0c0.011,0.007,0.029,0.005,0.037-0.002c0.004-0.004,0.007-0.004,0.013-0.001
			c0.013,0.008,0.087,0.007,0.119-0.002c0.014-0.003,0.032-0.007,0.035-0.007c0.004,0.001,0.011-0.001,0.015-0.003
			c0.004-0.002,0.012-0.002,0.014,0c0.006,0.002,0.041-0.002,0.047-0.008c0.007-0.007-0.006-0.007-0.055-0.005
			c-0.013,0.002-0.029,0-0.029-0.002c-0.002-0.003-0.01,0-0.018,0.008c-0.005,0.008-0.013,0.011-0.016,0.01
			c-0.007-0.004-0.038-0.006-0.069-0.007c-0.018,0.001-0.026,0.003-0.03,0.007c-0.004,0.005-0.006,0.006-0.006,0.001
			c0-0.003-0.003-0.003-0.009-0.002c-0.007,0.002-0.013,0.001-0.015-0.001c-0.002-0.002,0-0.004,0.004-0.005
			c0.002,0,0.004-0.002,0-0.004c-0.002-0.003-0.004-0.003-0.006,0c-0.006,0.005-0.024,0.005-0.029-0.001
			c-0.008-0.006-0.029-0.002-0.032,0.006c-0.004,0.002-0.006,0.005-0.011,0.004c-0.004-0.003-0.007-0.001-0.01,0.002
			c-0.002,0.003-0.006,0.003-0.01-0.002c-0.012-0.009-0.021-0.008-0.026,0.005c-0.005,0.007-0.011,0.011-0.013,0.011
			c-0.01,0-0.01-0.008,0-0.012c0.006-0.004-0.016-0.017-0.025-0.012c-0.011,0.004-0.023-0.001-0.023-0.009
			c0-0.007,0-0.008-0.039-0.008c-0.016,0-0.021-0.002-0.023-0.007c0-0.006,0.002-0.007,0.009-0.006
			c0.017,0.001,0.044-0.008,0.043-0.014c-0.002-0.003-0.019-0.003-0.041-0.003c-0.027,0.002-0.043,0-0.054-0.004
			c-0.008-0.003-0.016-0.005-0.018-0.003c-0.002,0.002-0.008-0.002-0.014-0.005c-0.005-0.01-0.007-0.009-0.014,0.003
			c-0.007,0.009-0.011,0.012-0.023,0.011c-0.009-0.001-0.025-0.001-0.037-0.001c-0.015,0-0.02-0.002-0.025-0.01
			c-0.004-0.006-0.01-0.011-0.018-0.012c-0.007-0.001-0.018-0.005-0.029-0.009c-0.01-0.003-0.017-0.005-0.019-0.002
			c0,0.004-0.008,0.004-0.019,0.002c-0.009-0.003-0.018-0.003-0.021-0.002c-0.004,0.002-0.004-0.003-0.002-0.013
			c0.002-0.009,0.005-0.031,0.005-0.05c0.005-0.048,0.007-0.057,0.021-0.05c0.007,0.004,0.019-0.003,0.025-0.01
			c-0.004,0-0.006,0.002-0.008,0c-0.002-0.003-0.002-0.008,0-0.009c0.001-0.003-0.002-0.005-0.008-0.006
			c-0.012-0.003-0.014-0.005-0.02-0.028c-0.004-0.016-0.013-0.016-0.03-0.003c-0.015,0.013-0.015,0.019,0.004,0.018
			c0.003,0,0.009,0.003,0.009,0.005c0,0.003-0.006,0.005-0.013,0.003c-0.007-0.001-0.015-0.003-0.015-0.003
			c-0.004,0-0.006-0.002-0.006-0.005c0-0.002-0.003-0.007-0.009-0.01c-0.004-0.004-0.007-0.013-0.007-0.021
			c0-0.011-0.002-0.014-0.014-0.013c-0.003,0-0.014-0.003-0.022-0.006c-0.007-0.006-0.009-0.008-0.002-0.015
			c0.011-0.011,0.013-0.028,0.004-0.034c-0.005-0.003-0.007-0.01-0.005-0.013c0-0.001-0.001,0-0.001-0.001
			c0-0.001,0.003-0.002,0.002-0.003c-0.002-0.007-0.002-0.009,0.006-0.005c0.002,0.005,0.006,0.005,0.008,0.001
			c0.005-0.007-0.002-0.014-0.016-0.015c-0.01-0.001-0.013-0.001-0.006-0.002c0.006-0.002,0.01-0.007,0.01-0.01
			c0-0.004-0.002-0.005-0.004-0.004c-0.004,0.002-0.006-0.002-0.006-0.008s-0.007-0.019-0.014-0.028
			c-0.016-0.014-0.016-0.021-0.016-0.05c0-0.018-0.004-0.041-0.007-0.048c-0.006-0.013-0.047-0.054-0.056-0.054
			c-0.002,0.001-0.008-0.006-0.014-0.013c-0.008-0.012-0.014-0.015-0.02-0.013c-0.016,0.003-0.029-0.01-0.039-0.036
			c-0.002-0.013-0.009-0.028-0.012-0.03c-0.008-0.006-0.016-0.035-0.008-0.035c0.006,0,0.004-0.013-0.002-0.018
			c-0.002-0.001-0.004,0.002-0.004,0.007c0,0.012-0.015,0.019-0.022,0.009c-0.005-0.009-0.002-0.048,0.009-0.079
			c0.006-0.014,0.01-0.039,0.012-0.054c0.002-0.027,0.002-0.028,0.015-0.029c0.014,0,0.016,0,0.016,0.018
			c0,0.017,0,0.019,0.019,0.019c0.022,0.002,0.041,0.01,0.041,0.02c0,0.004,0.004,0.006,0.009,0.006
			c0.006-0.002,0.011,0.003,0.019,0.015c0.006,0.008,0.01,0.019,0.01,0.021c0,0.007,0.018,0.025,0.025,0.025
			c0.004,0.001,0.004-0.003,0.002-0.006c-0.002-0.004-0.002-0.004,0.002-0.002c0.004,0.005,0.006,0.007,0.008,0.021
			c0,0.011,0.01,0.008,0.01-0.003c0-0.009,0.003-0.009,0.01-0.003c0.003,0.005,0.013,0.009,0.021,0.011
			c0.006,0.004,0.006,0.006,0.002,0.008c-0.01,0.004-0.006,0.056,0.002,0.056c0.004,0,0.011-0.004,0.016-0.01
			c0.003-0.005,0.015-0.014,0.027-0.021c0.009-0.004,0.009-0.012,0.013-0.016c-0.003,0-0.007-0.004-0.011-0.008
			c-0.004-0.006-0.006-0.011-0.004-0.01c0,0,0.006,0.005,0.012,0.01c0.005,0.004,0.005,0.007,0.003,0.008
			c0.008-0.007,0.019-0.012,0.019-0.024c0.002-0.001,0.004-0.004,0.008-0.001c0.01,0.001,0.035-0.01,0.054-0.024
			c0.007-0.006,0.016-0.01,0.021-0.01c0.008,0,0.014-0.024,0.01-0.042c-0.002-0.007,0-0.012,0.002-0.01
			c0.004,0.002,0.006-0.002,0.006-0.007s-0.002-0.01-0.006-0.011c-0.002-0.002-0.006-0.012-0.008-0.024
			c0-0.012-0.004-0.028-0.01-0.036c-0.006-0.008-0.01-0.018-0.01-0.022c0-0.003-0.008-0.013-0.016-0.021s-0.015-0.017-0.015-0.019
			c0-0.004-0.006-0.009-0.011-0.01c-0.012-0.003-0.075-0.06-0.075-0.066c0-0.002-0.009-0.012-0.021-0.021
			c-0.014-0.015-0.016-0.021-0.016-0.039c0-0.013,0.002-0.022,0.008-0.022c0.002-0.001,0.008-0.009,0.015-0.018
			c0.005-0.009,0.012-0.015,0.014-0.013c0.002,0.001,0.007,0,0.007-0.004c0.002-0.004,0.006-0.006,0.01-0.006
			c0.006,0.001,0.008-0.001,0.008-0.005c-0.002-0.01,0.035-0.018,0.048-0.013c0.005,0.003,0.014,0.003,0.022,0.003
			c0.012,0,0.012,0.002,0.01,0.007c-0.004,0.011,0.012,0.043,0.025,0.046c0.012,0,0.018-0.011,0.008-0.024
			c-0.004-0.006-0.004-0.009,0-0.007c0.004,0.003,0.006,0.001,0.006-0.005c0-0.005,0.004-0.01,0.006-0.01
			c0.005,0,0.01-0.001,0.014-0.001s0.007-0.001,0.012-0.001c0.002,0,0.006-0.002,0.002-0.005c0-0.003,0-0.004,0.004-0.001
			c0.004,0.001,0.008,0.001,0.01-0.001c0.002-0.003,0.007-0.003,0.017,0.001c0.017,0.006,0.025,0.006,0.021-0.002
			c-0.002-0.004-0.002-0.005,0.002-0.004c0.002,0.002,0.006,0.001,0.006-0.001c0-0.002,0.003-0.004,0.005-0.002
			c0.009,0.004,0.017-0.007,0.013-0.021c-0.002-0.01-0.002-0.01,0.004,0.001c0.003,0.013,0.024,0.021,0.024,0.011
			c0-0.001,0.009-0.008,0.022-0.012c0.012-0.005,0.021-0.006,0.019-0.004s0,0.005,0.002,0.005c0.004,0,0.005-0.003,0.005-0.005
			c0-0.001,0.002-0.004,0.006-0.003c0.017,0.004,0.028,0.001,0.034-0.011c0.003-0.008,0.01-0.014,0.019-0.014
			c0.004-0.001,0.014-0.008,0.02-0.014c0.007-0.007,0.014-0.012,0.018-0.013c0.01,0.001,0.021-0.015,0.016-0.022
			c-0.002-0.002-0.002-0.003,0-0.002c0.007,0.003,0.02-0.007,0.016-0.011c-0.002-0.001,0.002-0.002,0.007-0.002
			c0.009,0.001,0.013-0.001,0.013-0.006c0-0.003-0.004-0.005-0.004-0.005c-0.009,0.004-0.014-0.016-0.006-0.021
			c0.004-0.003,0.004-0.007,0.002-0.009c0,0-0.002-0.002,0.002-0.001c0.002,0,0.006-0.005,0.006-0.009
			c0-0.005,0.004-0.01,0.006-0.011c0.004-0.001,0.004-0.008,0.004-0.014c0-0.015,0.007-0.024,0.015-0.024
			c0.005,0.001,0.009-0.001,0.009-0.004c0.002-0.015,0.002-0.023-0.002-0.031c-0.004-0.006-0.004-0.008,0.002-0.006
			c0.004,0,0.008-0.007,0.008-0.027c0-0.015,0-0.028-0.004-0.028c-0.004,0-0.006,0.002-0.006,0.004s0,0.006,0,0.009
			c-0.002,0.009-0.009,0.009-0.018,0.001c-0.005-0.004-0.011-0.008-0.021-0.007c-0.016,0.003-0.011-0.01,0.01-0.015
			c0.016-0.004,0.02-0.008,0.009-0.014c-0.003-0.001-0.003-0.003-0.003-0.005c0.003-0.002-0.002-0.007-0.012-0.008
			c-0.017-0.005-0.026-0.018-0.013-0.02c0.003-0.001,0.007,0,0.005,0.003c-0.002,0.002,0.002,0.006,0.006,0.007
			c0.004,0.001,0.008,0,0.008-0.003c0-0.005-0.019-0.02-0.045-0.038c-0.004-0.002-0.01-0.009-0.015-0.016
			c-0.02-0.033-0.045-0.057-0.069-0.069c-0.022-0.013-0.024-0.015-0.022-0.026c0.004-0.005,0.011-0.015,0.021-0.021
			c0.016-0.012,0.02-0.018,0.008-0.013c-0.004,0.001,0.002-0.003,0.012-0.011c0.01-0.008,0.021-0.011,0.031-0.01
			c0.014,0.001,0.017,0.001,0.017-0.009c0-0.014-0.003-0.015-0.032-0.022c-0.014-0.002-0.029-0.008-0.035-0.01
			c-0.011-0.007-0.039-0.008-0.035-0.001c0,0.001-0.002,0.008-0.006,0.01c-0.006,0.007-0.008,0.007-0.021,0.003
			c-0.007-0.004-0.013-0.012-0.013-0.016c-0.002-0.013-0.011-0.024-0.031-0.026c-0.023-0.002-0.043-0.017-0.043-0.031
			c0-0.009,0.002-0.011,0.009-0.007c0.016,0.005,0.034,0,0.037-0.012c0-0.008,0.011-0.017,0.019-0.019
			c0.01-0.005,0.017-0.011,0.017-0.014c0-0.01,0.011-0.016,0.026-0.012c0.024,0.005,0.03,0.015,0.02,0.029
			c-0.01,0.016-0.01,0.019,0.004,0.027c0.007,0.008,0.007,0.008,0,0.014c-0.007,0.007-0.007,0.014,0.004,0.015
			c0.002,0,0.011-0.007,0.023-0.015c0.02-0.014,0.025-0.017,0.046-0.016c0.013,0.001,0.028,0.002,0.034,0.005
			c0.004,0.002,0.01,0.004,0.014,0.004c0.002,0,0.009,0.002,0.016,0.006c0.008,0.004,0.01,0.01,0.01,0.035
			c0,0.022,0.002,0.029,0.006,0.024c0.004-0.002,0.005-0.003,0.005,0c0,0.006,0.024,0.018,0.028,0.014
			c0.004-0.002,0.008-0.002,0.015,0.001c0.006,0.003,0.009,0.004,0.009,0.003s0.008,0.002,0.016,0.007
			c0.012,0.009,0.018,0.022,0.008,0.021c-0.01-0.003-0.006,0.005,0.01,0.021c0.021,0.022,0.027,0.03,0.023,0.032
			c-0.007,0.004-0.007,0.026,0.002,0.03c0.002,0.002,0.004,0.009,0.004,0.014c0,0.01,0.002,0.01,0.023,0.011
			c0.013,0.002,0.023,0,0.023-0.001c0.002-0.004,0.009-0.005,0.016-0.005c0.004,0.002,0.009,0,0.009-0.003
			c0-0.001,0.005-0.001,0.007-0.001c0.009,0.006,0.03-0.01,0.03-0.022c0-0.018-0.033-0.066-0.057-0.089
			c-0.014-0.013-0.03-0.027-0.035-0.034c-0.006-0.006-0.018-0.017-0.032-0.024c-0.007-0.005-0.023-0.015-0.027-0.022
			c-0.008-0.011-0.006-0.012,0.008-0.016c0.006-0.003,0.019-0.012,0.027-0.021c0.014-0.013,0.014-0.015,0.006-0.027
			c-0.008-0.017-0.006-0.038,0.004-0.035c0.004,0,0.008-0.002,0.008-0.008c0-0.004,0-0.008,0.004-0.005
			c0.002,0.002,0.007-0.002,0.009-0.008c0.004-0.007,0.007-0.009,0.007-0.005c0,0.004,0.004,0.004,0.01-0.002
			c0.006-0.005,0.008-0.006,0.008,0c0,0.01,0.037,0.023,0.053,0.021c0.02-0.004,0.049-0.028,0.049-0.039
			c0-0.012,0.014-0.055,0.02-0.06c0.004-0.001,0.006-0.021,0.006-0.042c0-0.023,0.002-0.045,0.006-0.051
			c0.003-0.008,0.001-0.015-0.008-0.035c-0.008-0.014-0.016-0.028-0.021-0.031c-0.003-0.002-0.003-0.004-0.003-0.006
			c0.003-0.002,0-0.006-0.008-0.011c-0.019-0.014-0.041-0.065-0.023-0.062c0.01,0.002,0.051,0.037,0.055,0.046
			c0,0.004,0.011,0.017,0.02,0.027c0.009,0.01,0.023,0.028,0.03,0.039c0.005,0.013,0.019,0.029,0.026,0.036
			c0.007,0.007,0.018,0.021,0.021,0.03c0.004,0.009,0.01,0.017,0.012,0.017c0,0,0.008,0.007,0.01,0.016
			c0.003,0.009,0.012,0.022,0.021,0.03c0.006,0.006,0.012,0.011,0.018,0.016c0-0.006-0.002-0.012-0.002-0.017
			c-0.007-0.016-0.004-0.016,0.008-0.012c0.01,0.002,0.021,0.01,0.025,0.014c0.01,0.009,0.01,0.009,0.01,0.004
			c0-0.009-0.016-0.028-0.023-0.03c-0.004,0-0.014-0.004-0.02-0.012c-0.006-0.007-0.019-0.017-0.026-0.02
			c-0.007-0.005-0.019-0.019-0.021-0.029c-0.005-0.012-0.011-0.025-0.012-0.029c-0.006-0.012,0.018-0.006,0.044,0.011
			c0.02,0.014,0.026,0.016,0.026,0.011c0-0.001-0.01-0.009-0.019-0.014c-0.009-0.006-0.026-0.019-0.038-0.031
			c-0.01-0.012-0.042-0.041-0.068-0.064s-0.049-0.048-0.053-0.055c-0.01-0.017-0.031-0.039-0.037-0.041
			c-0.004-0.001-0.014-0.007-0.021-0.015c-0.016-0.022-0.028-0.028-0.037-0.027c-0.007,0.001-0.006,0.004,0.007,0.014
			c0.019,0.01,0.019,0.01,0.008,0.018c-0.01,0.006-0.01,0.008-0.002,0.013c0.011,0.01,0.028,0.035,0.024,0.038
			c-0.004,0.002-0.03-0.029-0.03-0.036c0-0.002-0.012-0.012-0.033-0.023c-0.019-0.01-0.039-0.023-0.047-0.028
			c-0.018-0.011-0.066-0.022-0.059-0.013c0.005,0.006-0.002,0.024-0.009,0.023c-0.005,0-0.007-0.005-0.007-0.011
			c0-0.004-0.004-0.008-0.008-0.01c-0.004,0-0.01-0.005-0.01-0.01s-0.002-0.009-0.005-0.01c-0.002,0-0.006-0.003-0.002-0.006
			c0.003-0.009-0.016-0.025-0.026-0.022c-0.006,0-0.013,0-0.017-0.001c-0.004-0.002-0.007,0-0.011,0.003
			c-0.004,0.002,0.013,0.008,0.037,0.011c0.008,0,0.008,0.002,0.004,0.002c-0.004,0.002-0.004,0.005,0.004,0.012
			c0.003,0.006,0.005,0.01,0.003,0.01c-0.003-0.001-0.005,0.004-0.003,0.009c0.001,0.008,0,0.005-0.008-0.007
			c-0.012-0.015-0.014-0.019-0.039-0.024c-0.045-0.012-0.045-0.013-0.024-0.032c0.022-0.021,0.03-0.034,0.022-0.037
			c-0.006-0.003,0.017-0.041,0.024-0.04c0.003,0.001,0-0.005-0.002-0.011c-0.005-0.011-0.003-0.013,0.002-0.014
			c0.005,0,0.009-0.006,0.009-0.012c0-0.007,0.004-0.011,0.012-0.013c0.018-0.002,0.095,0.011,0.098,0.017
			c0.002,0.004,0.008,0.005,0.014,0.007c0.006,0.001,0.01,0.001,0.008-0.003c-0.002-0.001,0.019,0.001,0.041,0.005
			c0.039,0.009,0.041,0.009,0.039,0c-0.002-0.007,0-0.009,0.006-0.006c0.022,0.009,0.047,0.018,0.053,0.017
			c0.003,0,0.01,0.002,0.018,0.005c0.012,0.005,0.012,0.007,0.008,0.011c-0.005,0.005-0.002,0.011,0.016,0.013
			c0.041,0.008,0.052,0.009,0.052,0.005c0-0.002,0.009-0.002,0.017,0.001c0.008,0.002,0.02,0.004,0.031,0.003
			c0.018,0,0.012-0.006-0.023-0.021c-0.025-0.009-0.028-0.015-0.016-0.034c0.002-0.005,0.004-0.017,0.004-0.025
			c0.002-0.014,0.003-0.014,0.023-0.012c0.011,0.002,0.023,0.004,0.032,0.005c0.003,0.001,0.014,0.003,0.024,0.005
			c0.008,0.002,0.014,0.006,0.014,0.009c0,0.009,0.019,0.029,0.027,0.027c0.004-0.001,0.008,0.004,0.016,0.011
			c0.006,0.006,0.009,0.009,0.011,0.005c0-0.004,0-0.004,0.004-0.003c0.002,0.003,0.005-0.002,0.009-0.009
			c0.006-0.01,0.01-0.014,0.021-0.012c0.013,0.002,0.023-0.006,0.009-0.008c-0.002,0.001-0.012-0.007-0.018-0.015
			c-0.016-0.014-0.015-0.014-0.004-0.015s0.028,0.006,0.024,0.011c-0.004,0.003,0.022,0.038,0.032,0.039
			c0.011,0.001,0.005,0.016-0.008,0.022c-0.01,0.004-0.012,0.01-0.01,0.017c0,0.007,0,0.013-0.002,0.016
			c-0.005,0.003-0.007,0.012-0.005,0.018c0.005,0.016-0.004,0.033-0.015,0.032c-0.005-0.001-0.01,0-0.01,0.002
			c0,0.003-0.004,0.003-0.016,0.003c-0.013-0.003-0.013-0.003,0.002,0.01c0.008,0.011,0.012,0.018,0.01,0.024
			c-0.002,0.018,0.024,0.051,0.086,0.105c0.015,0.013,0.035,0.032,0.049,0.044c0.013,0.011,0.031,0.028,0.046,0.039
			c0.013,0.012,0.028,0.025,0.036,0.033c0.006,0.007,0.018,0.019,0.027,0.025c0.004,0.005,0.01,0.009,0.015,0.013
			c0.002-0.006,0.006-0.009,0.009-0.013c0.01-0.012,0.008-0.031-0.007-0.043c-0.009-0.007-0.009-0.019,0-0.021
			c0.005-0.001,0.014,0.001,0.021,0.003c0.01,0.003,0.008,0-0.011-0.016c-0.021-0.017-0.025-0.022-0.021-0.029
			c0.003-0.007,0.005-0.007,0.021-0.003c0.016,0.006,0.02,0.005,0.018-0.001c-0.001-0.005-0.009-0.015-0.022-0.023
			c-0.033-0.024-0.044-0.052-0.017-0.036c0.008,0.004,0.016,0.006,0.017,0.005c0.006-0.003-0.018-0.027-0.028-0.03
			c-0.012-0.002-0.039-0.026-0.039-0.034c0-0.01-0.018-0.02-0.033-0.02c-0.018,0-0.041-0.023-0.035-0.038
			c0.002-0.009,0-0.015-0.012-0.027c-0.013-0.012-0.013-0.015-0.002-0.011c0.002,0,0.01,0,0.018-0.001
			c0.005-0.001,0.009-0.001,0.007,0.001c-0.002,0.002,0,0.006,0.004,0.007c0.009,0.004,0.017,0,0.013-0.007
			c-0.008-0.01,0.011-0.004,0.022,0.007c0.013,0.014,0.021,0.014,0.021,0.002c0-0.016,0.049-0.007,0.09,0.016
			c0.031,0.016,0.037,0.018,0.027,0.005c-0.002-0.006-0.002-0.01,0.01-0.016c0.006-0.004,0.014-0.011,0.016-0.017
			c0.002-0.006,0.009-0.01,0.018-0.011c0.01,0,0.018-0.002,0.018-0.003c0-0.002,0.002-0.004,0.007-0.003
			c0.004,0,0.011-0.001,0.015-0.004c0.006-0.005,0.028,0,0.069,0.016c0.012,0.005,0.016,0.005,0.016-0.002
			c0-0.005-0.008-0.011-0.013-0.014s-0.015-0.009-0.021-0.012c-0.002-0.004-0.012-0.01-0.02-0.014
			c-0.021-0.01-0.08-0.049-0.08-0.052c0-0.002,0.006-0.007,0.01-0.011c0.014-0.008,0.006-0.021-0.021-0.032
			c-0.012-0.006-0.021-0.011-0.018-0.013c0-0.002,0.015,0.004,0.032,0.015c0.024,0.015,0.037,0.02,0.067,0.026
			c0.031,0.007,0.042,0.012,0.063,0.025c0.015,0.009,0.032,0.019,0.039,0.02c0.005,0.002,0.024,0.009,0.043,0.017
			c0.03,0.014,0.032,0.014,0.037,0.009c0.005-0.004,0.005-0.004,0.004,0.002c-0.004,0.007,0.008,0.012,0.022,0.008
			C51.658,25.159,51.656,25.158,51.646,25.153z M46.084,24.53c-0.004-0.001-0.002-0.003,0.004-0.003
			c0.008-0.001,0.01,0.001,0.008,0.003C46.094,24.531,46.088,24.531,46.084,24.53z M46.16,24.5c-0.004,0-0.004-0.004,0-0.008
			c0.005-0.004,0.007-0.008,0.003-0.008c-0.003,0,0.002-0.005,0.009-0.01c0.011-0.012,0.021-0.013,0.021-0.004
			C46.193,24.476,46.167,24.5,46.16,24.5z M45.99,24.974c-0.002,0-0.002-0.003,0.002-0.007c0.007-0.009,0.014-0.007,0.007,0.002
			C45.998,24.971,45.994,24.974,45.99,24.974z M46.68,25.612c0.008,0.003,0.008,0.005,0,0.005c-0.006,0-0.01-0.004-0.013-0.005
			C46.663,25.606,46.67,25.607,46.68,25.612z M46.656,25.64c0.002,0.003,0.004,0.007,0,0.011c0,0.004-0.002,0.003-0.002-0.003
			C46.652,25.642,46.654,25.638,46.656,25.64z M46.639,25.627c0.006,0,0.011,0.002,0.006,0.002c0,0.002-0.004,0.003-0.008,0.002
			C46.633,25.63,46.633,25.627,46.639,25.627z M46.43,24.257c0.002,0,0.006,0.003,0.006,0.006c0,0.002-0.004,0.004-0.006,0.001
			c-0.004-0.001-0.008-0.004-0.008-0.005C46.422,24.258,46.426,24.258,46.43,24.257z M46.385,24.271c0,0.002-0.006,0.006-0.01,0.006
			c-0.009,0-0.009-0.002-0.002-0.006C46.383,24.265,46.385,24.265,46.385,24.271z M46.362,24.299c0.007,0,0.009,0.001,0.006,0.003
			c-0.004,0.001-0.009,0.001-0.013,0C46.352,24.301,46.354,24.299,46.362,24.299z M46.327,24.307c0.002,0.004,0,0.006-0.002,0.004
			c-0.004-0.001-0.005-0.006-0.005-0.008C46.32,24.296,46.323,24.297,46.327,24.307z M46.197,24.857
			c0.005,0.002,0.011,0.005,0.011,0.007c0,0.003-0.004,0.007-0.008,0.008c-0.003,0.001-0.007,0.001-0.005-0.002
			s0.002-0.005-0.002-0.005c-0.004,0-0.008-0.002-0.008-0.005S46.191,24.856,46.197,24.857z M46.167,24.885
			c-0.019-0.008-0.019-0.017,0-0.011c0.01,0.002,0.014,0.002,0.014-0.001s0.002-0.002,0.008,0.002
			C46.2,24.887,46.184,24.895,46.167,24.885z M46.528,24.869c-0.004,0.003-0.007,0.005-0.007,0.002c0-0.008,0.007-0.015,0.01-0.01
			C46.531,24.862,46.53,24.867,46.528,24.869z M46.572,25.638c-0.002,0-0.005,0-0.007,0c-0.005,0.002-0.005-0.006-0.007-0.017
			c0-0.018,0-0.018,0.007-0.008c0.005,0.006,0.011,0.01,0.013,0.01c0.003-0.001,0.005,0.002,0.003,0.006
			C46.578,25.633,46.576,25.638,46.572,25.638z M46.741,24.664c-0.009,0-0.014,0.003-0.014,0.008c0,0.006-0.027,0.006-0.078-0.003
			c-0.027-0.004-0.088,0.023-0.071,0.031c0.009,0.004,0.007,0.012-0.002,0.008c-0.004-0.002-0.009-0.006-0.009-0.01
			c0-0.005-0.002-0.008-0.006-0.007c-0.012,0-0.03,0.008-0.02,0.009c0.008,0.001,0.006,0.012-0.004,0.012
			c-0.008-0.001-0.008,0.002-0.004,0.014c0.004,0.016,0.008,0.022,0.012,0.013c0.002-0.003,0.006-0.007,0.012-0.006
			c0.006,0,0.01-0.002,0.01-0.004c0-0.006,0.021-0.01,0.023-0.005c0.004,0.003,0.008,0.005,0.014,0.005
			c0.016,0,0.013,0.053-0.006,0.054c0,0-0.01-0.008-0.021-0.02c-0.018-0.015-0.022-0.016-0.036-0.012
			c-0.02,0.008-0.033,0.04-0.029,0.073c0.002,0.026-0.003,0.043-0.024,0.042c-0.004,0-0.009,0.004-0.009,0.008
			c0,0.005-0.006,0.011-0.014,0.015c-0.013,0.006-0.031,0.002-0.026-0.007c0.01-0.015-0.065-0.01-0.08,0.007
			c-0.001,0.002-0.018,0.008-0.037,0.012c-0.035,0.008-0.05,0.004-0.05-0.013c0-0.015-0.015-0.012-0.064,0.009
			c-0.01,0.005-0.021,0.007-0.023,0.007c-0.004-0.005,0.018-0.024,0.025-0.022c0,0.002,0.004,0,0.006-0.005s0.008-0.008,0.014-0.007
			c0.005,0,0.005-0.003,0.005-0.005c-0.003-0.004-0.007-0.004-0.011-0.003c-0.004,0.003-0.004,0.002,0-0.004
			c0.004-0.004,0.006-0.009,0.004-0.011c-0.002-0.002,0-0.003,0.004-0.003c0.003,0,0.005-0.005,0.007-0.01
			c0-0.007,0.002-0.005,0.003,0.004c0.004,0.012,0.007,0.013,0.027,0.013c0.018,0,0.023-0.002,0.025-0.01
			c0.002-0.016,0.018-0.024,0.039-0.024c0.016,0,0.021-0.003,0.021-0.01c0-0.008,0-0.008,0.006-0.002
			c0.001,0.004,0.005,0.007,0.007,0.005c0.006-0.006,0.018-0.044,0.016-0.047c-0.002-0.002-0.004,0-0.006,0.003
			c-0.004,0.012-0.014,0.008-0.01-0.006c0.002-0.009,0.006-0.028,0.008-0.047c0.002-0.002,0.006-0.005,0.012-0.006
			c0.008-0.003,0.014-0.004,0.017-0.005c0,0,0.008-0.002,0.015-0.004c0.008-0.002,0.012-0.008,0.012-0.012
			c0-0.004,0.005-0.012,0.009-0.014c0.007-0.006,0.007-0.009,0-0.019c-0.005-0.014-0.009-0.016-0.037-0.03
			c-0.017-0.009-0.025-0.02-0.017-0.036c0.004-0.004,0.004-0.015,0.004-0.023c0-0.009,0.006-0.016,0.016-0.023
			c0.008-0.005,0.016-0.012,0.016-0.015c0-0.005,0.037-0.022,0.049-0.022c0.002,0,0.014-0.009,0.023-0.019
			c0.014-0.014,0.018-0.02,0.014-0.023c-0.006-0.004-0.004-0.009,0.004-0.021c0.008-0.009,0.016-0.016,0.017-0.014
			c0.004,0.002,0.006,0,0.006-0.002c0-0.007,0.041-0.007,0.069-0.002c0.009,0.004,0.016,0.008,0.016,0.014
			c0,0.007-0.003,0.009-0.012,0.007c-0.008-0.002-0.012-0.001-0.01,0.004c0.004,0.008-0.028,0.043-0.041,0.042
			c-0.006,0-0.008,0.002-0.008,0.004c0,0.007-0.031,0.02-0.039,0.018c-0.004-0.001-0.008-0.001-0.013,0.001
			c0,0.004,0.003,0.008,0.007,0.009c0.008,0.004,0.008,0.005,0.002,0.011c-0.009,0.005-0.009,0.011-0.004,0.018
			c0.004,0.007,0.006,0.016,0.01,0.021c0.002,0.005,0.004,0.022,0.004,0.037c-0.002,0.023,0,0.027,0.006,0.026
			c0.006,0,0.009,0.003,0.009,0.006c-0.002,0.004,0.008,0.008,0.017,0.01c0.01,0.002,0.021,0.007,0.021,0.01
			c0.001,0.004,0.017,0.002,0.041-0.003c0.023-0.006,0.042-0.008,0.046-0.008c0.006,0.002,0.011,0,0.014-0.001
			c0-0.004,0.02-0.006,0.041-0.008c0.029-0.002,0.038,0,0.049,0.008C46.773,24.661,46.773,24.666,46.741,24.664z M47.178,25.245
			c-0.009,0.005-0.019,0.008-0.021,0.007c-0.004-0.001-0.005-0.001-0.005,0.004s0.005,0.01,0.012,0.012
			c0.009,0.004,0.011,0.005,0.005,0.009c-0.006,0.003-0.012,0.011-0.014,0.019c-0.003,0.013-0.005,0.014-0.035,0.011
			c-0.015-0.002-0.033-0.001-0.036,0.001c-0.003,0.003-0.009-0.001-0.016-0.01c-0.004-0.004-0.007-0.01-0.011-0.014
			c0.006-0.005,0.009-0.01,0.015-0.015c0.009-0.008,0.02-0.015,0.03-0.017c0.007-0.001,0.018-0.002,0.023-0.004
			c0.016-0.006,0.041-0.012,0.059-0.011C47.195,25.237,47.193,25.239,47.178,25.245z M47.269,25.383
			c-0.006-0.004-0.01-0.005-0.016-0.006c0.006,0,0.012,0.002,0.019,0.006c0.009,0.006,0.024,0.013,0.032,0.016
			C47.296,25.396,47.281,25.39,47.269,25.383z M46.994,27.781c-0.007-0.004-0.012-0.009-0.009-0.013c0-0.005-0.002-0.013-0.007-0.02
			c-0.004-0.007-0.008-0.015-0.008-0.018c0-0.005-0.006-0.016-0.017-0.026c-0.007-0.01-0.016-0.022-0.019-0.028
			c0-0.004,0-0.016-0.002-0.024c-0.002-0.009-0.002-0.036-0.004-0.059c0-0.039,0-0.041,0.006-0.027
			c0.005,0.009,0.008,0.022,0.008,0.03s0.004,0.017,0.007,0.018c0.002,0.002,0.004,0.008,0.002,0.012
			c-0.002,0.005,0,0.014,0.002,0.02c0.007,0.01,0.007,0.012,0,0.014c-0.011,0.003-0.005,0.022,0.009,0.026
			c0.012,0.005,0.02,0.016,0.02,0.033c0,0.007,0.005,0.022,0.012,0.037C47.012,27.788,47.012,27.788,46.994,27.781z M47.143,27.874
			c0,0.013,0.005,0.027,0.007,0.03c0.009,0.007,0.011,0.03,0.002,0.034c-0.009,0.005-0.002,0.077,0.005,0.081
			c0.004,0,0.006,0.004,0.006,0.006c0,0.004-0.002,0.004-0.006,0.003c-0.002-0.002-0.005-0.002-0.005,0.001
			s-0.002,0.005-0.007,0.006c-0.002-0.001-0.002-0.006-0.002-0.011c0.002-0.007,0-0.015,0-0.019
			c-0.006-0.005-0.014-0.046-0.014-0.062c0.004-0.065,0.004-0.082-0.004-0.094c-0.003-0.007-0.007-0.015-0.007-0.017
			c0-0.008,0.011-0.003,0.019,0.006C47.139,27.844,47.143,27.859,47.143,27.874z M47.067,27.39c0.009-0.005,0.021-0.01,0.025-0.011
			c0.004-0.007,0.027,0.002,0.037,0.014c0.004,0.005,0.004,0.01,0.002,0.015c-0.004,0.004-0.004,0.008-0.004,0.012
			c0.004,0.006-0.008,0.032-0.014,0.032c-0.003,0-0.005,0.004-0.005,0.006c0,0.003-0.005,0.01-0.009,0.013
			c-0.01,0.007-0.01,0.009,0,0.015c0.014,0.009,0.004,0.013-0.021,0.009c-0.014-0.003-0.025-0.002-0.029,0
			c-0.012,0.009-0.014-0.004-0.012-0.041C47.043,27.398,47.043,27.396,47.067,27.39z M47.113,26.091
			c-0.001,0-0.003-0.005-0.001-0.008c0.001-0.004,0.003-0.009,0.005-0.009s0.002,0.005,0.002,0.009
			C47.119,26.088,47.117,26.093,47.113,26.091z M47.403,25.432c0.007-0.001,0.017-0.002,0.025-0.003c0,0,0,0,0.002,0.001
			C47.42,25.43,47.411,25.432,47.403,25.432z M47.435,26.638c-0.006,0-0.008-0.002-0.006-0.004c0.002-0.002,0.004-0.004,0.006-0.004
			c0,0,0.002,0.002,0.004,0.003C47.441,26.635,47.439,26.637,47.435,26.638z M47.771,25.724c-0.044,0.008-0.064,0.004-0.095-0.021
			c-0.009-0.009-0.024-0.017-0.029-0.017c-0.015-0.001-0.03-0.018-0.034-0.04c-0.002-0.013,0-0.022,0.004-0.024
			c0.005-0.002,0.007-0.014,0.009-0.029c0-0.024,0-0.026,0.014-0.025c0.009,0.001,0.016-0.001,0.016-0.005
			c0-0.001-0.004-0.002-0.009-0.003c-0.005,0-0.007,0-0.009,0s-0.006-0.001-0.012-0.002c0.01,0.003,0.016,0.004,0.021,0.002
			c0.002-0.001,0.003-0.002,0.002-0.005c0-0.004-0.007-0.007-0.011-0.007c-0.006,0-0.034-0.026-0.06-0.056
			c-0.045-0.049-0.052-0.06-0.052-0.075c0-0.017-0.013-0.039-0.032-0.048c-0.006-0.001-0.006-0.003-0.002-0.006
			c0.004-0.003,0.009-0.011,0.011-0.019c0.006-0.015,0.034-0.033,0.045-0.028c0.002,0.001,0.006,0,0.006-0.004
			c0-0.012,0.015-0.022,0.047-0.038c0.016-0.006,0.029-0.012,0.033-0.013c-0.002-0.003-0.006-0.009-0.012-0.014
			c0.008,0.007,0.014,0.012,0.012,0.013c0,0.009,0.016,0.015,0.037,0.012c0.011-0.001,0.017-0.001,0.022,0.011
			c0.009,0.013,0.017,0.062,0.009,0.058c-0.002,0-0.014-0.004-0.029-0.005c-0.024-0.004-0.028-0.003-0.033,0.009
			c-0.002,0.006-0.006,0.015-0.002,0.017c0.002,0.003-0.006,0.004-0.016,0.004c-0.014-0.001-0.016,0.001-0.011,0.01
			c0.004,0.006,0.009,0.011,0.015,0.011c0.004,0,0.012,0.007,0.016,0.017c0.007,0.008,0.014,0.015,0.016,0.015
			c0.002,0.001,0.003,0.007,0.003,0.013s0.002,0.009,0.009,0.006c0-0.002,0.006,0,0.01,0.005c0.004,0.005,0.013,0.01,0.021,0.012
			c0.019,0.002,0.023,0.008,0.019,0.031c-0.002,0.011-0.002,0.021,0.002,0.021c0.008,0,0.024,0.031,0.021,0.044
			c-0.002,0.014,0.007,0.038,0.019,0.036c0.004-0.001,0.006,0.003,0.004,0.015c0,0.013,0,0.016,0.009,0.014
			c0.01-0.004,0.022,0.013,0.028,0.05c0.002,0.019,0.006,0.038,0.008,0.042C47.813,25.715,47.805,25.719,47.771,25.724z
			 M47.795,25.549c-0.007,0.008-0.009,0.008-0.01,0.004c-0.002-0.004-0.01-0.007-0.016-0.007c-0.013,0-0.023-0.015-0.023-0.033
			c0-0.021,0.019-0.027,0.031-0.007c0.004,0.007,0.013,0.02,0.024,0.027c0.01,0.009,0.014,0.015,0.01,0.012
			C47.807,25.542,47.799,25.546,47.795,25.549z M47.727,24.469c0.047-0.019,0.091-0.047,0.091-0.063
			C47.818,24.424,47.774,24.45,47.727,24.469z M47.909,25.364c0-0.005,0.004,0,0.009,0.011c0.004,0.011,0.008,0.022,0.004,0.023
			C47.918,25.401,47.909,25.372,47.909,25.364z M47.926,25.366c-0.009-0.019-0.009-0.019,0-0.019c0.004,0,0.01,0.005,0.012,0.011
			C47.943,25.375,47.934,25.382,47.926,25.366z M47.986,25.398c-0.006,0.011-0.012,0.014-0.018,0.012
			c-0.002-0.002-0.011-0.003-0.016-0.003c-0.006-0.001-0.008-0.008-0.008-0.029c0-0.016-0.001-0.031-0.005-0.032
			c-0.005-0.002-0.005-0.007,0.002-0.01c0.007-0.009,0.014-0.01,0.014-0.003c0,0.003-0.002,0.005-0.005,0.005
			c-0.004-0.001-0.004,0.002-0.004,0.005c0.004,0.003,0.009,0.004,0.016,0.003c0.006-0.002,0.01,0,0.012,0.011
			c0.004,0.007,0.008,0.015,0.012,0.019C47.991,25.383,47.991,25.385,47.986,25.398z M47.987,25.312
			c-0.003,0-0.001-0.002,0.004-0.002s0.009,0.002,0.007,0.003C47.996,25.315,47.991,25.315,47.987,25.312z M48.149,24.411
			c-0.008-0.007-0.014-0.012-0.02-0.013c-0.004,0-0.008-0.002-0.01-0.004c-0.005-0.009,0.027,0.003,0.037,0.013
			C48.17,24.424,48.164,24.427,48.149,24.411z M48.497,25.341c-0.004,0.001-0.009,0.006-0.009,0.01c0,0.005-0.004,0.007-0.009,0.007
			c-0.004-0.001-0.006,0.004-0.006,0.015c0,0.013-0.002,0.015-0.01,0.009c-0.009-0.008-0.011-0.031-0.002-0.031
			c0.004,0.001,0.006,0,0.004-0.002c-0.006-0.005,0.013-0.02,0.023-0.02c0.005,0,0.01,0.002,0.01,0.005
			C48.502,25.338,48.498,25.341,48.497,25.341z M48.517,25.336c-0.004-0.002-0.002-0.002,0.006-0.002
			c0.009,0.001,0.011,0.002,0.007,0.003C48.527,25.338,48.521,25.338,48.517,25.336z M48.596,25.351
			c-0.022-0.005-0.026-0.009-0.01-0.008c0.004,0,0.014,0.003,0.018,0.006C48.61,25.354,48.607,25.354,48.596,25.351z M48.625,25.354
			c-0.006-0.001-0.011-0.003-0.011-0.005s0.005-0.003,0.011-0.003c0.006,0.001,0.012,0.003,0.012,0.005
			C48.637,25.356,48.631,25.357,48.625,25.354z M48.982,24.331c-0.009-0.003-0.019-0.007-0.019-0.009
			c0-0.006,0.015-0.004,0.027,0.004C48.995,24.33,48.993,24.331,48.982,24.331z M49.157,26.536c0,0-0.004-0.002-0.006-0.005
			c-0.001-0.003,0-0.004,0.002-0.004c0.004,0,0.007,0.001,0.007,0.004S49.159,26.536,49.157,26.536z M49.529,25.182
			c-0.002,0.005-0.011,0.011-0.027,0.014c-0.02,0.004-0.022,0.005-0.02,0.016c0,0.012,0,0.014-0.021,0.016
			c-0.016,0.003-0.023,0.002-0.035-0.003c-0.013-0.005-0.013-0.006,0.004-0.004c0.013,0.002,0.033-0.008,0.033-0.016
			c0-0.002,0.006-0.008,0.014-0.013c0.005-0.004,0.009-0.012,0.007-0.015c-0.002-0.004,0-0.008,0.004-0.01
			c0.019-0.008,0.023-0.045,0.011-0.083c-0.004-0.014,0.012-0.008,0.018,0.007c0.023,0.047,0.026,0.057,0.019,0.064
			c-0.005,0.007-0.007,0.007-0.004-0.001c0.002-0.003,0-0.007,0-0.007c-0.005-0.001-0.007,0.003-0.007,0.009s0.004,0.01,0.009,0.01
			c0.006,0,0.007,0.002,0.004,0.004C49.534,25.172,49.531,25.177,49.529,25.182z M50.508,27.685c0.004,0.002,0.01,0.004,0.01,0.005
			c0,0-0.006,0.002-0.01,0.001c-0.004,0.001-0.009-0.001-0.009-0.003C50.499,27.687,50.504,27.685,50.508,27.685z M49.931,27.317
			c-0.002,0.005,0,0.006,0.005,0.003c0.003-0.001,0.004,0.003,0.005,0.005c-0.002-0.001-0.005,0-0.005-0.001
			c0-0.004-0.005-0.006-0.01-0.008C49.927,27.317,49.931,27.315,49.931,27.317z M49.899,27.299c-0.003-0.001,0-0.004-0.01-0.004
			c-0.004-0.001-0.008-0.006-0.008-0.01c0-0.002-0.003-0.002-0.003-0.003C49.888,27.289,49.896,27.294,49.899,27.299z
			 M49.922,26.927c0,0.001-0.005,0-0.005-0.004c0-0.009,0.005-0.01,0.009-0.004C49.928,26.922,49.926,26.924,49.922,26.927z
			 M51.348,25.085c0.004,0.001,0.012,0.004,0.016,0.007c0.002,0.007-0.01,0.004-0.02-0.004
			C51.336,25.083,51.338,25.083,51.348,25.085z M51.457,25.044c-0.01-0.006-0.011-0.008-0.004-0.007
			c0.004,0.001,0.008,0.004,0.01,0.006C51.465,25.047,51.463,25.047,51.457,25.044z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M49.854,39.169c-0.001,0-0.003,0.001-0.004,0.001c-0.001,0-0.002-0.001-0.003-0.001c-0.435,0.178-0.817,0.455-1.122,0.805
			c0.455,0.524,0.731,1.207,0.731,1.955c0,0.748-0.276,1.432-0.731,1.955c0.305,0.351,0.687,0.628,1.123,0.807
			c0.001,0,0.002,0,0.002-0.001c0.001,0,0.002,0,0.002,0.001c1.087-0.444,1.854-1.512,1.854-2.762
			C51.706,40.682,50.94,39.616,49.854,39.169z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M48.722,39.974c-0.302-0.35-0.686-0.624-1.12-0.805c0,0-0.001,0-0.002,0c-0.002-0.001-0.004-0.001-0.006-0.002
			c-1.086,0.445-1.852,1.514-1.852,2.761c0,1.25,0.768,2.317,1.855,2.762c0.438-0.178,0.822-0.456,1.125-0.807
			c-0.452-0.522-0.73-1.205-0.73-1.954C47.992,41.182,48.269,40.499,48.722,39.974z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M45.53,23.841c-0.044,0-0.062,0.001-0.066,0.005c-0.009,0.008-0.034,0.004-0.028-0.004c0.002-0.002,0-0.004-0.006-0.004
			c-0.003,0-0.007,0.003-0.007,0.006c0.002,0.003-0.002,0.008-0.008,0.009c-0.006,0.001-0.009-0.001-0.009-0.004
			c0.001-0.005-0.004-0.008-0.009-0.008c-0.006-0.002-0.01-0.001-0.006,0.001c0.002,0.002-0.005,0.009-0.021,0.015
			c-0.03,0.013-0.034,0.013-0.021,0.003c0.008-0.004,0.01-0.011,0.008-0.014c-0.002-0.004,0-0.007,0.003-0.008
			c0.006-0.002,0.014-0.005,0.02-0.006c0.014-0.006,0.006-0.007-0.032-0.01c-0.021-0.002-0.048-0.006-0.058-0.009
			c-0.024-0.008-0.19-0.002-0.285,0.009c-0.041,0.004-0.106,0.013-0.145,0.016c-0.039,0.005-0.078,0.012-0.087,0.014
			c-0.007,0.002-0.02,0.004-0.028,0.004c-0.006,0-0.009,0-0.015,0c0.006,0.004,0.009,0.008,0.015,0.01
			c0.012,0.007,0.012,0.009,0.004,0.01c-0.006,0-0.012-0.001-0.013-0.005c-0.002-0.002-0.008-0.003-0.015,0
			c-0.006,0-0.014,0-0.016-0.005c-0.012-0.007-0.02-0.007-0.012,0c0,0.003-0.008,0.006-0.018,0.006
			c-0.018,0.001-0.022,0.004-0.018,0.009c0.004,0.008,0.002,0.008-0.009,0.006c-0.029-0.006-0.048-0.004-0.054,0.005
			c-0.008,0.01-0.008,0.01-0.008,0c0-0.009-0.006-0.009-0.061-0.001c-0.033,0.006-0.074,0.013-0.095,0.016
			c-0.018,0.004-0.03,0.005-0.026,0.003c0.011-0.008,0.006-0.014-0.014-0.013c-0.011,0.002-0.023,0-0.027-0.003
			c-0.004-0.003-0.059,0.001-0.187,0.013c-0.184,0.02-0.246,0.03-0.283,0.047c-0.017,0.007-0.026,0.009-0.043,0.008
			c-0.017-0.002-0.034,0-0.052,0.007c-0.015,0.004-0.049,0.013-0.071,0.017c-0.06,0.011-0.097,0.021-0.097,0.026
			c0,0.003,0.005,0.004,0.012,0.004c0.005-0.001,0.009,0.001,0.009,0.005c0,0.004,0.009,0.008,0.019,0.009
			c0.013,0.003,0.015,0.003,0.007,0.005c-0.007,0.002-0.015,0.007-0.019,0.009c-0.007,0.007-0.007,0.006-0.005,0
			c0.003-0.003-0.006-0.007-0.011-0.007s-0.017-0.004-0.023-0.009c-0.007-0.006-0.016-0.006-0.035-0.004
			c-0.015,0.002-0.027,0.005-0.027,0.008c-0.002,0.004-0.011,0.007-0.023,0.009c-0.011,0.001-0.018,0.004-0.018,0.007
			c0,0.003-0.006,0.005-0.014,0.006c-0.006,0.002-0.02,0.008-0.034,0.014c-0.02,0.011-0.023,0.016-0.023,0.025
			c0,0.01,0.003,0.013,0.017,0.012c0.013-0.001,0.013,0.001,0.008,0.003c-0.01,0.005-0.01,0.006,0,0.016
			c0.01,0.011,0.01,0.011,0.08,0.002c0.072-0.009,0.109-0.007,0.087,0.004c-0.007,0.004-0.011,0.003-0.013,0
			c-0.002-0.007-0.041-0.004-0.056,0.004c-0.006,0.002-0.008,0.007-0.006,0.013c0.002,0.008,0,0.009-0.009,0.008
			c-0.006,0-0.023,0.005-0.041,0.01c-0.015,0.007-0.028,0.009-0.03,0.008c-0.003-0.003-0.024,0.009-0.024,0.013
			c0,0.003,0.004,0.003,0.008,0c0.004-0.003,0.004-0.002,0.002,0.003c-0.004,0.004-0.008,0.004-0.016-0.001
			c-0.007-0.005-0.013-0.005-0.033,0.001c-0.013,0.003-0.022,0.004-0.026,0.002c-0.002-0.004-0.009-0.005-0.017-0.004
			c-0.008,0.002-0.015,0-0.017-0.001c-0.002-0.005-0.051,0.006-0.055,0.012c-0.002,0.002-0.007,0.006-0.011,0.008
			c-0.006,0.002-0.006,0.004,0.002,0.002c0.009-0.001,0.018,0.021,0.013,0.026c-0.002,0.003-0.02,0.008-0.04,0.011
			c-0.012,0.004-0.023,0.007-0.035,0.009c0.005-0.005,0.01-0.011,0.016-0.016c0.014-0.014,0.014-0.016,0.006-0.022
			c-0.006-0.006-0.01-0.006-0.02,0.001c-0.013,0.009-0.02,0.009-0.013,0.003c0.005-0.009-0.032-0.002-0.065,0.012
			c-0.015,0.007-0.027,0.014-0.027,0.019c0,0.005-0.002,0.006-0.004,0.006c-0.004-0.003-0.004-0.006-0.004-0.008
			c0.006-0.008-0.021-0.016-0.033-0.012c-0.012,0.006-0.03,0.027-0.021,0.026c0.002-0.001,0.004,0.001,0.002,0.002
			c-0.007,0.006,0.017,0.013,0.038,0.01c0.018-0.002,0.022-0.002,0.02,0.006c0,0.008,0.006,0.008,0.039,0.002
			c0.028-0.006,0.041-0.01,0.045-0.017c0.006-0.005,0.011-0.007,0.018-0.005c0.008,0.001,0.008,0.003,0.004,0.004
			c-0.005,0.003-0.004,0.006,0.004,0.009c0.006,0.004,0.006,0.007,0.001,0.007c-0.009,0.002-0.009,0.012,0.003,0.01
			c0.004,0,0.008-0.003,0.008-0.005c0-0.003,0.009-0.005,0.023-0.005c0.025-0.001,0.03-0.005,0.043-0.017
			c0.012-0.014,0.012-0.018,0.005-0.021c-0.005-0.002-0.005-0.005,0-0.008c0.005,0,0.007-0.005,0.007-0.01
			c0-0.01,0.047-0.016,0.051-0.006c0.002,0.006,0.004,0.008,0.002,0.009c-0.002,0.001-0.01,0.006-0.017,0.009
			c-0.013,0.006-0.025,0.029-0.016,0.029c0.016,0.001,0.043-0.002,0.124-0.011c0.069-0.005,0.097-0.011,0.102-0.015
			c0.006-0.005,0.01-0.005,0.012-0.002c0.006,0.006,0.063-0.004,0.093-0.016c0.016-0.007,0.032-0.02,0.032-0.03
			c0-0.015-0.075-0.009-0.146,0.012c-0.025,0.007-0.045,0.011-0.046,0.01c-0.004-0.001-0.015-0.002-0.03-0.001
			c-0.022,0.002-0.045-0.006-0.024-0.009c0.008-0.001,0.013-0.004,0.017-0.005c0.001-0.004,0.031-0.009,0.069-0.014
			c0.038-0.005,0.082-0.011,0.1-0.014c0.018-0.004,0.037-0.006,0.043-0.007c0.01,0,0.01,0,0,0.006
			c-0.009,0.004-0.001,0.004,0.021-0.001c0.024-0.004,0.028-0.005,0.02,0c-0.016,0.009-0.016,0.012,0.002,0.011
			c0.01-0.002,0.016-0.005,0.018-0.013s0.006-0.012,0.014-0.013c0.02-0.002,0.047-0.016,0.047-0.024
			c0-0.005-0.004-0.006-0.016-0.003c-0.008,0.004-0.012,0.003-0.008-0.001c0-0.002,0.006-0.006,0.013-0.006
			c0.007-0.001,0.019-0.006,0.028-0.012c0.008-0.005,0.027-0.013,0.043-0.014c0.037-0.007,0.047-0.014,0.039-0.018
			c-0.007-0.002-0.002-0.003,0.008-0.002c0.012,0.001,0.019-0.001,0.021-0.005c0.003-0.003,0.009-0.006,0.015-0.006
			c0.004-0.001,0.014-0.008,0.021-0.015c0.011-0.014,0.024-0.018,0.086-0.031c0.015-0.003,0.035-0.011,0.044-0.016
			c0.009-0.005,0.021-0.011,0.025-0.012c0.112-0.031,0.141-0.041,0.135-0.044c-0.003-0.003,0.002-0.004,0.013-0.002
			c0.01,0,0.023-0.002,0.026-0.005c0.008-0.005,0.084-0.027,0.088-0.024c0.002,0.002-0.002,0.007-0.006,0.012
			c-0.008,0.007-0.019,0.01-0.039,0.012c-0.041,0.004-0.148,0.036-0.152,0.044c-0.001,0.004,0.005,0.006,0.021,0.004
			c0.011,0,0.025,0,0.025,0.003c0,0.009-0.025,0.019-0.076,0.029c-0.076,0.014-0.16,0.036-0.176,0.046
			c-0.006,0.004-0.011,0.007-0.015,0.01c0.004,0.001,0.009,0.002,0.015,0.003c0.005,0.003,0.017,0.004,0.023,0.003
			c0.005,0,0.01,0.001,0.012,0.003s-0.009,0.004-0.025,0.006c-0.015,0.001-0.03,0.005-0.033,0.006c0,0.004,0.005,0.005,0.012,0.005
			c0.009-0.002,0.014,0.001,0.012,0.005c-0.006,0.012,0.036,0.031,0.068,0.031c0.015,0,0.025-0.003,0.023-0.006
			c-0.004-0.003,0-0.005,0.013-0.002c0.008,0.002,0.017,0.002,0.021,0c0.012-0.009,0.09-0.016,0.129-0.011
			c0.041,0.007,0.055,0.011,0.039,0.012c-0.014,0.001-0.008,0.016,0.006,0.017c0.014,0.003,0.014,0.003,0.004,0.011
			c-0.01,0.009-0.01,0.009,0,0.01c0.008,0.002,0.008,0.002-0.004,0.004c-0.006,0.001-0.011,0.004-0.009,0.006
			c0.003,0.003,0.007,0.003,0.013,0.001c0.008-0.002,0.006,0.001-0.004,0.009c-0.004,0.005-0.011,0.009-0.014,0.013
			c0.003,0,0.008-0.002,0.012-0.004c0.014-0.005,0.014-0.005,0.004,0.007c-0.008,0.009-0.008,0.012-0.002,0.015
			s0.006,0.004-0.004,0.005c-0.014,0.001-0.018,0.009-0.007,0.013c0.007,0.001,0.005,0.006-0.007,0.015
			c-0.014,0.011-0.016,0.014-0.008,0.023c0.004,0.006,0.01,0.006,0.025,0.003c0.011-0.002,0.02-0.002,0.022,0
			c0.002,0.003,0,0.003-0.006,0.002c-0.003-0.001-0.007,0.001-0.007,0.003c0,0.004,0.004,0.006,0.011,0.005
			c0.007-0.001,0.009-0.005,0.009-0.011c0-0.005,0-0.009,0.004-0.009c0.004,0,0.006,0.002,0.004,0.006
			c0,0.004,0.004,0.005,0.01,0.005c0.01-0.001,0.014,0.002,0.014,0.01c0.002,0.01-0.005,0.014-0.012,0.005
			c-0.002-0.006-0.062-0.001-0.066,0.006c-0.004,0.002,0,0.005,0.002,0.007c0.003,0.001,0,0.002-0.008,0.001
			c-0.01,0-0.014,0-0.012,0.003s-0.002,0.01-0.008,0.018c-0.004,0.005-0.008,0.01-0.012,0.015c0.008,0.003,0.014,0.006,0.02,0.008
			c0.014,0.007,0.02,0.007,0.045-0.002c0.034-0.011,0.04-0.019,0.019-0.027c-0.009-0.003-0.013-0.006-0.011-0.008
			c0.002-0.002,0.006,0,0.009,0.002c0.004,0.003,0.013,0.005,0.019,0.005c0.017-0.002,0.017,0.015,0.002,0.037
			c-0.01,0.014-0.012,0.014-0.035,0.014c-0.023-0.001-0.026,0.001-0.039,0.017c-0.01,0.009-0.018,0.018-0.018,0.018
			c-0.007,0.001-0.029,0.03-0.029,0.036c0,0.005,0.004,0.008,0.01,0.008c0.007-0.001,0.007,0.001-0.002,0.004
			c-0.016,0.006-0.028,0.026-0.018,0.029c0,0.002,0.002,0.009,0,0.016c-0.002,0.008,0,0.01,0.006,0.007
			c0.008-0.004,0.01-0.004,0.008,0.003c-0.002,0.004-0.002,0.007,0,0.007c0.004,0,0.004,0.009,0,0.02
			c-0.008,0.017-0.008,0.021,0,0.024c0.006,0.003,0.007,0.004,0.002,0.007c-0.01,0.006,0.002,0.032,0.011,0.032
			c0.005-0.001,0.005,0.005,0.004,0.014c-0.002,0.009-0.002,0.016,0.001,0.016c0.002,0,0.008,0.009,0.01,0.02
			c0.004,0.01,0.012,0.021,0.015,0.022c0.005,0.001,0.009,0.007,0.009,0.013c0,0.012,0.006,0.012,0.02,0.004
			c0.014-0.007,0.06,0.003,0.06,0.014c0,0.005,0.006,0.008,0.011,0.01c0.004,0.001,0.016,0.003,0.02,0.006
			c0.018,0.006,0.041-0.004,0.05-0.019c0.001-0.006,0.007-0.011,0.011-0.012c0.003,0,0.01-0.006,0.01-0.012
			c0.002-0.004,0.012-0.017,0.021-0.027c0.009-0.011,0.021-0.027,0.021-0.035c0.002-0.009,0.01-0.017,0.02-0.021
			c0.004-0.003,0.018-0.012,0.025-0.02c0.007-0.009,0.019-0.016,0.023-0.019c0.005-0.003,0.009-0.006,0.009-0.01
			c0-0.005,0.005-0.013,0.013-0.02c0.008-0.006,0.01-0.014,0.01-0.021c-0.004-0.005-0.002-0.01,0-0.009
			c0.007,0,0.037-0.017,0.033-0.021c0-0.002,0.007-0.005,0.022-0.006c0.017,0,0.025-0.003,0.021-0.008
			c-0.004-0.004-0.002-0.004,0.004-0.001c0.012,0.007,0.037,0.011,0.037,0.005c0-0.003,0.004-0.006,0.006-0.006
			c0.007,0,0.01-0.003,0.01-0.006c0-0.002,0.002-0.004,0.006-0.002c0.002,0.002,0.009,0.002,0.014-0.002
			c0-0.002,0.018-0.01,0.034-0.016c0.019-0.008,0.039-0.021,0.056-0.037c0.015-0.013,0.031-0.026,0.041-0.028
			c0.01-0.003,0.017-0.009,0.02-0.013c0.004-0.008,0.006-0.008,0.01-0.002c0.003,0.005,0.011,0.005,0.03,0.001
			c0.074-0.015,0.156-0.036,0.178-0.049c0.011-0.005-0.005-0.005-0.003-0.005c0.002,0,0.021-0.008,0.041-0.018
			c0.014-0.005,0.023-0.011,0.037-0.017c-0.01,0-0.021-0.001-0.031-0.001c-0.045-0.002-0.082-0.009-0.067-0.01
			c0.011-0.001,0.015-0.004,0.011-0.014c0-0.01,0.002-0.011,0.016-0.012c0.013-0.001,0.016,0.002,0.018,0.008
			c0,0.007,0.002,0.013,0.004,0.014c0.004,0.005,0.06,0.007,0.066,0.002c0.005-0.003,0.008-0.014,0.008-0.027
			c0.002-0.021,0-0.023-0.018-0.032c-0.017-0.006-0.017-0.007-0.004-0.005c0.015,0.003,0.035-0.009,0.022-0.015
			c-0.005-0.004-0.005-0.005,0.002-0.005s0.011-0.003,0.007-0.011c-0.001-0.002,0.007-0.002,0.008-0.004
			c0.001-0.001,0-0.001,0.002-0.002c0.001,0,0,0,0.001,0c0.005-0.006,0.007-0.012,0.025-0.012c0.023-0.001,0.035-0.021,0.017-0.025
			c-0.006,0-0.006-0.002,0.006-0.004c0.006-0.001,0.014-0.001,0.016-0.001c0.009,0.001,0.048-0.018,0.056-0.026
			c0.001-0.004,0.009-0.007,0.015-0.004c0.006,0,0.011,0,0.011-0.003c0.004-0.003,0-0.005-0.005-0.004
			c-0.004,0-0.008-0.003-0.004-0.005c0.002-0.003-0.004-0.006-0.016-0.005c-0.014,0-0.026,0.012-0.018,0.022
			c0.004,0.004-0.021,0.008-0.023,0.001c-0.005-0.001,0-0.007,0.004-0.01c0.016-0.01,0.014-0.038-0.004-0.048
			c-0.014-0.01-0.007-0.016,0.018-0.014c0.015,0.002,0.018,0.005,0.016,0.011c-0.001,0.005-0.001,0.006,0.002,0.005
			c0.004-0.004,0.006,0,0.004,0.006c-0.004,0.017,0.004,0.013,0.01-0.006c0.004-0.009,0.01-0.016,0.014-0.016
			s0.008-0.005,0.006-0.01c0-0.007-0.005-0.009-0.021-0.01c-0.009,0.001-0.02-0.001-0.022-0.003c-0.003-0.002,0-0.003,0.008-0.001
			c0.007,0.001,0.015,0,0.019-0.003c0.004-0.004,0.004-0.007-0.008-0.01c-0.008-0.002-0.015-0.007-0.019-0.01
			c-0.002-0.004-0.005-0.004-0.011,0c-0.012,0.011-0.014,0.001-0.002-0.01c0.01-0.009,0.015-0.011,0.033-0.008
			c0.019,0.001,0.023,0,0.019-0.006c-0.001-0.007,0-0.009,0.006-0.011c0.009,0,0.009,0-0.002-0.003
			c-0.013-0.006-0.005-0.009,0.028-0.014c0.011-0.003,0.024-0.007,0.026-0.009c0.001-0.005,0.01-0.008,0.022-0.011
			c0.011-0.003,0.02-0.006,0.017-0.009c0-0.002,0.007-0.005,0.017-0.005c0.01-0.001,0.021-0.002,0.021-0.005
			c0.004-0.001,0.02-0.008,0.041-0.012C45.618,23.849,45.605,23.841,45.53,23.841z M43.621,24.112
			c-0.003,0.001-0.002-0.004,0.004-0.008c0.016-0.012,0.018-0.012,0.008-0.001C43.629,24.107,43.625,24.112,43.621,24.112z
			 M43.101,24.257c-0.004-0.001-0.002-0.002,0.004-0.003c0.007-0.002,0.009,0,0.007,0.001C43.11,24.257,43.105,24.258,43.101,24.257
			z M43.189,24.239c-0.006-0.005-0.006-0.007-0.002-0.008c0.003-0.001,0.007,0.001,0.009,0.003
			C43.203,24.242,43.196,24.245,43.189,24.239z M43.717,23.995c0.004-0.002,0.006,0,0.006,0.002
			c-0.002,0.001-0.006,0.002-0.01,0.001C43.707,23.997,43.709,23.995,43.717,23.995z M43.728,24.075
			c-0.002,0.002-0.006,0.002-0.006-0.001c0-0.002,0.004-0.005,0.006-0.006c0.003,0,0.005,0.001,0.005,0.002
			C43.733,24.072,43.731,24.075,43.728,24.075z M43.74,24.064c-0.001-0.003-0.007-0.005-0.009-0.006
			c-0.003-0.002-0.001-0.005,0.004-0.005c0.004-0.001,0.009,0.003,0.009,0.007C43.744,24.065,43.742,24.065,43.74,24.064z
			 M43.748,24.03c-0.011,0.003-0.021,0.008-0.025,0.016c-0.01,0.014-0.014,0.013-0.012-0.002c0.004-0.015,0.021-0.021,0.045-0.02
			C43.766,24.025,43.764,24.026,43.748,24.03z M43.826,24.02c-0.009,0.001-0.009,0.002,0,0.008c0.012,0.004,0.012,0.004-0.004,0.002
			c-0.012-0.004-0.012-0.007-0.009-0.013c0.007-0.007,0.015-0.01,0.018-0.003C43.833,24.017,43.83,24.02,43.826,24.02z
			 M44.054,24.09c-0.002-0.002-0.002-0.002,0.006-0.004c0.01,0,0.012,0,0.008,0.002C44.064,24.09,44.059,24.09,44.054,24.09z
			 M44.134,24.075c-0.002-0.002-0.002-0.002,0.008-0.003c0.006,0,0.01,0,0.006,0.001C44.146,24.075,44.139,24.077,44.134,24.075z
			 M44.241,24.549c-0.006-0.004-0.006-0.004,0.003-0.003c0.006,0.002,0.01,0.004,0.006,0.005
			C44.25,24.552,44.244,24.552,44.241,24.549z M44.814,23.87c0,0.008,0,0.008-0.016,0.005c-0.007-0.002-0.007-0.003,0.002-0.007
			c0.006-0.002,0.012-0.004,0.014-0.005C44.814,23.864,44.814,23.867,44.814,23.87z M45.198,24.171
			c0.016,0.004,0.011,0.007-0.003,0.005c-0.009-0.002-0.013-0.007-0.013-0.013c0-0.005-0.006-0.012-0.01-0.014
			c-0.007-0.004-0.006-0.006,0.014-0.006c0.021,0,0.023,0,0.014,0.005C45.184,24.153,45.184,24.169,45.198,24.171z M45.207,24.135
			c-0.003,0.001-0.005-0.002-0.005-0.004c0-0.003,0-0.007,0.002-0.005c0.001,0,0.003,0.002,0.005,0.004
			C45.211,24.133,45.209,24.135,45.207,24.135z M45.229,24.143c-0.006-0.001-0.002-0.001,0.008-0.002
			c0.009-0.001,0.012,0.001,0.01,0.001C45.244,24.143,45.235,24.145,45.229,24.143z M45.305,23.848c-0.008,0-0.008,0,0.002-0.004
			c0.02-0.006,0.028-0.006,0.017-0.001C45.32,23.846,45.311,23.848,45.305,23.848z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M47.766,28.026c0.004-0.017,0.002-0.035,0-0.043c-0.008-0.021-0.029-0.067-0.032-0.066c0,0-0.007,0.005-0.009,0.012
			c-0.004,0.006-0.008,0.02-0.01,0.029c-0.002,0.022-0.006,0.026-0.019,0.02c-0.009-0.006-0.011-0.006-0.011,0
			c0,0.005,0.004,0.01,0.008,0.011c0.001,0.002,0,0.002-0.006,0.001c-0.014-0.002-0.016,0.001-0.014,0.021
			c0,0.004-0.002,0.008-0.004,0.013c-0.003,0-0.007,0.007-0.007,0.012c0,0.007-0.001,0.009-0.009,0.008
			c-0.003-0.002-0.007,0-0.007,0.003c0,0.012-0.037,0.038-0.065,0.049c-0.011,0.004-0.023,0.011-0.027,0.013
			c-0.002,0.002-0.01,0.005-0.018,0.005c-0.009-0.001-0.011,0.002-0.011,0.014c0,0.01-0.005,0.021-0.011,0.03
			c-0.012,0.017-0.012,0.028,0,0.088c0.007,0.035,0.007,0.036-0.01,0.065c-0.009,0.018-0.018,0.033-0.02,0.035
			c-0.018,0.015-0.023,0.062-0.014,0.086c0.006,0.007,0.009,0.022,0.006,0.035c-0.002,0.015,0,0.024,0.004,0.027
			c0.004,0.001,0.006,0.008,0.006,0.014c0,0.012,0.016,0.025,0.027,0.024c0.004-0.001,0.01,0.003,0.01,0.005
			c0,0.008,0.025,0.004,0.039-0.004c0.006-0.004,0.017-0.009,0.025-0.009c0.016-0.001,0.034-0.023,0.034-0.039
			c0-0.004,0.005-0.013,0.009-0.019c0.002-0.007,0.01-0.025,0.014-0.041c0.004-0.015,0.013-0.037,0.018-0.048
			c0.004-0.011,0.014-0.03,0.016-0.045c0.004-0.015,0.016-0.048,0.025-0.077c0.014-0.027,0.023-0.061,0.023-0.07
			c0.007-0.038,0.009-0.043,0.016-0.043c0.006,0,0.008-0.002,0-0.043c-0.001-0.008-0.003-0.015-0.003-0.022
			c0.003,0.004,0.007,0.008,0.011,0.013c0.002,0.004,0.006,0.008,0.01,0.012c0.002-0.006,0.006-0.012,0.011-0.017
			c0.007-0.013,0.007-0.02,0.003-0.026C47.771,28.054,47.766,28.039,47.766,28.026z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M46.67,24.216c-0.001,0,0,0.001-0.002,0.001c0.003,0.001,0.006,0.001,0.008,0.002c0.001-0.001,0.004,0,0.004,0
			C46.68,24.218,46.676,24.216,46.67,24.216z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="48.704,27.267 48.711,27.27 48.713,27.268 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M45.417,24.422c-0.009-0.003-0.014-0.008-0.013-0.01c0-0.004-0.001-0.005-0.005-0.005
			c-0.004-0.001-0.002-0.004,0.004-0.009c0.011-0.008,0.009-0.008-0.008-0.005c-0.012,0.002-0.021,0.001-0.022-0.001
			c-0.008-0.008-0.024-0.005-0.024,0.003c0,0.004-0.006,0.009-0.014,0.009c-0.007,0-0.025,0-0.043,0.001
			c-0.025,0.002-0.033,0.004-0.037,0.012c-0.007,0.008-0.007,0.008-0.011-0.001c-0.005-0.013-0.019-0.01-0.019,0.003
			c0,0.007-0.002,0.011-0.008,0.009c-0.004-0.002-0.01-0.001-0.012,0.001c-0.005,0.01-0.013,0.006-0.009-0.004
			c0.002-0.006-0.004-0.012-0.019-0.02c-0.022-0.013-0.02-0.013-0.029-0.002c-0.006,0.004-0.014,0.009-0.02,0.009
			c-0.007,0.001-0.012,0.004-0.012,0.009c0,0.003-0.002,0.008-0.004,0.008c-0.003,0-0.009,0.004-0.016,0.008
			c-0.01,0.008-0.008,0.008,0.021,0.005c0.043-0.004,0.047,0.008,0.006,0.016c-0.025,0.004-0.031,0.008-0.025,0.014
			c0,0.001,0.005,0.005,0.007,0.004c0.004,0,0.005-0.003,0.004-0.004c-0.004-0.006,0.035-0.002,0.041,0.004
			c0.007,0.011,0.003,0.02-0.011,0.02c-0.002,0.001-0.006,0.004-0.006,0.009s0.008,0.007,0.026,0.005
			c0.013-0.001,0.03,0,0.041,0.003c0.021,0.009,0.065,0.009,0.089,0c0.03-0.012,0.055-0.021,0.078-0.028
			c0.032-0.011,0.068-0.034,0.068-0.046C45.435,24.434,45.427,24.427,45.417,24.422z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="46.617" y="24.219" width="0.004" height="0.006" />
				</g>
			</g>
			<g>
				<g>
					<path d="M48.721,27.268c0.006-0.008,0.004-0.008-0.004-0.013c-0.006-0.001-0.01-0.006-0.01-0.007
			c0.006-0.006-0.004-0.039-0.01-0.035c-0.005,0.001-0.005-0.003-0.003-0.014c0.002-0.007,0-0.015-0.002-0.015
			s-0.005,0.006-0.005,0.015c0,0.008,0.003,0.015,0.005,0.017c0.004,0.002,0.004,0.006,0.004,0.01
			c-0.004,0.004-0.002,0.008,0.001,0.011c0.004,0.001,0.006,0.006,0.006,0.009c0,0.003-0.002,0.003-0.006-0.001
			c-0.005-0.006-0.005-0.006-0.001,0.002c0,0.004,0.003,0.012,0.003,0.018c0,0.002,0.002,0,0.004,0c0-0.002,0.004-0.005,0.008-0.004
			c0.002-0.001,0.004,0.001,0.004,0.004c0,0.001-0.002,0.001-0.002,0.002C48.717,27.267,48.717,27.273,48.721,27.268z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M49.024,26.986c-0.004,0-0.008-0.007-0.008-0.01c0-0.007-0.027-0.033-0.035-0.033c-0.004,0-0.008,0.002-0.01,0.005
			s-0.002,0.007,0.002,0.008c0.004,0.001,0.006,0.006,0.006,0.011s-0.002,0.008-0.008,0.006c-0.002-0.002-0.008-0.001-0.008,0.003
			c0,0.002,0.004,0.005,0.006,0.005c0.006,0,0.006,0.02,0,0.026c-0.002,0.004,0.006,0.072,0.014,0.091
			c0.004,0.011,0.025,0.015,0.043,0.008c0.025-0.013,0.033-0.021,0.033-0.043C49.059,27.042,49.038,26.995,49.024,26.986z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.588,29.747L43.588,29.747L43.588,29.747z" />
				</g>
			</g>
			<g>
				<g>
					<path d="M50.764,26.974c-0.012-0.005-0.014-0.011-0.014-0.024s-0.002-0.016-0.014-0.014c-0.005-0.001-0.012,0.001-0.012,0.003
			c0,0.003-0.002,0.004-0.008,0.004c-0.008,0-0.01-0.003-0.008-0.012c0.004-0.009,0-0.012-0.011-0.012
			c-0.005,0-0.016-0.005-0.024-0.008c-0.016-0.007-0.021-0.008-0.021-0.003c0,0.003,0.003,0.006,0.005,0.007
			c0.004,0.002,0.006,0.013,0.004,0.027c-0.002,0.024,0,0.028,0.012,0.018c0.006-0.003,0.008-0.003,0.01,0
			c0,0.003,0.008,0.004,0.01,0.004c0.012-0.004,0.011,0.008-0.002,0.017c-0.01,0.005-0.012,0.007-0.004,0.015
			c0.004,0.006,0.009,0.01,0.013,0.01c0.004,0,0.007,0.004,0.009,0.007c0.006,0.012,0.014,0.007,0.018-0.008
			c0.005-0.009,0.012-0.015,0.014-0.015c0.004,0,0.004,0.002,0.002,0.004c-0.004,0.004,0.011,0.006,0.025,0.003
			C50.782,26.995,50.78,26.981,50.764,26.974z M50.721,26.991c-0.002,0.004-0.004,0.003-0.004-0.002s0.002-0.007,0.004-0.005
			C50.721,26.984,50.723,26.989,50.721,26.991z M50.726,26.982c-0.002-0.004-0.004-0.009-0.004-0.012
			c0-0.003,0.002-0.002,0.004,0.002c0.004,0.005,0.008,0.009,0.008,0.011C50.734,26.987,50.73,26.986,50.726,26.982z M50.737,26.952
			c-0.001,0.002-0.003,0.001-0.003-0.004c0-0.003,0-0.005,0.003-0.005C50.737,26.945,50.737,26.948,50.737,26.952z M50.739,26.981
			c-0.002-0.005,0.002-0.007,0.002-0.005c0.004,0.001,0.004,0.005,0.002,0.008C50.741,26.987,50.739,26.986,50.739,26.981z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="43.188,24.46 43.188,24.461 43.189,24.461 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M47.703,24.062c-0.02-0.006-0.022-0.007-0.043-0.005c-0.005,0.002-0.017,0.005-0.025,0.008
			c-0.008,0.006-0.033,0.006-0.08,0.006c-0.062-0.002-0.084,0-0.09,0.006c-0.008,0.007-0.047,0.02-0.056,0.018
			c-0.007-0.001-0.009,0.003-0.005,0.009c0.002,0.018-0.006,0.031-0.024,0.039c-0.006,0.002-0.012,0.006-0.017,0.007
			c0.003,0.003,0.009,0.004,0.015,0.006c0.008,0.004,0.01,0.006,0.004,0.006c-0.004,0-0.014,0.004-0.025,0.011
			c-0.01,0.01-0.012,0.013-0.006,0.014c0.016,0,0.014,0.01-0.004,0.015c-0.006,0.001-0.014,0.007-0.014,0.011
			c0,0.012,0.015,0.021,0.026,0.02c0.007-0.002,0.011,0,0.007,0.002c0,0.002,0.006,0.008,0.014,0.01
			c0.01,0.005,0.016,0.005,0.02,0.001c0.004-0.005,0.004-0.004,0.004,0.002c0,0.011,0.011,0.015,0.053,0.021
			c0.064,0.011,0.071,0.012,0.073,0.004c0.002-0.005,0-0.006-0.007-0.005c-0.004,0.002-0.008,0.001-0.006-0.001
			c0-0.002-0.008-0.009-0.021-0.013c-0.01-0.004-0.021-0.01-0.021-0.011c0-0.002-0.006-0.009-0.014-0.014
			c-0.017-0.01-0.026-0.037-0.013-0.037c0.004,0,0.005-0.003,0.005-0.005c0-0.007,0.056-0.058,0.062-0.057
			c0.004,0,0.012-0.004,0.018-0.009c0.012-0.009,0.036-0.015,0.104-0.023C47.726,24.088,47.747,24.078,47.703,24.062z
			 M47.377,24.151c-0.003-0.002-0.003-0.003,0.004-0.003c0.006,0,0.01,0.001,0.006,0.003C47.387,24.151,47.383,24.153,47.377,24.151
			z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M50.87,27.672c-0.002,0.002-0.017,0.004-0.034,0.006c-0.021,0.002-0.035,0.006-0.039,0.012
			c-0.004,0.004-0.019,0.012-0.033,0.017c-0.017,0.006-0.026,0.013-0.027,0.021c-0.002,0.004-0.008,0.012-0.012,0.017
			c-0.008,0.005-0.008,0.008-0.002,0.01c0.012,0.006,0.045-0.008,0.063-0.025c0.011-0.009,0.029-0.02,0.043-0.023
			c0.028-0.008,0.063-0.026,0.06-0.032C50.887,27.672,50.874,27.671,50.87,27.672z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M48.439,24.065c0-0.012-0.013-0.017-0.054-0.025s-0.054-0.013-0.047-0.021c0.004-0.004-0.002-0.008-0.025-0.017
			c-0.055-0.017-0.055-0.017-0.076-0.014C48.2,23.992,48.198,23.992,48.209,24c0.012,0.005,0.012,0.006-0.001,0.004
			c-0.017-0.001-0.02,0.008-0.006,0.023c0.013,0.015,0.023,0.02,0.029,0.01c0.002,0.002,0.005,0.007,0.009,0.007
			c0.004,0.001,0.007-0.001,0.007-0.004c0-0.004,0.005-0.004,0.007,0.001c0.002,0.004,0.008,0.007,0.014,0.006
			c0.004-0.002,0.019,0.002,0.029,0.008c0.025,0.011,0.101,0.03,0.137,0.036c0.022,0.003,0.035-0.004,0.018-0.012
			C48.445,24.077,48.439,24.07,48.439,24.065z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M50.953,27.466c-0.007,0-0.018,0.003-0.02,0.006c-0.004,0.004-0.004,0.004-0.004,0c0-0.003-0.004-0.003-0.012,0.001
			c-0.013,0.009-0.013,0.016-0.003,0.013c0.007-0.002,0.009,0,0.009,0.008c0.003,0.011,0.003,0.011,0.019,0.006
			c0.011-0.002,0.021-0.008,0.021-0.011c0-0.004,0.002-0.004,0.01,0c0.006,0.001,0.015,0.003,0.021,0.001
			c0.007-0.001,0.019,0.002,0.028,0.006c0.021,0.012,0.025,0.011,0.025-0.001C51.047,27.473,51.006,27.461,50.953,27.466z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M49.801,24.463c-0.01-0.002-0.021-0.008-0.026-0.012c-0.013-0.01-0.031-0.015-0.031-0.009
			c0,0.008,0.015,0.018,0.024,0.018c0.006,0.002,0.012,0.004,0.013,0.008c0.004,0.005,0,0.006-0.007,0.005
			c-0.007-0.001-0.011,0-0.007,0.004c0,0.003,0.007,0.004,0.014,0.005c0.008-0.001,0.032,0.005,0.059,0.013
			c0.043,0.012,0.057,0.013,0.041,0.002C49.867,24.487,49.819,24.466,49.801,24.463z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M51.82,27.732c-0.006,0-0.006-0.001,0-0.003c0.003-0.003,0-0.004-0.016-0.008c-0.02-0.004-0.029-0.011-0.016-0.011
			c0.01-0.001,0.004-0.008-0.011-0.009c-0.011,0-0.013-0.001-0.013-0.01c0.002-0.009,0-0.01-0.016-0.006
			c-0.012,0.002-0.016,0.001-0.021-0.015c-0.004-0.009-0.018-0.022-0.027-0.03c-0.011-0.009-0.02-0.019-0.02-0.023
			c0-0.007,0.004-0.009,0.02-0.01c0.016,0,0.018,0,0.018-0.006c0-0.01-0.018-0.022-0.028-0.024c-0.006,0-0.011-0.002-0.017-0.006
			c-0.006-0.004-0.017-0.007-0.022-0.009c-0.013-0.001-0.013-0.002-0.013-0.012c0-0.005,0.005-0.01,0.007-0.01
			c0.004,0.001,0.007-0.002,0.007-0.006c0-0.006-0.003-0.006-0.009-0.002c-0.007,0.005-0.011,0.004-0.021-0.002
			c0.007-0.008,0.002-0.015,0.002-0.016c0-0.003-0.004-0.004-0.005-0.003c-0.004,0.002-0.01,0-0.013-0.004
			c-0.007-0.002-0.013-0.005-0.021-0.005c-0.006,0-0.015-0.002-0.017-0.006c-0.003-0.003-0.021-0.008-0.037-0.012
			c-0.017-0.003-0.039-0.012-0.05-0.016c-0.014-0.006-0.024-0.008-0.028-0.008c-0.004,0.001-0.008,0-0.008-0.002
			s-0.008-0.005-0.021-0.007c-0.014-0.001-0.039-0.01-0.059-0.019c-0.033-0.013-0.037-0.014-0.053-0.009
			c-0.008,0.002-0.018,0.009-0.018,0.012c-0.002,0.005-0.009,0.008-0.02,0.008c-0.005,0-0.014,0.001-0.014,0.003
			s-0.002,0.007-0.008,0.013c-0.006,0.004-0.015,0.011-0.019,0.017c-0.003,0.005-0.013,0.009-0.021,0.009
			c-0.006,0-0.013-0.003-0.018-0.02c-0.004-0.012-0.007-0.017-0.009-0.015c-0.005,0.007-0.011,0.001-0.011-0.009
			c0-0.008,0-0.009,0.004-0.002c0.007,0.004,0.007,0.004,0.002-0.003c-0.002-0.004-0.004-0.015-0.004-0.024
			c-0.002-0.02-0.008-0.026-0.018-0.023c-0.003,0.001-0.016-0.001-0.025-0.006c-0.023-0.011-0.032-0.011-0.057,0
			c-0.01,0.004-0.021,0.011-0.023,0.012c-0.006,0.003-0.008,0.003-0.012,0c-0.004-0.004-0.022-0.006-0.022-0.001
			c0,0.007,0.022,0.027,0.032,0.027c0.017,0,0.025,0.005,0.025,0.011c0,0.013,0.02,0.018,0.051,0.017
			c0.021-0.001,0.028-0.001,0.026,0.002s-0.009,0.004-0.017,0.003c-0.008-0.002-0.014-0.001-0.019,0.003
			c-0.004,0.003-0.013,0.005-0.018,0.003c-0.011-0.001-0.025,0.002-0.025,0.007c0,0.002,0.007,0.005,0.013,0.008
			c0.008,0.003,0.014,0.007,0.014,0.011c0,0.004,0.004,0.006,0.007,0.006c0.004,0,0.004,0.002,0.002,0.004
			c-0.005,0.006,0.002,0.021,0.009,0.019c0.004-0.001,0.01,0,0.014,0.003c0.002,0.002,0.011,0.003,0.011,0.002
			c0.004-0.001,0.002-0.004,0-0.005c-0.007-0.002-0.009-0.006-0.005-0.01c0.005-0.004,0.01-0.004,0.025,0.001
			c0.012,0.004,0.022,0.011,0.028,0.014c0.005,0.003,0.017,0.007,0.026,0.008c0.02,0.001,0.098,0.03,0.098,0.036
			c0,0.003,0.004,0.008,0.008,0.013c0.002,0.005,0.006,0.012,0.006,0.015c0,0.004,0.004,0.008,0.006,0.008
			c0,0,0.002,0.007,0.002,0.014c0,0.012-0.002,0.015-0.008,0.015c-0.01,0-0.035,0.027-0.035,0.037c0,0.006,0.045,0.005,0.053-0.001
			c0.002-0.001,0.011-0.004,0.021-0.005c0.017-0.001,0.021,0,0.038,0.018c0.02,0.019,0.021,0.02,0.049,0.02
			c0.018-0.002,0.031,0,0.032,0.001c0.002,0.001,0.009,0.001,0.013-0.001c0.008-0.003,0.01-0.003,0.008,0.004
			c0,0.003-0.004,0.01-0.006,0.013c-0.002,0.001-0.002,0.003,0.002,0.003c0.008,0,0.014-0.01,0.014-0.022
			c0-0.003,0.001-0.007,0.005-0.007c0.004,0,0.006-0.003,0.006-0.006s0.001-0.005,0.005-0.004c0.002,0.002,0.004-0.003,0.004-0.007
			c0-0.017,0.039-0.037,0.053-0.028c0,0.002,0.012,0.005,0.021,0.006c0.012,0.002,0.021,0.008,0.025,0.013
			c0,0.004,0.007,0.009,0.009,0.009c0.002,0,0.005,0.004,0.005,0.007c0,0.004,0.004,0.01,0.007,0.012
			c0.004,0.004,0.007,0.006,0.004,0.009c-0.002,0.001,0.003,0.002,0.007,0.002c0.004,0,0.01,0.005,0.012,0.011
			c0.002,0.007,0.01,0.011,0.029,0.014c0.012,0.002,0.03,0.004,0.039,0.003c0.01,0,0.021,0.002,0.025,0.005
			c0.016,0.006,0.057,0.005,0.059-0.001c0.002-0.001,0.002-0.002,0.002-0.002C51.832,27.732,51.825,27.732,51.82,27.732z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M50.846,27.008c-0.002,0.002-0.002-0.003-0.002-0.013c0.004-0.014,0.004-0.015-0.004-0.013
			c-0.008,0.001-0.012,0-0.014-0.006c-0.003-0.01-0.014-0.013-0.014-0.004c0,0.005,0,0.007-0.008,0.007
			c-0.006-0.001-0.008-0.002-0.002-0.006c0.002-0.002,0.002-0.009,0-0.014c-0.013-0.014-0.011-0.016,0.005-0.013
			c0.006,0.001,0.011,0.001,0.017,0.002c-0.004-0.005-0.007-0.01-0.011-0.014c-0.006-0.008-0.012-0.02-0.012-0.025
			c0-0.016-0.006-0.023-0.029-0.026c-0.018-0.001-0.021,0-0.021,0.007c0,0.003,0.008,0.012,0.016,0.018
			c0.011,0.005,0.014,0.013,0.014,0.017c-0.003,0.004-0.005,0.003-0.012-0.003c-0.01-0.01-0.014-0.004-0.01,0.014
			c0.002,0.009,0.008,0.016,0.01,0.016c0.007,0,0.009,0.005,0.009,0.012c0,0.01,0.005,0.014,0.016,0.019
			c0.007,0.004,0.015,0.008,0.015,0.012c0,0.002,0.002,0.009,0.006,0.014c0.002,0.008,0,0.009-0.012,0.008
			c-0.006,0-0.015-0.002-0.015-0.004s0-0.004-0.003-0.004c-0.004,0-0.004,0.006-0.002,0.014c0.002,0.01,0.002,0.013-0.004,0.01
			c-0.005-0.001-0.01,0-0.01,0.006c-0.004,0.012-0.006,0.012-0.018-0.001c-0.009-0.009-0.013-0.009-0.023-0.005
			c-0.008,0.002-0.014,0.007-0.014,0.012c0,0.003-0.006,0.006-0.015,0.006c-0.007,0-0.015,0.001-0.015,0.004
			c-0.002,0.002-0.004,0.012-0.008,0.018c-0.002,0.008-0.002,0.017-0.002,0.02c0.002,0.008,0.018,0.007,0.018-0.002
			c0-0.003,0.004-0.012,0.007-0.016c0.009-0.008,0.009-0.008,0.009,0.001c0,0.01,0.008,0.013,0.016,0.005
			c0-0.002,0.004-0.003,0.009,0c0.006,0.001,0.009-0.001,0.009-0.007c0-0.008,0.002-0.009,0.009-0.004
			c0.009,0.003,0.011,0.01,0.011,0.028c-0.002,0.02,0.002,0.024,0.016,0.032c0.009,0.006,0.02,0.012,0.025,0.013
			c0.005,0.001,0.012,0.005,0.012,0.011c0.004,0.011,0.015,0.011,0.016,0.001c0-0.003,0.002-0.01,0.006-0.014
			c0.002-0.004,0-0.012-0.004-0.02c-0.011-0.016-0.009-0.02,0.002-0.021c0.006,0.001,0.01,0.003,0.01,0.007
			c0,0.003,0.004,0.011,0.008,0.016c0.009,0.008,0.009,0.007,0.009-0.008c0-0.009,0.003-0.017,0.005-0.019
			c0.004-0.001,0.005-0.006,0.005-0.011C50.872,27.068,50.85,27.005,50.846,27.008z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M50.519,27.258c-0.012-0.012-0.012-0.015-0.006-0.025c0.006-0.009,0.006-0.013,0-0.017
			c-0.008-0.006,0.002-0.015,0.022-0.02c0.021-0.003,0.021-0.009,0.005-0.012c-0.016-0.003-0.018-0.012-0.001-0.012
			c0.013,0.001,0.03-0.006,0.03-0.012c0-0.004-0.004-0.007-0.012-0.009c-0.016-0.006-0.033-0.015-0.037-0.02
			c-0.001-0.002-0.008-0.003-0.01-0.003c-0.004,0.002-0.006-0.002-0.006-0.006c0-0.005-0.004-0.014-0.014-0.02
			c-0.007-0.006-0.011-0.012-0.009-0.016c0.002-0.009-0.005-0.012-0.013-0.009c0.005,0.001-0.001,0.008-0.005,0.018
			c-0.002,0.01-0.01,0.018-0.012,0.017c-0.002,0-0.007,0.006-0.009,0.013c-0.004,0.007-0.013,0.016-0.021,0.021
			c-0.011,0.005-0.011,0.008-0.008,0.014c0.006,0.006,0.004,0.006-0.007,0.006c-0.009,0-0.019,0.003-0.022,0.006
			c-0.004,0.004-0.013,0.007-0.019,0.006c-0.007,0-0.011,0.003-0.011,0.01c0,0.005-0.01,0.02-0.021,0.03
			c-0.018,0.018-0.021,0.022-0.044,0.025c-0.013,0.002-0.024,0.003-0.026,0.007c-0.002,0.002-0.006,0.002-0.01,0.001
			c-0.002-0.001-0.007,0.014-0.002,0.026c0,0.002-0.003,0.008-0.007,0.013c-0.005,0.007-0.009,0.008-0.019,0.003
			c-0.006-0.003-0.016-0.005-0.021-0.003c-0.005,0-0.011-0.004-0.013-0.007c-0.003-0.01-0.02-0.001-0.028,0.016
			c-0.009,0.015-0.009,0.033,0,0.072c0.009,0.032,0.015,0.042,0.027,0.04c0.009-0.003,0.01-0.001,0.01,0.012
			c0,0.009,0,0.026,0.004,0.038c0.005,0.023,0.005,0.022,0.043,0.021c0.019,0,0.023,0.001,0.023,0.008
			c0,0.011,0.009,0.017,0.012,0.009c0.002-0.002,0.008-0.002,0.013-0.002c0.006,0.002,0.016-0.001,0.021-0.007
			c0.005-0.005,0.013-0.007,0.013-0.004c0,0.002,0.003,0.004,0.007,0.005c0.004-0.001,0.008,0.001,0.008,0.004
			s0.006,0.003,0.013,0.002c0.017-0.005,0.024,0.001,0.024,0.015c0,0.016,0.004,0.016,0.031,0.002
			c0.008-0.003,0.018-0.008,0.023-0.012c0.004,0.003,0.006,0.007,0.007,0.01c0.008,0.01,0.008,0.01,0.013-0.002
			c0.004-0.007,0.004-0.018,0.002-0.021c0-0.004,0.002-0.016,0.006-0.025c0.008-0.011,0.01-0.018,0.006-0.019
			c-0.012-0.003-0.002-0.021,0.018-0.036c0.011-0.008,0.02-0.015,0.023-0.014c0.002,0,0.004-0.01,0-0.024
			c-0.002-0.03,0.006-0.041,0.031-0.039c0.03,0.001,0.03-0.007,0.006-0.027c-0.021-0.012-0.023-0.019-0.021-0.025
			C50.528,27.273,50.526,27.265,50.519,27.258z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M50.751,26.848c0.007-0.004,0.007-0.005,0.003-0.014c-0.008-0.011-0.017-0.012-0.017-0.003
			c0,0.006-0.002,0.005-0.017-0.001c-0.01-0.003-0.019-0.006-0.021-0.005c-0.003,0.002-0.005-0.001-0.007-0.004
			c-0.005-0.006-0.009-0.008-0.026-0.006c-0.02,0.001-0.025,0-0.027-0.005c-0.002-0.006,0.004-0.008,0.021-0.007
			c0.004,0,0.008,0,0.008-0.002c0-0.004-0.019-0.014-0.029-0.016c-0.008,0-0.012-0.006-0.008-0.022c0-0.008,0.004-0.013,0.01-0.013
			c0.014,0.001,0.021-0.036,0.008-0.052c-0.004-0.006-0.01-0.019-0.01-0.028c0-0.017,0-0.017-0.016-0.016
			c-0.011,0.001-0.02,0-0.021-0.002c-0.004-0.007-0.027-0.008-0.031-0.002c-0.008,0.006-0.005,0.07,0,0.085
			c0.004,0.008,0.004,0.009-0.003,0.001c-0.023-0.018-0.023-0.004-0.013,0.032c0.012,0.024,0.023,0.041,0.035,0.041
			c0.004,0,0.006-0.003,0.006-0.007c0-0.004,0-0.005,0.004-0.004c0,0.001,0,0.008-0.004,0.013c-0.008,0.012-0.004,0.027,0.004,0.023
			c0.004-0.001,0.008,0,0.008,0.003c0,0.006-0.018,0.004-0.021-0.004c0-0.003-0.007-0.006-0.01-0.007
			c-0.006,0-0.002,0.005,0.007,0.017c0.011,0.01,0.021,0.024,0.024,0.032c0.013,0.019,0.02,0.023,0.027,0.014
			c0.01-0.011,0.004-0.028-0.008-0.038c-0.01-0.008-0.01-0.008,0.004-0.013c0.012-0.006,0.029-0.001,0.018,0.005
			c-0.006,0.002-0.006,0.005,0,0.01c0.008,0.007,0.009,0.007,0.015,0.001c0.005-0.006,0.007-0.006,0.011,0.002
			c0.004,0.003,0.008,0.006,0.012,0.004c0-0.002,0-0.008-0.004-0.012c-0.014-0.018-0.002-0.014,0.022,0.01
			c0.021,0.02,0.024,0.027,0.013,0.026c-0.002,0-0.004-0.001-0.002-0.003c0-0.003-0.015-0.022-0.02-0.023
			c-0.008,0-0.004,0.013,0.003,0.016c0.006,0.002,0.009,0.009,0.009,0.021c0,0.012,0.002,0.016,0.004,0.012
			c0.004-0.004,0.008-0.004,0.018,0.002c0.021,0.012,0.023,0.01,0.014-0.006c-0.005-0.009-0.007-0.015-0.004-0.016
			c0.004-0.003,0.008-0.009,0.008-0.015c0.003-0.009,0-0.013-0.011-0.017c-0.019-0.007-0.021-0.015-0.011-0.013
			c0.007,0,0.011,0.002,0.011,0.004C50.737,26.851,50.742,26.851,50.751,26.848z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M50.789,27.312c-0.011,0.011-0.023,0.017-0.027,0.015c-0.006-0.001-0.026-0.003-0.051-0.005
			c-0.022-0.001-0.041-0.005-0.041-0.007c0-0.005-0.02-0.008-0.025-0.005c0,0.001-0.006,0.007-0.008,0.011
			c-0.002,0.004-0.006,0.006-0.01,0.005c-0.007-0.004-0.025,0.014-0.025,0.025c0,0.004-0.002,0.008-0.004,0.008
			c-0.004,0-0.004,0.005-0.002,0.012c0.002,0.008-0.002,0.018-0.008,0.024c-0.005,0.005-0.009,0.016-0.009,0.024
			c0,0.014-0.013,0.042-0.021,0.042c-0.002,0-0.004,0.005-0.002,0.013c0,0.006,0.002,0.015,0.002,0.019
			c0.002,0.005,0.006,0.007,0.013,0.005c0.011-0.002,0.013,0,0.013,0.014c0.002,0.01,0,0.027-0.004,0.039
			c-0.007,0.025-0.003,0.031,0.023,0.027c0.016-0.003,0.018-0.005,0.018-0.019c0.002-0.07,0.006-0.089,0.016-0.093
			c0.014-0.004,0.018,0.001,0.008,0.017c-0.006,0.012-0.004,0.015,0.012,0.025c0.011,0.009,0.012,0.013,0.011,0.021
			c-0.004,0.009,0.001,0.02,0.013,0.02c0.004,0,0.011-0.004,0.017-0.009c0.003-0.004,0.011-0.009,0.015-0.009
			c0.004,0,0.004,0.003-0.003,0.007c-0.012,0.013-0.014,0.027-0.003,0.035c0.01,0.007,0.012,0.007,0.021,0
			c0.005-0.005,0.009-0.012,0.007-0.016c-0.002-0.004,0-0.009,0.002-0.011c0.002,0,0.004-0.009,0.004-0.019
			c0-0.013-0.002-0.016-0.011-0.015c-0.012,0.002-0.023-0.011-0.021-0.021c0.003-0.004-0.005-0.018-0.014-0.031
			c-0.013-0.018-0.015-0.024-0.011-0.029c0.004-0.003,0.011-0.006,0.013-0.006c0.003,0,0.015-0.005,0.021-0.014
			c0.008-0.011,0.012-0.012,0.022-0.011c0.009,0.002,0.013,0.001,0.013-0.006c0-0.012-0.014-0.016-0.029-0.009
			c-0.008,0.004-0.021,0.006-0.032,0.006c-0.015,0-0.021,0.003-0.028,0.011c-0.011,0.01-0.025,0.011-0.025,0
			c0-0.002-0.006-0.007-0.012-0.013c-0.009-0.006-0.011-0.013-0.009-0.023c0.002-0.022,0.006-0.023,0.073-0.02
			c0.091,0.006,0.105,0.005,0.105-0.004c0-0.002,0.006-0.012,0.014-0.019c0.007-0.009,0.011-0.017,0.011-0.021
			C50.816,27.292,50.812,27.292,50.789,27.312z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M51.13,26.044c-0.007-0.007-0.014-0.015-0.014-0.02s0-0.013,0-0.016c-0.002-0.003-0.004-0.01-0.004-0.013
			c0-0.004-0.005-0.013-0.012-0.018c-0.004-0.006-0.01-0.014-0.01-0.017c0-0.004,0.004-0.005,0.01-0.003
			c0.007,0.003,0.012,0.003,0.012,0c0-0.002-0.002-0.005-0.005-0.005S51.1,25.943,51.1,25.93c0-0.019,0-0.022-0.032-0.054
			c-0.018-0.018-0.036-0.036-0.036-0.039c0-0.004-0.003-0.007-0.007-0.007c-0.005-0.001-0.011-0.003-0.017-0.005S51,25.823,51,25.83
			c0,0.003-0.002,0.005-0.006,0.002c-0.004-0.003-0.006,0-0.004,0.008c0.004,0.006,0.002,0.012-0.002,0.013
			c-0.003,0.002-0.001,0.006,0.004,0.011c0.006,0.005,0.007,0.012,0.006,0.016c-0.004,0.004,0,0.006,0.005,0.007
			c0.004,0.001,0.012,0.006,0.014,0.011c0.009,0.015,0.011,0.055,0.004,0.06c-0.008,0.005-0.008,0.007-0.008,0.019
			c0,0.003-0.008,0.008-0.015,0.01c-0.011,0.001-0.019,0.004-0.021,0.006c-0.006,0.006-0.016-0.008-0.01-0.016
			c0.002-0.01-0.006-0.013-0.018-0.008c-0.007,0.002-0.007,0.004,0,0.015c0.004,0.006,0.008,0.007,0.008,0.005
			c0-0.003,0-0.005,0.004-0.003c0.002,0.001-0.012,0.022-0.017,0.022c-0.006,0-0.006,0.017,0.002,0.018
			c0.003,0.002,0.003,0.004,0,0.008c-0.004,0.004-0.008,0.004-0.015,0c-0.006-0.005-0.011-0.008-0.032-0.004
			c-0.014,0-0.034,0-0.045,0.001c-0.021-0.005-0.03-0.004-0.03,0.001c0,0.008-0.026,0.028-0.037,0.03
			c-0.006,0.002-0.009,0.006-0.009,0.011s-0.004,0.011-0.007,0.014c-0.005,0.002-0.013,0.007-0.021,0.011
			c-0.004,0.002-0.008,0.005-0.011,0.007c0.003,0.004,0.009,0.01,0.013,0.015c0.008,0.009,0.018,0.013,0.021,0.012
			c0.002,0,0.004,0,0.002,0.002c-0.002,0.001,0.002,0.012,0.011,0.022c0.008,0.01,0.016,0.021,0.016,0.025
			c0,0.004,0.004,0.008,0.012,0.009c0.006,0.002,0.011,0.005,0.011,0.008s0.006,0.001,0.013-0.005
			c0.012-0.008,0.012-0.014,0.012-0.038c0-0.016,0-0.03-0.002-0.032c-0.002-0.011,0.002-0.011,0.016,0.003
			c0.017,0.017,0.036,0.022,0.031,0.011c-0.004-0.009,0.005-0.023,0.016-0.022c0.004,0,0.008,0.003,0.008,0.004
			c0,0.01,0.006,0.007,0.014-0.005c0.002-0.005,0.006-0.012,0.005-0.015c-0.003-0.004-0.003-0.008,0.001-0.01
			c0.004,0,0.008,0.002,0.008,0.004c0,0.009,0.019,0.026,0.033,0.03c0.007,0.002,0.011-0.001,0.015-0.012
			c0.002-0.007,0.009-0.012,0.013-0.012c0.012,0.002,0.012-0.011-0.004-0.016c-0.006-0.001-0.011-0.006-0.011-0.007
			c0-0.002,0.005-0.002,0.011,0c0.008,0.001,0.012,0.004,0.012,0.006c0,0.001,0.009,0.004,0.023,0.005
			c0.021,0.003,0.023,0.001,0.023-0.009c0-0.003,0-0.007,0.002-0.01c0.002,0.005,0.003,0.009,0.007,0.014
			c0.009,0.014,0.021,0.014,0.017-0.002c0-0.012,0.008-0.013,0.021-0.002c0.007,0.007,0.009,0.007,0.019,0.003
			c0.002-0.004,0.006-0.01,0.002-0.011c0-0.002,0-0.008,0.004-0.011C51.138,26.055,51.138,26.052,51.13,26.044z M50.837,26.107
			c-0.003,0-0.007-0.003-0.005-0.006c0.002-0.003-0.005-0.007-0.016-0.008c-0.009-0.003-0.017-0.006-0.017-0.008
			c0-0.003,0.005-0.002,0.024,0.001c0.016,0.005,0.018,0.001,0.021-0.002c0,0.001-0.002,0.001-0.002-0.004
			c-0.002-0.006,0-0.007,0.002-0.005c0.002,0.001,0.002,0.005,0,0.009c0.001-0.001,0.007,0.001,0.007-0.001
			c0.007-0.012,0.011-0.009,0.007,0.004C50.855,26.099,50.844,26.109,50.837,26.107z M50.889,26.081
			c-0.004,0.007-0.017,0.006-0.021,0c-0.002-0.003,0.004-0.006,0.021-0.006C50.891,26.075,50.891,26.078,50.889,26.081z
			 M50.912,26.078c-0.013-0.004-0.013-0.005-0.009-0.01c0.009-0.007,0.021-0.005,0.025,0.004
			C50.932,26.081,50.928,26.083,50.912,26.078z M51.018,25.848c-0.004,0-0.008-0.002-0.008-0.003c0-0.003,0.004-0.005,0.008-0.003
			c0.002,0.001,0.007,0.002,0.007,0.003C51.025,25.844,51.02,25.846,51.018,25.848z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M51.112,25.752c0.002-0.013,0.003-0.018,0.009-0.015c0.006,0.004,0.01,0.004,0.01,0.001c0-0.001-0.004-0.006-0.011-0.008
			c-0.009-0.002-0.011-0.002-0.014,0.013c-0.005,0.016-0.013,0.017-0.013-0.001c0-0.012-0.01-0.014-0.016-0.004
			c-0.001,0.007-0.001,0.007-0.039-0.009c-0.035-0.015-0.061-0.029-0.066-0.037c-0.008-0.006-0.043-0.028-0.049-0.029
			c-0.002,0-0.006,0.003-0.006,0.006s-0.002,0.005-0.005,0.003c-0.002-0.001-0.006,0-0.006,0.001c0,0.003,0.004,0.004,0.008,0.004
			c0.015-0.002,0.048,0.045,0.042,0.057c-0.001,0.002,0,0.007,0.002,0.009c0.014,0.007,0.006,0.01-0.009,0.005
			c-0.021-0.007-0.024-0.004-0.013,0.007c0.008,0.008,0.008,0.009,0,0.01c-0.004,0.001-0.008,0.008-0.008,0.016
			c0,0.007,0,0.011,0.002,0.009c0.004-0.008,0.026,0.01,0.031,0.023c0.002,0.012,0.018,0.015,0.021,0.006
			c0.003-0.003,0.007-0.006,0.011-0.005c0.014,0.002,0.008-0.007-0.013-0.017c-0.013-0.006-0.021-0.011-0.021-0.013
			c0-0.005,0.006-0.004,0.014,0.002c0.004,0.005,0.008,0.004,0.017,0.001s0.015-0.001,0.039,0.01
			c0.019,0.008,0.032,0.013,0.035,0.012c0.006-0.002,0.006-0.001,0.004,0.002c-0.002,0.002,0,0.005,0.002,0.005
			c0.006,0.001,0.006-0.003,0.004-0.01c-0.006-0.017,0.002-0.023,0.021-0.021c0.017,0.001,0.033-0.004,0.033-0.011
			c0-0.003-0.004-0.005-0.009-0.003C51.114,25.771,51.11,25.77,51.112,25.752z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M50.965,27.299c-0.004,0-0.012,0.004-0.019,0.007c-0.011,0.006-0.011,0.005-0.011-0.012c0-0.02-0.006-0.022-0.018-0.006
			c-0.003,0.006-0.007,0.019-0.007,0.028c0,0.009-0.004,0.017-0.006,0.017c-0.003,0-0.001,0.002,0.002,0.004
			c0.006,0.003,0.006,0.006,0.002,0.007c-0.004,0.001-0.004,0.007-0.004,0.012c0.004,0.009,0.004,0.009,0.008,0
			c0.003-0.006,0.005-0.006,0.005,0.004s-0.002,0.013-0.011,0.01c-0.01-0.001-0.015,0.004-0.01,0.016
			c0.003,0.011,0.033,0.014,0.033,0.004c0-0.005,0-0.005,0.006,0.001c0.011,0.014,0.014,0.003,0.003-0.013
			c-0.013-0.022-0.009-0.032,0.013-0.026c0.021,0.006,0.025,0.002,0.01-0.01c-0.015-0.011-0.015-0.011-0.004-0.016
			C50.967,27.322,50.971,27.299,50.965,27.299z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="50.844,26.084 50.844,26.084 50.844,26.085 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M50.572,26.924c0.003,0.002,0.005,0.002,0.005-0.001c0-0.002-0.003-0.004-0.009-0.004
			c-0.009,0.001-0.013,0.004-0.015,0.017c0,0.009-0.006,0.022-0.011,0.027c-0.006,0.006-0.009,0.015-0.007,0.016
			c0.001,0.004,0.001,0.004-0.002,0.004c-0.006-0.002-0.047,0.033-0.05,0.043c-0.005,0.005-0.002,0.013,0,0.016
			c0.005,0.003,0.005,0.003,0.005,0s0.016-0.015,0.027-0.027c0.016-0.013,0.027-0.024,0.027-0.027c0-0.007,0.022-0.024,0.032-0.026
			c0.015-0.002,0.019-0.011,0.003-0.014c-0.007-0.001-0.009-0.006-0.009-0.015C50.568,26.927,50.57,26.922,50.572,26.924z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M40.834,25.25c-0.002,0-0.014,0.008-0.029,0.015c-0.017,0.008-0.029,0.016-0.031,0.018
			c-0.002,0.003-0.01,0.005-0.014,0.006c-0.008,0.002-0.018,0.006-0.021,0.01c-0.004,0.004-0.008,0.007-0.013,0.006
			c-0.006-0.002-0.036,0.014-0.039,0.023c-0.01,0.012,0,0.018,0.018,0.011c0.01-0.003,0.02-0.007,0.021-0.01
			c0.007-0.001,0.014-0.004,0.021-0.008c0.006-0.004,0.02-0.012,0.03-0.016c0.017-0.01,0.021-0.016,0.021-0.028
			c0-0.001,0.008-0.004,0.02-0.004c0.016-0.001,0.018-0.002,0.018-0.012C40.836,25.255,40.836,25.249,40.834,25.25z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.765,29.624c-0.011,0.001-0.026-0.002-0.032-0.004c-0.003-0.003-0.013-0.003-0.019-0.001
			c-0.007,0.001-0.015,0.001-0.02-0.002c-0.005-0.001-0.007,0,0,0.004c0.009,0.011,0.007,0.022-0.002,0.019
			c-0.016-0.008-0.01,0.006,0.007,0.016c0.015,0.01,0.017,0.01,0.032-0.008c0.009-0.01,0.017-0.012,0.013-0.008
			c-0.009,0.024-0.009,0.027,0.002,0.028c0.005,0.001,0.021-0.004,0.035-0.012c0.022-0.01,0.022-0.011,0.017-0.021
			C43.792,29.633,43.778,29.625,43.765,29.624z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.009,24.268c-0.005,0.003-0.016,0.003-0.023,0.005c0.002-0.004,0.004-0.009,0.007-0.013
			c0.011-0.014,0.009-0.02-0.001-0.018c-0.004,0-0.004,0.003-0.002,0.006c0.002,0.002,0.002,0.004-0.002,0.002
			c-0.004-0.001-0.015,0.002-0.025,0.006c-0.039,0.017-0.037,0.017-0.027,0.02c0.006,0.002,0.004,0.003-0.006,0.005
			c-0.015,0.002-0.017,0.011-0.002,0.011c0.003,0-0.004,0.004-0.021,0.006c-0.016,0.003-0.03,0.004-0.028,0.002
			c0.008-0.012-0.018-0.018-0.05-0.016c-0.035,0.002-0.035,0.002-0.018-0.006c0.007-0.006,0.013-0.009,0.011-0.009
			c-0.004,0,0-0.003,0.009-0.005c0.009-0.004,0.017-0.009,0.017-0.012c0-0.006-0.019-0.002-0.071,0.01
			c-0.033,0.008-0.059,0.018-0.119,0.046c-0.007,0.004-0.02,0.009-0.028,0.01c-0.008,0-0.02,0.006-0.029,0.01
			c-0.023,0.01-0.025,0.022-0.004,0.015c0.01-0.002,0.015-0.002,0.018,0.002c0.004,0.003,0.008,0.003,0.011-0.005
			c0.008-0.007,0.012-0.009,0.012-0.005c0,0.004,0.004,0.004,0.016,0c0.018-0.005,0.018-0.005,0.008,0.003
			c-0.011,0.009-0.011,0.009,0.003,0.007c0.008-0.001,0.02-0.006,0.028-0.01c0.008-0.003,0.016-0.005,0.016-0.004
			c0,0.001-0.008,0.007-0.017,0.011c-0.011,0.007-0.013,0.008-0.007,0.01c0.007,0.002,0.019,0.002,0.033,0.001
			c0.013-0.002,0.023,0,0.021,0.003c-0.004,0.014,0.027,0.011,0.091-0.009c0.035-0.01,0.085-0.024,0.112-0.03
			c0.039-0.007,0.053-0.013,0.075-0.028C43.05,24.268,43.046,24.26,43.009,24.268z M42.78,24.294c0.008-0.001,0.011,0,0.009,0.002
			c-0.001,0.003-0.009,0.006-0.014,0.008c-0.007,0-0.009-0.001-0.007-0.004C42.768,24.297,42.774,24.292,42.78,24.294z
			 M42.767,24.346c-0.016,0.002-0.018,0.003-0.004-0.003c0.009-0.003,0.014-0.006,0.016-0.007c0,0,0.002,0.002,0.004,0.003
			C42.785,24.343,42.779,24.346,42.767,24.346z M42.77,24.31c-0.011,0.001-0.028,0.006-0.032,0.008
			c-0.005,0.004-0.003,0.004,0.004,0.004c0.006,0,0.009,0,0.006,0.001c-0.004,0.003-0.013,0.003-0.021,0.001
			c-0.01-0.001-0.01-0.002-0.004-0.004c0.006-0.001,0.012-0.003,0.012-0.007c0-0.002-0.008-0.002-0.018,0.002
			c-0.02,0.006-0.02,0.006-0.004-0.003c0.008-0.008,0.014-0.009,0.024-0.006c0.003,0.001,0.019,0.001,0.033,0.002
			c0.006,0,0.015,0,0.025,0C42.787,24.31,42.778,24.31,42.77,24.31z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.588,29.747c0.002,0.001,0.002,0.005,0,0.006c-0.006,0.007-0.006,0.008,0.002,0.008c0.002,0,0.008-0.002,0.008-0.008
			C43.598,29.749,43.591,29.747,43.588,29.747z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon points="43.805,28.729 43.807,28.731 43.807,28.726 		" />
				</g>
			</g>
			<g>
				<g>
					<path d="M43.153,24.312c-0.015,0.004-0.036,0.011-0.048,0.013c-0.012,0.002-0.03,0.009-0.041,0.014
			c-0.015,0.007-0.019,0.01-0.014,0.013c0.012,0.004,0.006,0.017-0.013,0.021c-0.009,0.001-0.014,0-0.014-0.002
			c0-0.008-0.02-0.005-0.034,0.005c-0.013,0.009-0.005,0.018,0.018,0.025c0.014,0.004,0.02,0.009,0.018,0.018
			c0,0.013,0.002,0.012,0.064-0.01c0.028-0.009,0.039-0.015,0.059-0.036c0.012-0.014,0.023-0.029,0.023-0.035
			c0-0.005,0.002-0.01,0.008-0.011c0.01-0.001,0.01-0.004,0.004-0.015C43.179,24.307,43.174,24.307,43.153,24.312z M43.161,24.349
			c-0.005-0.001-0.009,0-0.011,0.003c-0.001,0.003-0.003,0.003-0.005-0.001c-0.004-0.009,0.004-0.014,0.018-0.006
			C43.171,24.349,43.171,24.349,43.161,24.349z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M51.801,28.226c-0.003,0.006-0.007,0.008-0.01,0.011c-0.001-0.002-0.001-0.005-0.002-0.005
			c-0.004,0.001-0.008-0.006-0.01-0.012c-0.002-0.006-0.008-0.013-0.01-0.013c-0.004,0-0.01-0.003-0.01-0.008
			c-0.002-0.004-0.006-0.007-0.01-0.007c-0.005,0.002-0.005-0.002-0.003-0.021c0.003-0.023,0.003-0.024-0.009-0.021
			c-0.006,0.001-0.012,0.001-0.014-0.003c-0.002-0.004-0.006-0.004-0.013,0c-0.005,0.005-0.007,0.004-0.005-0.012
			c0-0.009-0.002-0.019-0.008-0.025c-0.014-0.012-0.014-0.014-0.001-0.016c0.005,0,0.007-0.004,0.005-0.009
			c0-0.005-0.004-0.008-0.008-0.008c-0.01,0.003-0.043-0.011-0.047-0.022c-0.002-0.004-0.004-0.006-0.01-0.004
			c-0.004,0.002-0.008,0.002-0.008-0.002c0-0.002-0.004-0.002-0.005-0.002c-0.008,0.003-0.019-0.009-0.015-0.02
			c0-0.005,0-0.012-0.004-0.012c-0.002-0.002-0.002-0.004,0-0.006c0.008-0.009,0.002-0.041-0.008-0.049
			c-0.007-0.006-0.009-0.049,0-0.059c0.002-0.004-0.004-0.008-0.011-0.013c-0.013-0.005-0.013-0.006-0.005-0.011
			c0.005-0.005,0.007-0.006,0.003-0.01c-0.003-0.003-0.011-0.004-0.017-0.003c-0.006,0-0.006,0,0.003,0.003
			c0.011,0.003,0.011,0.003,0,0.003c-0.007,0.002-0.011,0.002-0.013,0.001c-0.004,0.001-0.006,0.002-0.006,0.004
			s-0.004,0.004-0.01,0.004c-0.005,0-0.007-0.005-0.005-0.021c0-0.016,0.004-0.021,0.009-0.019c0.004,0.002,0.008-0.003,0.006-0.013
			c0-0.009,0-0.016,0-0.018c0.006-0.008,0.006-0.016,0-0.016c-0.006,0-0.008,0.008-0.006,0.029c0,0.008,0,0.012-0.004,0.011
			c-0.003-0.002-0.005-0.006-0.003-0.01c0-0.003-0.002-0.009-0.004-0.011c-0.007-0.006-0.003-0.016,0.004-0.02
			c0.003-0.002,0-0.003-0.004-0.001c-0.011,0.002-0.025-0.01-0.019-0.015c0.002-0.001,0.002-0.007,0-0.015
			c-0.001-0.012-0.013-0.016-0.022-0.007c-0.002,0.003-0.007,0.01-0.009,0.016c-0.002,0.006-0.01,0.017-0.014,0.025
			c-0.009,0.007-0.014,0.021-0.014,0.03c-0.002,0.009-0.004,0.019-0.007,0.022c-0.004,0.003-0.009,0.016-0.011,0.031
			c-0.002,0.03-0.02,0.078-0.032,0.09c-0.009,0.006-0.011,0.012-0.011,0.014c0,0.009-0.016,0.019-0.03,0.019
			c-0.009,0.001-0.018-0.004-0.034-0.014c-0.009-0.009-0.023-0.015-0.029-0.015c-0.006,0-0.014-0.004-0.017-0.012
			c-0.006-0.006-0.011-0.009-0.015-0.009c-0.006,0-0.01-0.005-0.012-0.01c-0.005-0.011-0.012-0.014-0.012-0.004
			c0,0.004-0.01,0-0.027-0.012c-0.009-0.007-0.016-0.013-0.025-0.019c0.003-0.004,0.007-0.008,0.011-0.01
			c0.005-0.006,0.013-0.015,0.017-0.021c0.004-0.005,0.006-0.008,0.01-0.007c0.002,0.001,0.005,0.006,0.002,0.008
			c-0.002,0.005,0.017,0.011,0.025,0.007c0.004-0.002,0.004-0.022-0.004-0.025c0-0.002-0.006,0.001-0.011,0.003
			c-0.008,0.005-0.008,0.004-0.008-0.006c0-0.006,0.002-0.011,0.006-0.01c0.002,0.001,0.013-0.007,0.021-0.019
			c0.008-0.016,0.01-0.019,0.006-0.022c-0.006-0.002-0.008-0.007-0.006-0.01c0-0.002,0-0.004-0.004-0.004s-0.008,0.002-0.008,0.004
			c0,0.002-0.003,0.005-0.011,0.006c-0.007,0.002-0.011,0-0.007-0.003c0.001-0.008,0-0.008-0.021,0.003
			c-0.01,0.007-0.016,0.008-0.021,0.004c-0.004-0.002-0.014-0.004-0.018-0.004c-0.008,0-0.016-0.001-0.021-0.004
			s-0.009-0.005-0.015-0.004c-0.002,0.001-0.006-0.001-0.006-0.006c0-0.005-0.002-0.005-0.006,0
			c-0.005,0.006-0.007,0.006-0.011-0.001c-0.003-0.004-0.007-0.006-0.011-0.005c-0.002,0.002-0.006-0.001-0.006-0.005
			c0-0.008-0.002-0.008-0.015-0.004c-0.005,0.001-0.013,0.002-0.013,0.001c0-0.001-0.001,0-0.008,0.003
			c-0.005,0.005,0.007,0.017,0.016,0.014c0.012-0.002,0.016,0.011,0.01,0.019c-0.006,0.004-0.016,0.006-0.037,0.004
			c-0.014,0-0.027,0.001-0.027,0.004c0,0.001-0.002,0.004-0.006,0.003c-0.01,0.001-0.035,0.028-0.033,0.034
			c0.004,0.004-0.002,0.008-0.008,0.011c-0.006,0.003-0.01,0.008-0.01,0.01c0,0.003-0.006,0.009-0.014,0.016
			c-0.009,0.008-0.011,0.011-0.003,0.013c0.009,0.006,0,0.013-0.021,0.012c-0.009-0.001-0.022,0.002-0.026,0.003
			c-0.007,0.005-0.007,0.005-0.003-0.001c0.001-0.005,0-0.008-0.006-0.01c-0.006-0.001-0.01-0.005-0.01-0.008
			s-0.007-0.01-0.017-0.014c-0.014-0.008-0.016-0.009-0.022-0.002c-0.004,0.003-0.006,0.006-0.008,0.005
			c-0.007-0.003-0.037-0.005-0.037-0.001c0,0.002,0.002,0.004,0.004,0.004c0.004,0,0.006,0.005,0.006,0.01
			c0,0.009,0,0.009-0.008,0.001c-0.009-0.007-0.009-0.007-0.009,0c0,0.005-0.002,0.006-0.009,0.004c-0.011,0-0.011,0-0.011,0.01
			c0,0.006-0.005,0.011-0.005,0.01c-0.006-0.004-0.029,0.02-0.03,0.032c-0.002,0.007-0.002,0.015-0.002,0.017s-0.011,0.002-0.021,0
			c-0.013,0-0.019,0-0.017,0.002c0.002,0.004,0.002,0.006-0.002,0.008c-0.003,0.003-0.003,0.007,0,0.012
			c0.004,0.006,0.004,0.008,0,0.008c-0.003,0.002-0.005,0.004-0.007,0.008c-0.002,0.006-0.006,0-0.006-0.01
			c-0.004-0.01-0.008-0.019-0.008-0.019c-0.002,0.001-0.012,0.009-0.023,0.019c-0.018,0.013-0.02,0.022-0.02,0.033
			c0,0.011-0.006,0.019-0.018,0.032c-0.007,0.009-0.021,0.022-0.025,0.03c-0.013,0.015-0.041,0.027-0.07,0.029
			c-0.013,0.002-0.027,0.006-0.033,0.01c-0.005,0.006-0.017,0.011-0.023,0.011c-0.008,0.001-0.018,0.005-0.021,0.008
			c-0.006,0.004-0.012,0.007-0.027,0.006c-0.01,0-0.022,0.001-0.022,0.003c-0.004,0.003-0.009,0.004-0.012,0.004
			c-0.003,0-0.014,0.008-0.024,0.016c-0.011,0.007-0.025,0.017-0.036,0.022c-0.009,0.005-0.021,0.015-0.025,0.021
			c-0.003,0.006-0.007,0.01-0.007,0.013c-0.002-0.004-0.004-0.008-0.004-0.013c-0.001-0.01-0.005-0.012-0.009-0.006
			c-0.014,0.018-0.018,0.031-0.018,0.043c0,0.007-0.006,0.019-0.014,0.029c-0.018,0.022-0.018,0.026-0.005,0.059
			s0.011,0.051-0.004,0.027c-0.003-0.007-0.007-0.012-0.011-0.018c-0.002,0.003-0.004,0.008-0.006,0.011
			c-0.002,0.005-0.004,0.009-0.004,0.013c-0.002-0.004-0.004-0.008-0.007-0.012c-0.006-0.01-0.006-0.009-0.002,0.01
			c0,0.01,0.009,0.024,0.011,0.032c0.004,0.007,0.01,0.022,0.01,0.036c0.002,0.014,0.004,0.029,0.006,0.037
			c0.002,0.006,0.005,0.019,0.003,0.027c0,0.006-0.001,0.037-0.001,0.067c0,0.039-0.004,0.056-0.008,0.058
			c-0.004,0.002-0.008,0.008-0.008,0.014c0,0.012-0.013,0.031-0.025,0.033c-0.008,0-0.014,0.005-0.018,0.013
			c-0.006,0.019-0.006,0.021,0.006,0.021c0.006-0.002,0.013,0.002,0.013,0.006c0.006,0.011,0.023,0.016,0.054,0.016
			c0.025,0,0.051-0.009,0.051-0.016c0-0.004,0.016-0.012,0.033-0.014c0.004-0.002,0.012-0.009,0.02-0.013
			c0.011-0.012,0.05-0.022,0.113-0.024c0.016,0,0.029-0.002,0.045-0.002c0.005-0.007,0.015-0.014,0.023-0.022
			c0.011-0.012,0.025-0.022,0.031-0.022c0.006,0,0.012-0.002,0.012-0.005c0.001-0.002,0.01-0.005,0.018-0.009
			c0.007-0.001,0.019-0.007,0.024-0.011c0.004-0.005,0.014-0.007,0.024-0.007c0.019,0,0.092-0.019,0.104-0.027
			c0.004-0.003,0.031-0.009,0.057-0.014c0.044-0.006,0.048-0.006,0.062,0.002c0.005,0.004,0.014,0.007,0.018,0.006
			c0.005-0.002,0.017,0.001,0.026,0.005c0.012,0.004,0.022,0.008,0.026,0.008c0.006,0,0.006,0.001,0,0.004
			c-0.01,0.009-0.008,0.024,0.003,0.021c0.012,0,0.018,0.039,0.007,0.044c-0.01,0.006-0.008,0.007,0.009,0.017
			c0.017,0.01,0.017,0.01,0.019,0c0.002-0.01,0.035-0.041,0.047-0.042c0.005-0.001,0.018-0.01,0.031-0.022
			c0.013-0.012,0.026-0.022,0.028-0.02c0.001,0.001,0,0.003-0.004,0.004c-0.004,0.002-0.008,0.008-0.008,0.012
			c0,0.004-0.007,0.012-0.015,0.016c-0.006,0.004-0.016,0.015-0.02,0.023c-0.006,0.009-0.016,0.019-0.02,0.021
			c-0.007,0.003-0.016,0.009-0.021,0.014c-0.007,0.006-0.007,0.006,0.007,0.004c0.007-0.002,0.019-0.005,0.021-0.006
			c0.005-0.001,0.016-0.008,0.021-0.017c0.006-0.009,0.014-0.017,0.016-0.017c0.009-0.002,0.002,0.021-0.012,0.034
			c-0.015,0.017-0.015,0.021,0.006,0.015c0.012-0.004,0.015-0.002,0.021,0.01c0.007,0.01,0.007,0.013-0.002,0.029
			c-0.011,0.013-0.011,0.018-0.005,0.029c0.003,0.008,0.013,0.016,0.018,0.016c0.006,0.002,0.012,0.004,0.012,0.007
			c0,0.002,0.008,0.003,0.021,0.002c0.01-0.002,0.022-0.001,0.024,0c0.004,0.003,0.009,0.005,0.017,0.008
			c0.011,0.002,0.016,0.001,0.037-0.01c0.012-0.009,0.026-0.013,0.026-0.011c0.002,0,0.011,0.004,0.024,0.008
			c0.009,0.004,0.019,0.009,0.019,0.013c0,0.004,0.002,0.004,0.013-0.004c0.03-0.025,0.087-0.052,0.112-0.056
			c0.035-0.004,0.064-0.02,0.064-0.034c0-0.002,0.006-0.011,0.016-0.019c0.006-0.007,0.012-0.015,0.012-0.017
			s0.009-0.012,0.025-0.024c0.012-0.011,0.034-0.031,0.047-0.046c0.032-0.034,0.06-0.055,0.084-0.067
			c0.012-0.004,0.021-0.012,0.021-0.015s0.005-0.01,0.015-0.017c0.022-0.021,0.033-0.033,0.033-0.038
			c0-0.002,0.004-0.011,0.012-0.021c0.029-0.035,0.047-0.06,0.049-0.074c0.002-0.006,0.008-0.018,0.012-0.028
			c0.009-0.017,0.007-0.027-0.004-0.01c-0.002,0.006-0.004,0.008-0.004,0.006c-0.002-0.003,0.008-0.033,0.015-0.043
			c0-0.004,0.002-0.009,0-0.011c0-0.002,0.006-0.01,0.011-0.017c0.006-0.008,0.012-0.018,0.012-0.021
			C51.805,28.223,51.803,28.223,51.801,28.226z M50.226,28.405c0,0-0.002-0.002-0.004-0.006c-0.004-0.005-0.002-0.007,0.002-0.007
			c0.002,0,0.006,0.002,0.006,0.007C50.23,28.403,50.229,28.406,50.226,28.405z M51.238,28.752c-0.004,0.004-0.01,0.006-0.012,0.006
			c-0.004-0.004,0.004-0.012,0.012-0.013C51.241,28.745,51.241,28.747,51.238,28.752z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M45.631,24.555c-0.002,0.005-0.004,0.005-0.007,0.001c-0.006-0.006-0.011-0.007-0.017-0.002
			c-0.005,0.006-0.003,0.019,0.002,0.015c0.004-0.002,0.006,0,0.009,0.003c0,0.002,0,0.008-0.003,0.01
			c-0.004,0.002-0.002,0.007,0.003,0.011c0.009,0.008,0.009,0.008,0.006-0.001c-0.002-0.005-0.002-0.011,0.003-0.012
			c0.002-0.002,0.002-0.005,0.002-0.008c-0.002-0.003-0.002-0.006,0.002-0.008c0.002-0.002,0.006-0.007,0.006-0.011
			C45.637,24.549,45.635,24.549,45.631,24.555z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.008,24.189c-0.041,0.014-0.058,0.022-0.027,0.018c0.012-0.002,0.021-0.002,0.023,0
			c0.004,0.002-0.01,0.004-0.031,0.009c-0.03,0.003-0.041,0.007-0.045,0.013c-0.008,0.013,0.008,0.015,0.043,0.006
			c0.033-0.008,0.06-0.024,0.047-0.026c-0.004-0.001,0-0.001,0.011-0.001c0.013-0.001,0.017-0.002,0.015-0.005
			c-0.002-0.004,0-0.006,0.01-0.008c0.006-0.001,0.017-0.004,0.02-0.008c0.007-0.005,0.007-0.006,0-0.01
			C43.062,24.174,43.058,24.174,43.008,24.189z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M47.179,23.917c-0.015,0.004-0.017,0.003-0.01-0.005c0.004-0.003,0.014-0.006,0.018-0.006c0.007,0,0.01-0.002,0.009-0.005
			c-0.002-0.003-0.009-0.006-0.015-0.006c-0.006-0.001-0.012-0.003-0.012-0.006c0-0.006-0.004-0.006-0.023,0
			c-0.01,0.003-0.018,0.007-0.016,0.01c0.002,0.002,0.002,0.003-0.002,0.003c-0.003,0-0.007-0.003-0.007-0.006
			c0-0.005-0.037-0.008-0.075-0.004c-0.027,0.002-0.02,0.006,0.018,0.008c0.005,0,0.012,0,0.01,0.004
			c-0.002,0.002-0.002,0.004-0.002,0.005c0.029,0.006,0.064,0.008,0.061,0.003c0-0.003,0.002-0.006,0.01-0.005
			c0.008,0,0.011,0.003,0.006,0.005c-0.01,0.004,0.016,0.012,0.043,0.014c0.013,0.001,0.016,0,0.009-0.005
			C47.197,23.919,47.186,23.917,47.179,23.917z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M47.443,23.914c-0.006-0.001-0.012-0.002-0.012-0.006c-0.002-0.003-0.037-0.012-0.07-0.016
			c-0.008,0-0.01-0.002-0.004-0.008c0.007-0.007,0.004-0.008-0.012-0.009c-0.011-0.001-0.02,0.001-0.021,0.003
			c-0.002,0.003-0.011,0.005-0.025,0.006c-0.022,0.003-0.066,0.018-0.072,0.026c-0.002,0.002-0.008,0.002-0.012,0
			c-0.002-0.003-0.004-0.001-0.004,0.001c0,0.004,0.002,0.006,0.006,0.006c0.004,0.003,0.012,0.004,0.017,0.003
			c0.008,0.002,0.017-0.002,0.023-0.008c0.006-0.006,0.01-0.007,0.008-0.004c-0.002,0.003,0.002,0.008,0.01,0.011
			c0.017,0.005,0.02,0.004,0.017-0.002c-0.002-0.003,0.001-0.003,0.005-0.001c0.004,0.003,0.008,0.007,0.004,0.01
			c-0.002,0.004,0.012,0.005,0.043,0.005c0.026,0.001,0.039,0.002,0.033,0.004c-0.009,0.002-0.005,0.004,0.012,0.005
			c0.015,0.001,0.022,0,0.024-0.006c0.001-0.002,0.009-0.006,0.015-0.006c0.013,0.002,0.025-0.003,0.025-0.009
			C47.453,23.917,47.448,23.914,47.443,23.914z M47.247,23.911c-0.003,0.002-0.005,0.001-0.005-0.001
			c0-0.003,0.002-0.005,0.005-0.005c0.005,0.001,0.007,0.001,0.007,0.002C47.254,23.909,47.252,23.911,47.247,23.911z
			 M47.365,23.916c-0.005,0-0.011,0-0.015,0c0.004,0.001,0.009,0,0.013-0.005c0.008-0.001,0.009-0.003,0.004-0.005
			c-0.004,0-0.002-0.002,0.007-0.003c0.021-0.007,0.023-0.005,0.013,0.005C47.384,23.914,47.373,23.917,47.365,23.916z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M46.497,23.882c-0.004,0.004-0.004,0.004-0.006-0.001c-0.005-0.01-0.03-0.009-0.03,0.001c0,0.006,0,0.007-0.007,0.002
			c-0.009-0.009-0.093-0.012-0.104-0.004c-0.004,0.006-0.011,0.006-0.026,0.004c-0.014-0.003-0.018-0.002-0.022,0.005
			c-0.002,0.004,0,0.011,0.004,0.013c0.004,0.003,0.004,0.004,0.002,0.004c-0.002,0-0.01-0.004-0.014-0.008
			c-0.004-0.005-0.013-0.008-0.02-0.007c-0.008,0.002-0.014,0.001-0.014-0.001c-0.002-0.002-0.016-0.003-0.035-0.003
			c-0.017,0.002-0.035,0.003-0.045,0.002c-0.012,0-0.014,0.003-0.012,0.009c0.004,0.006,0.012,0.016,0.018,0.024
			c0.006,0.004,0.012,0.009,0.015,0.013c-0.005-0.003-0.011-0.006-0.015-0.009c-0.008-0.005-0.016-0.009-0.019-0.007
			c0,0.003,0.007,0.01,0.021,0.017c0.028,0.013,0.03,0.014,0.02,0.003c-0.011-0.015,0.028,0.01,0.042,0.026
			c0.009,0.008,0.017,0.015,0.021,0.015c0.008,0,0.018,0.005,0.031,0.01c0.03,0.017,0.043,0.016,0.047-0.003
			c0.002-0.01,0.008-0.017,0.012-0.017c0.009,0,0.039-0.025,0.031-0.026c-0.004,0,0-0.003,0.004-0.004
			c0.011-0.008,0.027-0.003,0.046,0.012c0.011,0.01,0.011,0.01,0.002,0.015c-0.01,0.006-0.006,0.007,0.019,0.006
			c0.009,0,0.017,0.002,0.021,0.005c0.004,0.007,0.027,0.004,0.037-0.003c0.003-0.004,0.01-0.005,0.015-0.005
			c0.006,0,0.009-0.003,0.006-0.005c0-0.004-0.009-0.005-0.018-0.006c-0.011,0-0.017-0.005-0.019-0.009
			c-0.002-0.006-0.01-0.008-0.017-0.007c-0.009,0.002-0.015,0-0.015-0.002c0-0.007-0.049-0.029-0.058-0.025
			c-0.007,0.002-0.007,0-0.002-0.003c0.004-0.004,0.017-0.004,0.039-0.002c0.055,0.009,0.104,0.007,0.108-0.004
			c0.002-0.002,0.008-0.005,0.013-0.004c0.015,0,0.019-0.009,0.007-0.015C46.564,23.88,46.5,23.875,46.497,23.882z M46.25,23.96
			c0,0-0.005-0.003-0.005-0.006s0.004-0.004,0.005-0.004c0.004,0,0.006,0.002,0.006,0.004C46.256,23.956,46.254,23.96,46.25,23.96z
			 M46.284,23.943c-0.005,0-0.011-0.004-0.011-0.008c0-0.006,0.004-0.006,0.011-0.001c0.005,0.002,0.009,0.006,0.009,0.007
			C46.293,23.943,46.289,23.943,46.284,23.943z M46.391,23.911c-0.004,0-0.01-0.005-0.012-0.008
			c-0.002-0.004,0.002-0.008,0.01-0.006c0.002,0.002,0.009,0.007,0.011,0.01C46.402,23.911,46.4,23.914,46.391,23.911z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M45.842,25.004c0.016-0.005,0.027-0.029,0.021-0.04c-0.007-0.013-0.035-0.02-0.046-0.01
			c-0.01,0.006-0.01,0.006-0.004-0.001c0.008-0.008,0.008-0.013,0-0.024c-0.003-0.009-0.006-0.018-0.006-0.021
			c0-0.007-0.013-0.021-0.029-0.029c-0.006-0.003-0.012-0.013-0.016-0.021c-0.002-0.02-0.016-0.038-0.027-0.039
			c-0.014,0-0.014-0.016,0.002-0.026c0.005-0.004,0.012-0.01,0.012-0.014c0-0.003,0.004-0.011,0.006-0.015
			c0.014-0.015,0.004-0.019-0.029-0.019c-0.015,0-0.032-0.002-0.033-0.005c-0.002-0.003-0.002-0.005,0.003-0.005
			c0.002,0,0.013-0.005,0.021-0.012c0.006-0.005,0.01-0.01,0.008-0.01c-0.002,0,0.002-0.006,0.009-0.012
			c0.005-0.007,0.011-0.015,0.014-0.019c0.005-0.008-0.02-0.008-0.028,0c-0.002,0.003-0.004,0.01-0.004,0.013
			c0,0.008-0.023,0.015-0.041,0.012c-0.008-0.002-0.016,0.001-0.021,0.011c-0.014,0.021-0.027,0.035-0.036,0.031
			c-0.001,0-0.007,0-0.017,0.003c-0.006,0.005-0.008,0.008,0,0.015c0.004,0.005,0.006,0.01,0.006,0.01
			c-0.002,0.001-0.002,0.004-0.002,0.006s0.002,0.001,0.006-0.004c0.002-0.003,0.005-0.004,0.007-0.001
			c0.002,0.003-0.004,0.009-0.017,0.012c-0.008,0.004-0.018,0.009-0.018,0.012s0.004,0.002,0.01,0
			c0.01-0.005,0.012-0.005,0.012,0.001c0,0.005,0.004,0.008,0.009,0.01c0.008,0.001,0.008,0.003-0.005,0.009
			c-0.008,0.002-0.016,0.007-0.016,0.01c0,0.008,0.012,0.013,0.02,0.006c0.007-0.005,0.014-0.002,0.014,0.007
			c0,0.003-0.002,0.003-0.005-0.002c-0.004-0.007-0.006-0.006-0.006,0.002c0,0.008,0.002,0.008,0.017,0.005
			c0.013-0.003,0.017-0.003,0.013,0.005c-0.003,0.005-0.005,0.009-0.007,0.009c-0.004,0-0.004,0.005,0,0.011
			c0.004,0.006,0.007,0.008,0.011,0.006c0.004-0.007,0.043-0.007,0.039,0c-0.002,0.002,0.002,0.011,0.007,0.017
			c0.009,0.01,0.009,0.029-0.001,0.035c-0.002,0.002-0.014,0.002-0.024,0.001c-0.014-0.001-0.021,0-0.021,0.004
			c0,0.002,0.002,0.004,0.006,0.001c0.009-0.006,0.005,0.003-0.008,0.014c-0.005,0.005-0.009,0.009-0.013,0.013
			c0.004-0.001,0.008-0.002,0.015-0.003c0.022-0.003,0.018,0.014-0.007,0.025c-0.028,0.012-0.027,0.026,0.007,0.028
			c0.027,0,0.043,0.012,0.021,0.012c-0.014,0-0.035,0.018-0.035,0.027c0,0.002-0.01,0.009-0.02,0.014
			c-0.011,0.007-0.018,0.012-0.016,0.014c0.005,0.006,0.018,0.004,0.031-0.004c0.005-0.006,0.017-0.008,0.026-0.006
			c0.01,0.002,0.016-0.001,0.024-0.008c0.008-0.008,0.015-0.009,0.045-0.008c0.02,0.001,0.037,0,0.037-0.003
			c0-0.001,0.009-0.003,0.022-0.003c0.022,0.002,0.067-0.014,0.067-0.024c0-0.003-0.004-0.005-0.01-0.005
			C45.834,25.021,45.834,25.008,45.842,25.004z M45.615,24.765c-0.002,0-0.004-0.003-0.002-0.005c0-0.003,0.004-0.005,0.004-0.005
			s0.003,0.001,0.003,0.004C45.62,24.762,45.618,24.765,45.615,24.765z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M43.312,24.13c-0.01-0.002-0.023-0.004-0.03-0.004c-0.007-0.001-0.019-0.001-0.022-0.002
			c-0.008,0-0.021,0.003-0.035,0.007c-0.014,0.003-0.023,0.008-0.023,0.011c0,0.004-0.005,0.01-0.011,0.016
			c-0.005,0.004-0.009,0.009-0.009,0.011c0.004,0.001,0.021,0,0.043-0.002c0.021-0.001,0.041-0.001,0.045,0.003
			c0.003,0.002,0.014,0.002,0.023,0.001c0.018-0.003,0.023-0.005,0.028-0.02C43.329,24.135,43.329,24.135,43.312,24.13z"/>
				</g>
			</g>
			<g>
				<g>
					<path d="M45.547,24.854c-0.012,0.005-0.037,0.034-0.027,0.034c0.004,0,0.006,0.003,0.004,0.006
			c-0.002,0.003-0.016,0.005-0.026,0.003c-0.021-0.001-0.024,0.001-0.028,0.009c-0.006,0.007-0.004,0.011,0,0.012
			c0.006,0.002,0.006,0.003,0,0.01c-0.01,0.011-0.008,0.013,0.008,0.018c0.018,0.004,0.018,0.015-0.008,0.032
			c-0.023,0.016-0.032,0.036-0.019,0.036c0.004,0,0.004,0.001,0.002,0.005c-0.002,0.004-0.002,0.004,0.005,0.002
			c0.005-0.002,0.009-0.001,0.009,0.002c0,0.01,0.041,0,0.068-0.016c0.012-0.007,0.03-0.013,0.041-0.013
			c0.014,0,0.016-0.003,0.021-0.021c0.004-0.012,0.006-0.03,0.006-0.042c0-0.018,0.003-0.022,0.015-0.027
			c0.015-0.007,0.017-0.021,0.004-0.037C45.611,24.856,45.568,24.849,45.547,24.854z"/>
				</g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
			<g>
			</g>
		</svg>
	)
}

export default IconCommercial;